import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader 
} from "@chakra-ui/modal";
// import { createOrganization, getOrganization, updateOrganization, } from "api/organizations";
// import { createOrganization, updateOrganization, } from "api/organizations";
// import { updateOrganization, } from "api/organizations";
import { updateOrganization, } from "api/graphQL_organizations";
import { createOrganization} from "api/graphQL_organizations";
import { getOrganization } from "api/graphQL_organizations";
import sipe from "assets/img/payment-clients/sipe.png";
import pixLotus from "assets/img/payment-clients/pix.png";
import pix from "assets/img/payment-clients/pix.png";
import blumon from "assets/img/payment-clients/blumon.png";
import stripe from "assets/img/payment-clients/stripe.png";
import memphis from "assets/img/payment-clients/memphis.png";

import repyd from "assets/img/payment-clients/rapyd.png";
import mit from "assets/img/payment-clients/mit.png";
import banwire from "assets/img/payment-clients/banwire.jpg";
import valitor from "assets/img/payment-clients/valitor.png";
import Card from "components/card/custom";
import DivLoader from "components/divloader/DivLoader";
import InputField from "components/fields/InputField";
import TextArea from "components/fields/TextArea";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { BsPlusCircle } from "react-icons/bs";
import { MdCheck, MdEdit, MdFileCopy, MdHighlightOff, MdInfo } from "react-icons/md";
import { MultiSelect } from "react-multi-select-component";
import { getImage } from "utils/commonFunction";
import _ from 'lodash';

export interface iCreateOrgForm {
  name: string;
  description: string;
  website: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  payment_methods: string[];
}

const CreateOrgModal = ({
  fetchOrganizations,
  allGateways,
  id,
  info,
  roleData,
  infoData

}: {
  fetchOrganizations: () => void;
  allGateways?: any;
  id?: string;
  info?: boolean;
  roleData?: any;
  infoData?:any;

}) => {
  const rootForm = {
    name: "",
    description: "",
    website: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    payment_methods:  [] as string[],
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [formValues, setFormValues] = React.useState<iCreateOrgForm>(rootForm);
  const [initialValues, setInitialFormValues] = React.useState<iCreateOrgForm>(rootForm);
  const [viewMode, setViewMode] = React.useState<boolean>(info);
  const [selected, setSelected] = React.useState([]);
  const [options, setGatewayOption] = React.useState([]);
  const [formValuesErr, setFormValuesErr] =
    React.useState<iCreateOrgForm>(rootForm);
  const [multiselectError, setMultiselectError] = React.useState<any>("");
  const [methodName, setMethodName] = React.useState<boolean>(false);
  const [isCloseModal, setIsCloseModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (allGateways.length > 0) {
      const method2 =
        roleData[0]?.organization?.value?.organization_show_method_name;
      setMethodName(method2);
      const newOptions = allGateways.map((ele: any) => {
        return {
          value: ele.id,
          label: method2 ? ele.name : ele.id,
        };
      });
      setGatewayOption(newOptions);
    }
  }, [allGateways]);

  const handleClose = () => {
    setFormValues(rootForm);
    setFormValuesErr(rootForm);
    setMultiselectError("");
    setIsCloseModal(true)
    setSelected([]);
    setViewMode(false)
    setTimeout(() => {
      setIsCloseModal(false)
      onClose();
    }, 300);
  };

  const handleMultiSelectChange = (sel?: string[]) => {
    setSelected(sel);
    setFormValues((prevData: any) => {
      return {
        ...prevData,
        payment_methods: sel?.map((ele: any) => ele.value),
      };
    });
    // setFormValues((prevData: any) => {
    //   return { ...prevData, payment_methods: "any" };
    // });
    if (sel?.length > 0) {
      setMultiselectError("");
    } else {
      setMultiselectError("The field is required");
    }
  };

  function handleValueChange(e: any) {
    const { id, value } = e.target;
  
    const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/.*)?$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\d+$/;
  
    let isValid = true;
    let errorMessage = "";
  
    if (id === "website" && !websiteRegex.test(value)) {
      isValid = false;
      errorMessage = "Invalid website URL";
    }
  
    if (id === "email" && !emailRegex.test(value)) {
      isValid = false;
      errorMessage = "Invalid email address";
    }
  
    if (id === "phone" && !phoneRegex.test(value)) {
      isValid = false;
      errorMessage = "Invalid phone number";
    }
  
    if (!isValid) {
      setFormValuesErr((prevErr: any) => ({
        ...prevErr,
        [id]: errorMessage,
      }));
      setFormValues((prevVal: any) => ({
        ...prevVal,
        [id]: value,
      }));
      return;
    } else {
      setFormValuesErr((prevErr: any) => ({
        ...prevErr,
        [id]: "",
      }));
      setFormValues((prevVal: any) => ({
        ...prevVal,
        [id]: value,
      }));
    }
  
    
  }
  
  

  function validateData() {
    let verifier: iCreateOrgForm;
    Object.keys(formValues).forEach((key: any) => {
      if (!formValues[key as keyof iCreateOrgForm]) {
        if (key !== "payment_methods") {
          verifier = { ...verifier, [key]: "The field is required" };
        }
      }
    });

    if (formValues?.payment_methods?.length < 1) {
      
      setMultiselectError("The field is required");
      
    } else {
      setMultiselectError("");
    }

    const hasEmptyValues = Object.values(formValues).some(
      (value) => value === ""
    );

    Object.entries(formValues).forEach(([key, value]) => {
      const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/.*)?$/;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const phoneRegex = /^\d+$/;
      
      if (key === "website" && value !== "" && !websiteRegex.test(value)) {
        verifier = {
          ...verifier,
          [key]: "Invalid website URL",
        };
      }

      if (key === "email" && value !== "" && !emailRegex.test(String(value || ""))) {
        verifier = {
          ...verifier,
          [key]: "Invalid email address",
        };
      }

      if (key === "phone" && value !== "" && !phoneRegex.test(value)) {
        verifier = {
          ...verifier,
          [key]: "Invalid phone number",
        };
      }
    });
    setFormValuesErr(verifier);

    const hasErrors = Object.keys(verifier || {}).length > 0;
   
    if (hasErrors) {
      return false;
    }

    if (hasEmptyValues) {
      return false;
    }
    if (verifier) {
      setFormValuesErr(verifier);
      return false;
    }
    if (multiselectError) {
      return false;
    }
    return true;
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    let validate = validateData();
    //return;
    if (validate) {
      try {
        setIsLoading(true);
        if (id) {
          const hasChanges = !_.isEqual(formValues, initialValues);
          if (!hasChanges) {
            toast.error("No changes were detected. You're all set!");
            handleClose();
          } else {
            const update = await updateOrganization(formValues);
            if(update.errors) {
              toast.error(update.errors[0].message);
            } else {
              toast.success("Organization details updated successfully");
              fetchOrganizations();
              handleClose();
            }
          }
        } else {
          const create = await createOrganization(formValues);
          if(create.errors) {
            toast.error(create.errors[0].message);
          } else {
            fetchOrganizations();
            handleClose();
            toast.success("Organization created successfully");
          }
        }
        
      } catch (err: any) {
        if (err?.response?.status === 422) {
          toast.error("Please provide valid values");
        } else {
          console.log("graph create org modal ",{err})
          toast.error(
            err?.response?.data?.message ?? "Unable update organization data"
          );
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function fetchOrganization(id: string) {
    await getOrganization({ id: id })
      .then((data: any) => {
        setFormValues(data[0]);
        setInitialFormValues(data[0]);
        let methodName =
          roleData[0]?.organization?.value?.organization_show_method_name;
        const selectedOptions = data[0].payment_methods.map((id: string) => {
          const gateway = allGateways?.find((item: any) => item.id === id);
          return {
            value: id,
            label: methodName ? gateway?.name : id,
          };
        });
        setSelected(selectedOptions);
        if (data[0].payment_methods?.length > 0) {
          setMultiselectError(true);
        } else {
          setMultiselectError(false);
        }
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message ?? "Unable to fetch data");
      });
  }

  const hasFormChanged = () => {
    return Object.keys(formValues).some((key) => {
      const k = key as keyof iCreateOrgForm;
      return formValues[k] !== initialValues[k];
    });
  };


  return (
    <>
      {info ? (
        roleData?.[0]?.organization?.value?.view_organization && (
          <div
            onClick={() => {
              if (id) {
                fetchOrganization(id);
              }
              onOpen();
              setViewMode(true)
            }}
          >
            {" "}
            <MdInfo
              className="h-5 w-5 cursor-pointer text-blue-500"
              title="View"
            />
          </div>
        )
      ) : id ? (
        roleData?.[0]?.organization?.value?.edit_organization && (
          <div
            onClick={() => {
              if (id) {
                fetchOrganization(id);
              }
              onOpen();
            }}
          >
            <MdEdit
              className="h-5 w-5 cursor-pointer text-blue-500"
              title="Edit"
            />
          </div>
        )
      ) : roleData?.[0]?.organization?.value?.add_organization ? (
        <div
          onClick={() => {
            if (id) {
              fetchOrganization(id);
            }
            onOpen();
          }}
        >
          <Card extra="w-fit px-5 cursor-pointer">
            <button className="flex items-center justify-center gap-2 whitespace-nowrap p-5 text-navy-700 outline-none dark:text-gray-200">
              <BsPlusCircle className="h-5 w-5 text-brand-500" />
              <span className="text-brand-500"> Add new organization</span>
            </button>
          </Card>
        </div>
      ) : null}
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <Card extra={`fixed top-0 right-0 h-full rounded-none max-w-[600px] sm:w-full flex flex-col justify-start !z-[1004] overflow-y-auto scrollbar-hide overflow-auto ${
            isOpen && !isCloseModal ? "card-enter" : "card-exit"
          }`}>
            <ModalHeader className="py-[20px] mb-[20px] px-[30px] text-2xl font-bold bg-brand-500 text-white">
              { !viewMode && (
                <MdHighlightOff
                  className="absolute right-7 top-6 h-8 w-8 cursor-pointer text-white"
                  onClick={handleClose}
                />
              )}
              {viewMode
              ? "View"
              : id
              ? infoData?.row?.original?.name + " - " + "Update Organization "
              : "Create Organization"}
            </ModalHeader>
            <ModalBody className="p-2">
          
              {viewMode && (
                <MdEdit
                  className="absolute right-7 top-6 h-6 w-6 cursor-pointer text-white"
                  onClick={() => {
                    setViewMode(!viewMode);
                  }}
                />
              )}
              <div className="px-[35px]">
                <InputField
                  variant="auth"
                  extra="mb-2 w-full text-sm font-bold text-gray-900 dark:text-white"
                  label="Name*"
                  placeholder="Google inc."
                  id="name"
                  type="text"
                  disabled={viewMode}
                  value={formValues?.name}
                  onChange={handleValueChange}
                  errMessage={formValuesErr?.name ? formValuesErr?.name : ""}
                />

                <div className="flex w-full gap-5 text-gray-900">
                  <InputField
                    variant="auth"
                    extra="mb-2 w-full"
                    label="Website*"
                    placeholder="www.google.com"
                    id="website"
                    type="text"
                    disabled={viewMode}
                    value={formValues?.website}
                    onChange={handleValueChange}
                    errMessage={formValuesErr?.website ? formValuesErr?.website : ""}
                  />
                  
                  <InputField
                    variant="auth"
                    extra="mb-2 w-full"
                    label="Email*"
                    placeholder="google@gmail.com"
                    id="email"
                    type="text"
                    disabled={viewMode}
                    value={formValues?.email}
                    errMessage={formValuesErr?.email ? formValuesErr?.email : ""}
                    onChange={handleValueChange}
                  />
                </div>

                <InputField
                  variant="auth"
                  extra="mb-2 w-full"
                  label="Phone*"
                  placeholder="+919909988088"
                  id="phone"
                  type="text"
                  disabled={viewMode}
                  value={formValues?.phone}
                  errMessage={formValuesErr?.phone ? formValuesErr?.phone : ""}
                  onChange={handleValueChange}
                />

                <InputField
                  variant="auth"
                  extra="mb-2 w-full"
                  label="Address line*"
                  placeholder="45 - Test street"
                  id="address"
                  type="text"
                  disabled={viewMode}
                  value={formValues?.address}
                  errMessage={formValuesErr?.address ? formValuesErr?.address : ""}
                  onChange={handleValueChange}
                />

                <div className="flex w-full gap-5">
                  <InputField
                    variant="auth"
                    extra="mb-2 w-full"
                    label="City*"
                    placeholder="Mountain View"
                    id="city"
                    type="text"
                    disabled={viewMode}
                    value={formValues?.city}
                    errMessage={formValuesErr?.city ? formValuesErr?.city : ""}
                    onChange={handleValueChange}
                  />
                  <InputField
                    variant="auth"
                    extra="mb-2 w-full"
                    label="State*"
                    placeholder="California"
                    id="state"
                    type="text"
                    disabled={viewMode}
                    value={formValues?.state}
                    errMessage={formValuesErr?.state ? formValuesErr?.state : ""}
                    onChange={handleValueChange}
                  />
                </div>

                <div className="flex w-full gap-5">
                  <InputField
                    variant="auth"
                    extra="mb-2 w-full"
                    label="Country*"
                    placeholder="USA"
                    id="country"
                    type="text"
                    disabled={viewMode}
                    value={formValues?.country}
                    errMessage={formValuesErr?.country ? formValuesErr?.country : ""}
                    onChange={handleValueChange}
                  />
                  <InputField
                    variant="auth"
                    extra="mb-2 w-full"
                    label="Postal Code*"
                    placeholder="850125"
                    id="postal_code"
                    type="text"
                    disabled={viewMode}
                    value={formValues?.postal_code}
                    errMessage={formValuesErr?.postal_code ? formValuesErr?.postal_code : ""}
                    onChange={handleValueChange}
                  />
                </div>

                <TextArea
                  variant="auth"
                  extra="mb-2 w-full "
                  label="Description*"
                  placeholder=""
                  id="description"
                  disabled={viewMode}
                  value={formValues?.description}
                  errMessage={formValuesErr?.description ? formValuesErr?.description : ""}
                  onChange={handleValueChange}
                />
                <div className="mb-3 block items-center gap-3">
                  <label className="ml-1.5 block pb-1 text-sm font-bold text-gray-900 dark:text-white">
                    Gateways*
                  </label>
                  <div className="relative">
                    <MultiSelect
                      options={options}
                      value={selected}
                      onChange={handleMultiSelectChange}
                      labelledBy={"Select"}
                      isCreatable={false}
                      disabled={viewMode}
                      overrideStrings={{ "noOptions": "Entered gateway not found!" }}
                      valueRenderer={(selected, options) => {
                        if (selected.length === 0) return "Select gateway";
                        if (selected.length === options.length) return "All gateways are selected";
                        return selected.map((option) => option.label).join(", ");
                      }}
                    />
                    <p className="text-sm text-orange-500">{multiselectError}</p>
                    
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="mt-[10px]">
                <div className="mt-5  flex gap-2 px-[30px] pb-[30px]">
                  <button
                    onClick={handleClose}
                    className="linear rounded-xl bg-indigo-600 px-5 py-2 text-white font-medium text-navy-700 outline-none transition duration-200 hover:bg-indigo-500 active:bg-indigo-500 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                  >
                    Close
                  </button>
                  <button
                    onClick={handleSubmit}
                    disabled={viewMode || !hasFormChanged()}
                    className="linear rounded-xl bg-indigo-600 px-5 py-2 text-white font-medium text-indigo-500 outline-none transition duration-200  dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                  >
                    {isLoading ? (
                      <DivLoader className="h-6 w-6 border-indigo-500" />
                    ) : id ? (
                      "Update"
                    ) : (
                      "Create"
                    )}
                  </button>
                </div>
            </ModalFooter>
          </Card>
      </Modal>
    </>
  );
};
export default CreateOrgModal;

export const TruncateCopy = ({
  info,
  slice = 15,
  showCopy = true,
}: {
  info: any;
  slice?: number;
  showCopy?: boolean;
}) => {
  const [copy, setCopy] = React.useState<boolean>();
  return (
    <div className={"flex items-center gap-2"}>
      <p className="overflow-auto text-sm font-bold text-navy-700 dark:text-white">
        {info?.getValue()}
      </p>
      {showCopy && (
        <>
          {copy ? (
            <MdCheck className="cursor-pointer text-teal-500" />
          ) : (
            <MdFileCopy
              className="cursor-pointer text-teal-500"
              onClick={() => {
                navigator.clipboard.writeText(info?.getValue());
                setCopy(true);
                setTimeout(() => {
                  setCopy(false);
                }, 1500);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
