import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { createClientGateway } from "api/client-gateways";

import {
  enableGateway,
  getEnabledSmartRoute,
} from "../../../../api/client-gateways";

import { getAllGatewaysByClient } from "api/gateways";
import Card from "components/card/custom";
import DivLoader from "components/divloader/DivLoader";
import { useContext, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { toast } from "react-hot-toast";
import { MdListAlt , MdHighlightOff } from "react-icons/md";
import { GatewayTypes } from "./CreateClientGatewaysModal";
import { ClientContext } from "ClientProvider";


import { Link } from "react-router-dom";

const smart_routingimg = require("../../../../assets/svg/smart_routing.svg");

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result as any[];
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  background: isDragging ? "rgb(224, 231, 255)" : "#fff",
  position: "relative",
  ...draggableStyle,
});

const ArrangeClientModal = ({ id, value }: { id: string; value: any }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [addedGateways, setAddedGateways] = useState<any[]>([]);
  const [newArr, setNewArr] = useState<any[]>([]);
  const [isCloseModal, setIsCloseModal] =  useState<boolean>(false);
  const [isSmartRoutingEnabled, setIsSmartRoutingEnabled] =
    useState<Boolean>(false);

  const [algorithm, setAlgorithm] = useState<string>("1");

  const { singleRoleData } = useContext(ClientContext);
 

  useEffect(() => {
    setIsSmartRoutingEnabled(value?.isSmartRouteEnabled);
    setAlgorithm(String(value?.sequential_algorithm || "1"));
  }, [value]);
  const fetchAddedGateways = () => {
    setIsLoading(true);
    getAllGatewaysByClient(id)
      .then((data) => {
        setAddedGateways(() => {
          let arr = data.map((ele: any) => {
            return {
              ...ele,
              auth_info: {
                ...ele.auth_info,
                priority: ele.auth_info?.priority ?? 0,
              },
            };
          });
          arr.sort(
            (a: any, b: any) => a.auth_info?.priority - b.auth_info?.priority
          );
          return arr;
        });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
            "Something went wrong while fetching gateways"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    setAddedGateways([]);
    setIsCloseModal(true)
    setTimeout(() => {
      setIsCloseModal(false)
      onClose();
    }, 300);
  };
  const handleSmartRouting = () => {
    localStorage.setItem("clientIds", id);

    const inputData = {
      id: id,
      isSmartRouteEnabled: isSmartRoutingEnabled,
    };

    // @ts-ignore
    enableGateway(inputData)
      .then((data) => {})
      .catch((err) => {});
  };

  const onDragEnd = (newItems: any[]) => {
    setAddedGateways(newItems);
    let arr: any[] = [];
    newItems.forEach((ele, index) => {
      arr.push({
        ...ele,
        auth_info: { ...ele.auth_info, priority: index + 1 },
      });
    });
    setNewArr(arr);
  };

  const handleSubmit = (addedGateways: any) => {
    let gateway_priority = addedGateways.map((data: any, index: number) => {
      return {
        client_id: data?.["client_id"],
        gateway_id: data?.["gateway_id"],
        priority: index + 1,
      };
    });
    if (isSmartRoutingEnabled === false) {
      const inputData = {
        id: id,
        isSmartRouteEnabled: isSmartRoutingEnabled,
        sequential_algorithm: parseInt(algorithm),
        gateway_priority,
      };

      enableGateway(inputData)
        .then((data) => {})
        .catch((err) => {});
    }

    newArr.forEach((input_data) => {
      Object.keys(input_data)?.forEach((key) => {
        if (!["auth_info", "client_id", "gateway_id"].includes(key)) {
          delete input_data[key];
        }
      });
      const inputs = {
        client_id: input_data.client_id,
        gateway_id: input_data.gateway_id,
        method: "put",
        body: input_data,
      };

      createClientGateway(inputs)
        .then((data) => {})
        .catch((err) => {
          toast.error(
            err?.response?.data?.message ??
              "Client gateway adding failed for" + input_data.name
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
    toast.success("Priority saved");
    onClose();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      onDragEnd(addedGateways);
    } else {
      const newItems = reorder(
        addedGateways,
        result.source.index,
        result.destination.index
      );
      onDragEnd(newItems);
    }
  };

  const handleToggle = () => {
    setIsSmartRoutingEnabled(!isSmartRoutingEnabled);
  };

  return (
    <>
      {/* text-blue-500 */}
      <button className="text-blue-500 outline-none" title="Change Gateway">
        <MdListAlt
          className="h-5 w-5 cursor-pointer"
          onClick={() => {
            fetchAddedGateways();
            onOpen();
          }}
        />
      </button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        {/* <ModalContent className="sm:max-w-xxl scrollbarhide   z-[1002] !m-auto flex max-h-[100vh] min-h-[85vh] max-w-[800px]   justify-start overflow-auto sm:my-8 sm:w-full"> */}
        <Card extra={`fixed top-0 right-0 h-full rounded-none max-w-[600px] sm:w-full flex flex-col justify-start !z-[1004] overflow-y-auto scrollbar-hide overflow-auto ${
            isOpen && !isCloseModal ? "card-enter" : "card-exit"
          }`}>
            <ModalHeader className="py-[20px] mb-[20px] px-[30px] text-2xl font-bold bg-brand-500 text-white">
                <MdHighlightOff
                  className="absolute right-7 top-6 h-8 w-8 cursor-pointer text-white"
                  onClick={handleClose}
                />
                {isSmartRoutingEnabled
                ? "Smart Routing"
                : "Change gateways priority"}
            </ModalHeader>
            <ModalBody className="p-[25px]">
                <div className="new scrollbarhide min-h-[50vh] overflow-auto">
                  <div className="flex justify-end p-2 ">
                    <label className="relative mb-3 inline-flex cursor-pointer items-center">
                      <input
                        type="checkbox"
                        className="peer sr-only"
                        //@ts-ignore
                        checked={isSmartRoutingEnabled}
                        onChange={handleToggle}
                      />
                      <div className="peer h-6 w-11 rounded-full bg-gray-400 after:absolute after:left-[2px] after:top-[4px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-indigo-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-400 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-indigo-800" />
                      <span className="ml-3 text-lg font-medium text-gray-900 dark:text-gray-300">
                        Smart Routing
                      </span>
                    </label>
                  </div>

                  {!isSmartRoutingEnabled && (
                    <div className="flex justify-end border p-2 shadow">
                      <div className=" p-2 ">
                        <div className="text-end">
                          <label className=" text-lg font-medium text-gray-900 dark:text-gray-300 ">
                            Algorithm
                          </label>
                        </div>
                        <div className="mt-2 flex">
                          <div className="ml-2 flex items-center">
                            <input
                              type="radio"
                              className="h-4 w-4"
                              id="topTpBottom"
                              name="algo"
                              value="1"
                              //@ts-ignore
                              checked={algorithm == "1"}
                              onChange={(e) => {
                                setAlgorithm(e.target.value);
                              }}
                            />
                            <label
                              htmlFor="topTpBottom"
                              className="mx-2 items-center"
                            >
                              Top to Bottom
                            </label>
                          </div>
                          {/* <div className="ml-2 flex items-center">
                            <input
                              type="radio"
                              className="h-4 w-4"
                              id="BottomtoTop"
                              name="algo"
                              value="2"
                              onChange={(e) => {
                                setAlgorithm(e.target.value);
                              }}
                              checked={algorithm == "2"}
                              //@ts-ignore
                            />
                            <label
                              htmlFor="BottomtoTop"
                              className="mx-2 items-center"
                            >
                              Bottom to Top
                            </label>
                          </div> */}
                          <div className="ml-2 flex items-center">
                            <input
                              type="radio"
                              className="h-4 w-4"
                              id="RoundRobin"
                              name="algo"
                              value="3"
                              onChange={(e) => {
                                setAlgorithm(e.target.value);
                              }}
                              checked={algorithm == "3"}
                              //@ts-ignore
                            />
                            <label
                              htmlFor="RoundRobin"
                              className="mx-2 items-center"
                            >
                              Round Robin
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {isLoading && (
                    <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
                  )}
                  {isSmartRoutingEnabled ? (
                    <>
                      <div className="text-center" style={{ overflow: "auto" }}>
                        <div className="flex items-center justify-center p-3">
                          <img
                            src={smart_routingimg.default}
                            alt="smart_routing_img"
                            className="w-[300px]"
                            loading="lazy"
                          />
                        </div>

                        <h1 className="mb-[35px] text-xl font-bold ">
                          Do you require the Smart Routing feature? You can
                          conveniently configure custom routing.
                        </h1>
                        <div className="flex gap-2"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      {addedGateways.length > 0 ? (
                        <>
                          <div className="scrollbarhide mt-5  overflow-auto overflow-auto overflow-x-hidden border p-2 px-[30px] pt-5 shadow scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1 ">
                            <DragDropContext onDragEnd={handleDragEnd}>
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    {addedGateways?.map((data, index) => {
                                      let img_src = GatewayTypes.filter(
                                        (ele) => ele.name === data.name
                                      )[0]?.image;
                                      return (
                                        <Draggable
                                          key={data?.gateway_id}
                                          draggableId={data?.gateway_id?.toString()}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                              className="mb-5 rounded-xl border border-gray-200 shadow-3xl shadow-shadow-500"
                                            >
                                              <div className="flex h-fit items-center justify-between">
                                              {singleRoleData[0]?.client?.value?.client_show_method_name ? (

                                                <>
                                                  <div className="flex items-center justify-center p-3">
                                                    <img
                                                      src={img_src}
                                                      alt={data?.name}
                                                      loading="lazy"
                                                      className="h-24 w-24 object-contain"
                                                    />
                                                  </div>
                                                  <p className="h-full w-full text-center text-xl font-bold capitalize">
                                                    {data?.name}
                                                  </p>
                                                </>
                                              ) : (
                                                // Show ID
                                                <p className="h-full w-full text-center text-xl font-normal p-4 capitalize">
                                                  {data?.client_gateway_id}
                                                </p>
                                              )}

                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>
                          <div className="mt-5 inline-flex justify-start gap-2"></div>
                        </>
                      ) : (
                        !isLoading && (
                          <div className="mt-4 border p-2 py-4 shadow">
                            <h1 className="mb-[20px] text-2xl font-bold">
                              No Payment Gateways added
                            </h1>
                            <p>Please add at least two gateways to continue.</p>
                            <div className="mt-5 flex gap-2"></div>
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
              
            </ModalBody>
            <ModalFooter className="">
              <div className="flex  gap-2 pb-2 md:px-3">
              <button
                onClick={handleClose}
                className="linear rounded-xl bg-indigo-600 px-5 py-2 text-white font-medium text-navy-700 outline-none transition duration-200 hover:bg-indigo-500 active:bg-indigo-500 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
              >
                Close
              </button>
              {isSmartRoutingEnabled ? (
                <Link
                  className="inline-flex"
                  to={`/admin/smart-routing/${id}`}
                >
                  <button
                    className="linear rounded-lg bg-indigo-600 px-5 py-2 text-white
                    font-medium text-white outline-none transition duration-200 hover:bg-indigo-500 hover:text-white active:bg-indigo-500 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                    //@ts-ignore
                    onClick={handleSmartRouting}
                  >
                    Smart Routing
                  </button>
                </Link>
              ) : addedGateways.length > 0 ? (
                <button
                  onClick={() => handleSubmit(addedGateways)}
                  className="linear rounded-xl bg-indigo-600 px-5 py-2 text-white font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-500 active:bg-indigo-500 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                >
                      {isLoading ? (
                        <DivLoader className="h-6 w-6 border-indigo-500" />
                      ) : (
                        "Save"
                      )}
                    </button>
                  ) : null}
              </div>
            </ModalFooter>
          </Card>
        {/* </ModalContent> */}
      </Modal>
    </>
  );
};
export default ArrangeClientModal;
