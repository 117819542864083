import { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import { ClientContext } from "ClientProvider";
// import { getSingleRole } from "api/role";
import { getSingleRole } from "api/graphQL_role";
// import { getAllGateways } from "api/gateways";
import { getAllGateways } from "api/graphQL_gateways";
import { getId } from "utils/auth";
import BlackList from "./components/BlackList";
import CreateBlockClientsModal from "./components/CreateBlockeclientModal";
import { getAllWhiteList } from "api/whiteListed";
export type BlackListClientsRowObj = {
  id: string;
  created_at: string;
  first_name: string;
  last_name: string;
  email?: string;
  is_blacklisted?: boolean;
  bin: string;
  last_four: string;
  attempts?: string;
};

const WhiteListClients = (props: {
  gatewayTypes?: any;
  type?: any;
  client_id?: any;
}) => {
  const { gatewayTypes, type, client_id } = props;
  let gateway = gatewayTypes.filter((data: any) => data?.name === type);
  let gateway_id = gateway && gateway.length > 0 ? gateway[0]?.id : "";
  const [blackListClients, setBlackListClients] = useState<
    BlackListClientsRowObj[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [page, setPage] = useState<number>(1);
  const [totalpage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTexts, setSearchTexts] = useState<string>("");

  const { singleRoleData, setSingleRoleData, filter } =
    useContext(ClientContext);
 

  const fetchRole = () => {
    // setIsLoading(true);
    console.log("fetch role in whitelist graphql",getId())
    getSingleRole(getId())
      .then((data) => {
        setSingleRoleData(data ?? []);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
          "Something went wrong while fetching role"
        );
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    // fetchRole()
  }, []);

  const fetchBlackListClientsData = () => {
    setIsLoading(true);
    getAllWhiteList(client_id, gateway_id)
      .then((data) => {
        setBlackListClients(data);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
            "Something went wrong while fetching data!"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (gateway_id && client_id) {
      fetchBlackListClientsData();
    }
  }, [page, pageSize, filter, searchTexts, type]);

  const serchboxValueChange = async (searchTerm: string) => {
    const search_txt = searchTerm || "";
    if (search_txt !== searchTexts && searchTexts !== "") {
      await setSearchTexts(search_txt);
      setPage(1);
      setCurrentPage(1);
    } else {
      await setSearchTexts(search_txt);
    }
  };

  return (
    <>
      <span className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        {singleRoleData?.[0]?.whitelist?.value?.add_whitelist && (
          <CreateBlockClientsModal
            fetchBlackListClientsData={fetchBlackListClientsData}
            roleData={singleRoleData}
            gateway_id={gateway_id}
            client_id={client_id}
          />
        )}
      </span>
      {singleRoleData?.[0]?.whitelist?.value?.view_whitelist && (
        <div className="mt-5 pt-5">
          <BlackList
            pageSize={pageSize}
            setPageSize={setPageSize}
            page={page}
            setPage={setPage}
            totalpage={totalpage}
            totalItems={totalItems}
            currentPage={currentPage}
            roleData={singleRoleData}
            tableData={blackListClients}
            fetchBlackListClientsData={fetchBlackListClientsData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onValueChange={serchboxValueChange}
          />
        </div>
      )}
    </>
  );
};

export default WhiteListClients;
