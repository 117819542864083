import authImg from "assets/img/dashboards/Debit.png";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import routes from "routes";
import { getToken } from "utils/auth";
import bm2payLogo from "../../assets/img/bm2-white.svg";
import lightningChecksLogo  from "../../assets/img/mainlogo.png";
import paxumLogo  from "../../assets/img/paxum.png";
import {getSettings} from "api/settings"

const hostname = window.location.hostname;

const logos : any = {
  'gateway-dev.lightningchecks.com': lightningChecksLogo,
  'gateway.lightningchecks.com': lightningChecksLogo,
  'testing.lightningchecks.com': lightningChecksLogo,
  'gateway-dev.bm2pay.com': bm2payLogo,
  'gateway.bm2pay.com': bm2payLogo,
  'gateway-stagging.bm2pay.com': bm2payLogo,
  'pay.paxum.com': paxumLogo,
};
const gradients : any = {
  'gateway-dev.lightningchecks.com': 'linear-gradient(130deg, rgba(39,25,150,1) 0%, rgba(66,42,251,1) 98%)',
  'gateway.lightningchecks.com': 'linear-gradient(130deg, rgba(39,25,150,1) 0%, rgba(66,42,251,1) 98%)',
  'testing.lightningchecks.com': 'linear-gradient(130deg, rgba(39,25,150,1) 0%, rgba(66,42,251,1) 98%)',
  'gateway-dev.bm2pay.com': 'linear-gradient(130deg, rgba(19,26,42,1) 0%, rgba(24,33,53,1) 98%)',
  'gateway.bm2pay.com': 'linear-gradient(130deg, rgba(19,26,42,1) 0%, rgba(24,33,53,1) 98%)',
  'gateway-stagging.bm2pay.com': 'linear-gradient(130deg, rgba(19,26,42,1) 0%, rgba(24,33,53,1) 98%)',
  'pay.paxum.com': 'linear-gradient(130deg, rgba(33,43,58,1) 0%, rgba(47,58,77,1) 98%)',
};
const backgroundGradient = gradients[hostname] || 'linear-gradient(130deg, rgba(19,26,42,1) 0%, rgba(24,33,53,1) 98%)';

export default function Auth() {

  const [loginIcon, setLoginIcon] = React.useState<string | null>(logos[hostname] || bm2payLogo);
  const [finalGradient,setFinalGradient] = React. useState<string>(backgroundGradient);

  const navigate = useNavigate();
  React.useEffect(() => {
    let token = getToken();
    if (token) {
      navigate("/admin/dashboard");
    }
  }, [navigate]);


  const fetchSettings = () => {
    getSettings(false)
    .then((data) => {
        setLoginIcon(data.login_icon)
        if(data.gradient_from_color != "" && data.gradient_to_color != "") {
          const bggr = 'linear-gradient(130deg, '+data.gradient_from_color+' 0%, '+data.gradient_to_color+' 98%)';
          setFinalGradient(bggr)
        }
      })
    .catch((err) => {
      
    })
    .finally(() => {
    });
};

  React.useEffect(() => {
    fetchSettings();
  }, []);

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                {/* <Link to="/admin" className="mt-0 w-max lg:pt-10">
                  <div className="mx-auto flex h-fit w-fit items-center hover:cursor-pointer">
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.70994 2.11997L2.82994 5.99997L6.70994 9.87997C7.09994 10.27 7.09994 10.9 6.70994 11.29C6.31994 11.68 5.68994 11.68 5.29994 11.29L0.709941 6.69997C0.319941 6.30997 0.319941 5.67997 0.709941 5.28997L5.29994 0.699971C5.68994 0.309971 6.31994 0.309971 6.70994 0.699971C7.08994 1.08997 7.09994 1.72997 6.70994 2.11997V2.11997Z"
                        fill="#A3AED0"
                      />
                    </svg>
                    <p className="ml-3 text-sm text-gray-600">
                      Back to Dashboard
                    </p>
                  </div>
                </Link> */}
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes> 
                <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                  <div
                    className="absolute flex flex-col h-full w-full items-end justify-center items-center bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]"
                    style={{
                        background: finalGradient,
                    }}
                  >
                    <img
                        style={{ height: "auto", width: "200px" }}
                        src={loginIcon}
                        className="ml-2"
                        alt="Gateway Image"
                    />
                    {/* <div className="text-center mt-4">
                      <h3 className="text-lg font-semibold text-white" >Fast. Secure. Reliable.</h3>
                      <p className="text-lg text-indigo-200 italic">Your trusted gateway for seamless payments.</p>
                    </div> */}
                    </div>
                </div>
              </div>
              {/* <Footer /> */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
