import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay ,ModalHeader , ModalFooter } from "@chakra-ui/modal";
import React from "react";
import Card from "components/card/custom";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdCheck, MdFileCopy, MdHighlightOff } from "react-icons/md";
const InfoModalSubscription = ({ info, varient }: { info?: any, varient?: string}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [copyPayment, setCopyPayment] = React.useState<boolean>(false)
  const [copyRefund, setCopyRefund] = React.useState<boolean>(false)
  const [activeTab, setActiveTab] = React.useState<string>("Payment Response")
  const [isCloseModal, setIsCloseModal] =  React.useState<boolean>(false);
  
  const handleClose = () => {
    setIsCloseModal(true)
    setTimeout(() => {
      setIsCloseModal(false)
      onClose();
    }, 300);
  };
  return (
    <>
      <button
        className="flex disabled:opacity-50 disabled:cursor-not-allowed items-center justify-center gap-2 whitespace-nowrap px-3 py-2 text-blue-500 outline-none dark:text-gray-200"
        onClick={() => { onOpen() }}
        disabled={!info}
      >
        <BsInfoCircleFill className={varient === "small" ? "h-4 w-4" : "w-5 h-5"} />
      </button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        {/* <ModalContent className="z-[1002] !m-auto sm:my-8 sm:w-full max-w-[800px] sm:max-w-xxl flex justify-start !py-[100px] min-h-[85vh] max-h-[100vh] overflow-auto scrollbarhide "> */}
          <Card extra={`fixed top-0 right-0 h-full rounded-none max-w-[600px] sm:w-full flex flex-col justify-start !z-[1004] overflow-y-auto scrollbar-hide overflow-auto ${
            isOpen && !isCloseModal ? "card-enter" : "card-exit"
          }`}>
            <ModalHeader className="py-[20px] mb-[20px] px-[30px] text-2xl font-bold bg-brand-500 text-white">
              
                <MdHighlightOff
                  className="absolute right-7 top-6 h-8 w-8 cursor-pointer text-white"
                  onClick={handleClose}
                />
              Subscription  Info
            </ModalHeader>
            <ModalBody className="p-2">
              <div>
                <div className="relative ml-5 mr-5">
                <ul className="flex">
                    <li className="mr-2">
                      <button
                        className={`px-4 py-2 border-b-2 ${activeTab === "Payment Response"
                          ? "border-blue-500 text-blue-600"
                          : "border-transparent text-gray-500"
                          }`}
                        onClick={() => setActiveTab("Payment Response")}
                      >
                        Payment Response
                      </button>
                    </li>
                    <li className="mr-1">
                      <button
                        className={`px-4 py-2 border-b-2 ${activeTab === "Gateway Response"
                          ? "border-blue-500 text-blue-600"
                          : "border-transparent text-gray-500"
                          }`}
                        onClick={() => setActiveTab("Gateway Response")}
                      >
                        Gateway Response
                      </button>
                    </li>
                  </ul>
                  <div className="pt-4">
                    {activeTab === "home" && (
                      <div className="tab-pane fade show active">
                        <p>Content for Home</p>
                      </div>
                    )}
                    {activeTab === "profile" && (
                      <div className="tab-pane fade">
                        <p>Content for Profile</p>
                      </div>
                    )}
                    {activeTab === "contact" && (
                      <div className="tab-pane fade">
                        <p>Content for Contact</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {activeTab == "Payment Response" && (
                <>
                  <div className="  relative flex flex-col overflow-auto mx-[35px] rounded-xl border border-gray-100  scrollbar scrollbar-h-1.5 scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-w-1.5  !overflow-auto max-w-[75vw]">
                    <pre className="p-2">{JSON.stringify(info?.payment[0]?.meta_info, null, 2)}</pre>
                    <div className="absolute top-3 right-3 outline-none">
                      {copyPayment ?
                        <MdCheck className="cursor-pointer text-teal-500 mr-4"/>
                        :
                        <MdFileCopy
                          className="cursor-pointer text-teal-500 mr-4"
                          onClick={() => {
                            navigator.clipboard.writeText(JSON.stringify(info?.payment[0]?.meta_info))
                            setCopyPayment(true);
                            setTimeout(() => { setCopyPayment(false) }, 1500)
                          }}
                        />}
                    </div>
                  </div>
                </>
              )}

              {activeTab == "Gateway Response" && (
                <>
                  <div className="  relative flex flex-col overflow-auto mx-[35px] rounded-xl border border-gray-100  scrollbar scrollbar-h-1.5 scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-w-1.5  !overflow-auto max-w-[75vw]">
                    <pre className="p-2">{JSON.stringify(info?.gateway_response, null, 2)}</pre>
                    <div className="absolute top-3 right-3 outline-none">
                      {copyPayment ?
                        <MdCheck className="cursor-pointer text-teal-500 mr-4" />
                        :
                        <MdFileCopy
                          className="cursor-pointer text-teal-500 mr-4"
                          onClick={() => {
                            navigator.clipboard.writeText(JSON.stringify(info?.gateway_response))
                            setCopyPayment(true);
                            setTimeout(() => { setCopyPayment(false) }, 1500)
                          }}
                        />}
                    </div>
                  </div>
                </>
              )}
              
            </ModalBody>
            <ModalFooter className="mt-[10px]">
              <div className="mt-5 flex gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={handleClose}
                  className="outline-none linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
              </div>
          </ModalFooter>
          </Card>
        {/* </ModalContent> */}
      </Modal>
    </>
  );
};
export default InfoModalSubscription;

