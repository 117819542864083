export const allCryptoCurrency = [
    { name: "Select Crypto Currency", code: "" },
    { name: "Bitcoin (BTC)", code: "BTC" },
    { name: "Ethereum (ETH)", code: "ETH" },
    { name: "Binance Coin (BNB)", code: "BNB" },
    { name: "Bitcoin Cash (BCH)", code: "BCH" },
    { name: "Litecoin (LTC)", code: "LTC" },
    { name: "Tron (TRX)", code: "TRX" },
    { name: "Tether (USDT)", code: "USDT" },
    { name: "USD Coin (USDC)", code: "USDC" },
]

export const allBlockChainNetwork = (cryptoCurrency: string) => {
    const currency_symbols: any = {
        BTC: [
            {
                name: "Select Blockchain Network",
                code: ""
            },
            {
                name: "bitcoin",
                code: "bitcoin"
            },
            {
                name: "lightning",
                code: "lightning"
            }
        ],
        LTC: [
            {
                name: "Select Blockchain Network",
                code: ""
            },
            {
                name: "litecoin",
                code: "litecoin"
            }
        ],
        BCH: [
            {
                name: "Select Blockchain Network",
                code: ""
            },
            {
                name: "bitcoincash",
                code: "bitcoincash"
            }
        ],
        BNB: [
            {
                name: "Select Blockchain Network",
                code: ""
            },
            {
                name: "bsc-bep20",
                code: "bsc-bep20"
            }
        ],
        TRX: [
            {
                name: "Select Blockchain Network",
                code: ""
            },
            {
                name: "tron-trc20",
                code: "tron-trc20"
            }
        ],
        ETH: [
            {
                name: "Select Blockchain Network",
                code: ""
            },
            {
                name: "ethereum-erc20",
                code: "ethereum-erc20"
            },
            {
                name: "bsc-bep20",
                code: "bsc-bep20"
            }
        ],
        USDT: [
            {
                name: "Select Blockchain Network",
                code: ""
            },
            {
                name: "ethereum-erc20",
                code: "ethereum-erc20"
            },
            {
                name: "bsc-bep20",
                code: "bsc-bep20"
            },
            {
                name: "tron-trc20",
                code: "tron-trc20"
            }
        ],
        USDC: [
            {
                name: "Select Blockchain Network",
                code: ""
            },
            {
                name: "ethereum-erc20",
                code: "ethereum-erc20"
            },
            {
                name: "bsc-bep20",
                code: "bsc-bep20"
            }
        ]
    }
    return currency_symbols[cryptoCurrency];
}