// import toast from "react-hot-toast";
// import authRequest from "utils/authRequest";

import authGraphQLRequest from "utils/authGraphQLRequest"

const toGraphQLInput = (obj: any): string => {
  if (typeof obj === "object" && obj !== null) {
    return `{${Object.entries(obj)
      .map(([key, value]) => `${key}: ${toGraphQLInput(value)}`)
      .join(", ")}}`;
  } else if (Array.isArray(obj)) {
    return `[${obj.map(toGraphQLInput).join(", ")}]`;
  } else if (typeof obj === "string") {
    return `"${obj}"`;
  } else {
    return String(obj); 
  }
};

export const getRolePermission = (
  page?: number,
  pageSize?: number,
  searchTexts? : string
): Promise<any> => {

  return new Promise((resolve, reject) => {
        let queryString = `{
  allRoles(page: ${page}, per_page: ${pageSize}, searchTexts: "${searchTexts}"){
  roles{
    id
    role_name
    api
    dashboard
    client
    organization
    payment
    payment_method
    user
    role
    subscription
    token
    payout
    super_admin
    settlement
    blacklist
    settings
    customers
    whitelist

  }
}
}`;
    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_roles'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response role by Id ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err role by Id ::', { err });
        reject(err);
      });
  });
};

// export const getRoleDrop = (): Promise<any> => {

//     return new Promise((resolve, reject) => {
//       authRequest({
//         url: `/api/v1/role/dropdown`,
//         method: "get",
//       })
//         .then(({ data }) => {
//           resolve(data);
//         })
//         .catch((err) => {
//           reject(err);
//         });
//     });
//   };

export const getSingleRole = (id: any): Promise<any> => {

  return new Promise((resolve, reject) => {
    let queryString = `{
        role(id:"${id}"){
          id
          role_name
          api
          dashboard
          client
          organization
          payment
          payment_method
          user
          role
          subscription
          token
          payout
          super_admin
          settlement
          blacklist
          settings
          customers
          whitelist

        }
      }
  `;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_roles'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response role by Id ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err role by Id ::', { err });
        reject(err);
      });
  });
};

//   export const postUserPermission = (data: any): Promise<any> => {
//     return new Promise((resolve, reject) => {
//       authRequest({
//         url: "/api/v1/role/create",
//         method: "post",
//         data,
//       })
//         .then(({ data }) => {
//           resolve(data);
//           toast.success(data?.message?data?.message:"Role Created Successfully");
//         })
//         .catch((err) => {
//           reject(err);
//           toast.error(err?.message);
//         });
//     });
//   };
//   export const updateUserPermission = (data: any,id:any): Promise<any> => {
//     return new Promise((resolve, reject) => {
//       authRequest({
//         url: `/api/v1/role/update/${id}`,
//         method: "put",
//         data,
//       })
//         .then(({ data }) => {
//           resolve(data);
//         })
//         .catch((err) => {
//           reject(err);
//         });
//     });
//   };
export const updateUserPermission = (data: any, id: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `
mutation {
  updateRole(id: "${id}", input: ${toGraphQLInput(data)}) {
    status
  }
}

    `;
    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_update_roles'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response role by Id ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err role by Id ::', { err });
        reject(err);
      });
  });
};
//   export const postUserPermission = (data: any): Promise<any> => {
//     return new Promise((resolve, reject) => {
//       authRequest({
//         url: "/api/v1/role/create",
//         method: "post",
//         data,
//       })
//         .then(({ data }) => {
//           resolve(data);
//           toast.success(data?.message?data?.message:"Role Created Successfully");
//         })
//         .catch((err) => {
//           reject(err);
//           toast.error(err?.message);
//         });
//     });
//   };
export const postUserPermission = (data: any): Promise<any> => {

  return new Promise((resolve, reject) => {
    let queryString = `
mutation {
  createRole(input: ${toGraphQLInput(data)}) {
    role {
      id
    }
  }
}

    `;

    console.log("queryString",queryString);
    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_create_roles'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response role by Id ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err role by Id ::', { err });
        reject(err);
      });
  });

};
//   export const updateUserPermission = (data: any,id:any): Promise<any> => {
//     return new Promise((resolve, reject) => {
//       authRequest({
//         url: `/api/v1/role/update/${id}`,
//         method: "put",
//         data,
//       })
//         .then(({ data }) => {
//           resolve(data);
//         })
//         .catch((err) => {
//           reject(err);
//         });
//     });
//   };

//   export const deleteRole = (id: string): Promise<any> => {
//     return new Promise((resolve, reject) => {
//       authRequest({
//         url: "/api/v1/role/delete/" + id,
//         method: "delete",
//       })
//         .then(({ data }) => {
//           resolve(data);
//         })
//         .catch((err) => {
//           reject(err);
//         });
//     });
//   };
export const deleteRole = (id: string , role_id : any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `
mutation {
  deleteRole(id: "${id}" , role_id : "${role_id}") {
    status
    message
  }
}
  `;
    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_delete_roles'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response role by Id ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err role by Id ::', { err });
        reject(err);
      });
  });
};