/* eslint-disable */
import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { ClientContext } from "ClientProvider";
// chakra imports

export const SidebarLinks = (props: { routes: RoutesType[] }): JSX.Element => {
  const { singleRoleData } = useContext(ClientContext);
  // Chakra color mode
  let location = useLocation();
  const navigate = useNavigate();

  const { routes } = props;

  //// console.log("routes", routes, singleRoleData, location);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const routeHiden = (route: any) => {
    // // console.log("route.path", route.layout, singleRoleData);

    let isStatus = false;
    if (
      route?.path ===
      singleRoleData?.[0]?.payment_method?.name?.replace(" ", "")
    ) {
      isStatus =
        singleRoleData?.[0]?.payment_method?.value?.view_payment_method_list;
      if (
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.payment_method?.value?.view_payment_method_list
      ) {
        navigate("/admin/dashboard");
      }
    }
    if (
      route?.path === singleRoleData?.[0]?.subscription?.name?.replace(" ", "")

    ) {
      isStatus = singleRoleData?.[0]?.subscription?.value?.view_subscription_list;
      if (
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.subscription?.value?.view_subscription_list
      ) {
        navigate("/admin/dashboard");

      }

    }
    if (
      route?.path ===
      singleRoleData?.[0]?.client?.name?.replace(" ", "") + "s"
    ) {
      isStatus = singleRoleData?.[0]?.client?.value?.view_client_list;
      if (
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.client?.value?.view_client_list
      ) {
        navigate("/admin/dashboard");
      }
    }

    if (
      route?.path ===
      singleRoleData?.[0]?.customers?.name?.replace(" ", "")
    ) {
      isStatus = singleRoleData?.[0]?.customers?.value?.view_customers;
      if (
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.customers?.value?.view_customers
      ) {
        navigate("/admin/dashboard");
      }
    }

    if (
      route?.path ===
      singleRoleData?.[0]?.api?.name?.replace(" ", "")
    ) {
      isStatus = singleRoleData?.[0]?.api?.value?.view_api_list;
      // isStatus = true
      if (
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.api?.value?.view_api_list
        // false
      ) {
        navigate("/admin/dashboard");
      }
    }

    if (
      route?.path ===
      singleRoleData?.[0]?.settlement?.name?.replace(" ", "")
    ) {
      isStatus = singleRoleData?.[0]?.settlement?.value?.view_settlement_list;
      if (
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.settlement?.value?.view_settlement_list
      ) {
        navigate("/admin/dashboard");
      }
    }

    if (
      route?.path ===
      singleRoleData?.[0]?.organization?.name?.replace(" ", "") + "s"
    ) {
      isStatus =
        singleRoleData?.[0]?.organization?.value?.view_organization_list;
      if (
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.organization?.value?.view_organization_list
      ) {
        navigate("/admin/dashboard");
      }
    }

    // if (
    //   route?.path ===
    //   singleRoleData?.[0]?.whitelist?.name
    // ) {
    //   isStatus =
    //     singleRoleData?.[0]?.whitelist?.value?.view_whitelist_list;
    //   if (
    //     location?.pathname === route?.layout + "/" + route?.path &&
    //     !singleRoleData?.[0]?.whitelist?.value?.view_whitelist_list
    //   ) {
    //     navigate("/admin/dashboard");
    //   }
    // }

    // if (
      
    //   route?.path ==="whitelist"
    //   // singleRoleData?.[0]?.organization?.name?.replace(" ", "") + "s"
    // ) {
    //   isStatus =true
    //     // singleRoleData?.[0]?.organization?.value?.view_organization_list;
    //   if (
    //     false
    //     // location?.pathname === route?.layout + "/" + route?.path &&
    //     // !singleRoleData?.[0]?.organization?.value?.view_organization_list
    //   ) {
    //     navigate("/admin/dashboard");
    //   }
    // }

    if (
      
      route?.path ===
      singleRoleData?.[0]?.blacklist?.name
    ) {
      isStatus =
        singleRoleData?.[0]?.blacklist?.value?.view_blacklist_list;
      if (
        
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.blacklist?.value?.view_blacklist_list
      ) {
        navigate("/admin/dashboard");
      }
    }

    if (
      
      route?.path ===
      singleRoleData?.[0]?.settings?.name
    ) {
      isStatus =
        singleRoleData?.[0]?.settings?.value?.view_settings;
      if (
        
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.settings?.value?.view_settings
      ) {
        navigate("/admin/dashboard");
      }
    }

    if (
      route?.path ===
      singleRoleData?.[0]?.payment?.name?.replace(" ", "") + "s"
    ) {
      isStatus = singleRoleData?.[0]?.payment?.value?.view_payment_list;
      if (
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.payment?.value?.view_payment_list
      ) {
        navigate("/admin/dashboard");
      }
    }
    if (
      route?.path == singleRoleData?.[0]?.token?.name?.replace(" ", "") + "s"

    ) {
      isStatus = singleRoleData?.[0]?.token?.value?.view_token_list;;
      if (
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.token?.value?.view_token_list
      ) {
        navigate("/admin/dashboard");
      }
    }
    if (
      route?.path == singleRoleData?.[0]?.payout?.name?.replace(" ", "")

    ) {
      isStatus = singleRoleData?.[0]?.payout?.value?.view_payout_list;
      if (
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.payout?.value?.view_payout_list
      ) {
        navigate("/admin/dashboard");
      }
    }
    if (route?.path === singleRoleData?.[0]?.role?.name?.replace(" ", "")) {
      isStatus = singleRoleData?.[0]?.role?.value?.view_role_list;
      if (
        location?.pathname === route?.layout + "/" + route?.path &&
        !singleRoleData?.[0]?.role?.value?.view_role_list
      ) {
        navigate("/admin/dashboard");
      }
    }
    if (
      route?.path ===
      singleRoleData?.[0]?.user?.name?.replace(" ", "") + "s"
    ) {
      isStatus = (singleRoleData?.[0]?.user?.value?.view_user_list || singleRoleData?.[0]?.user?.value?.view_self_created_user);
      if (
        location?.pathname === route?.layout + "/" + route?.path &&
        !(
          singleRoleData?.[0]?.user?.value?.view_user_list ||
          singleRoleData?.[0]?.user?.value?.view_self_created_user
        )
      ) {
        navigate("/admin/dashboard");
      }
    }
    if (route?.name === "Dashboard") {
      isStatus = true;
    }

    return isStatus;
  };

  const createLinks = (routes: RoutesType[]) => {
    // // console.log("routes", routes)
    return routes.map((route, index) => {
      if (route.layout === "/admin") {
        if (routeHiden(route)) {
          return (
            <Link key={index} to={route.layout + "/" + route.path}>
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                  key={index}
                >
                  <span
                    className={`${activeRoute(route.path) === true
                      ? "font-bold text-brand-500 dark:text-white"
                      : "font-medium text-gray-600"
                      }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${activeRoute(route.path) === true
                      ? "font-bold text-navy-700 dark:text-white"
                      : "font-medium text-gray-600"
                      }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                ) : null}
              </div>
            </Link>
          );
        }
      }
      if (route.layout === "/auth" || route.layout === "/rtl") {
        if (!route?.isHidden) {
          return (
            <Link key={index} to={route.layout + "/" + route.path}>
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                  key={index}
                >
                  <span
                    className={`${activeRoute(route.path) === true
                      ? "font-bold text-brand-500 dark:text-white"
                      : "font-medium text-gray-600"
                      }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${activeRoute(route.path) === true
                      ? "font-bold text-navy-700 dark:text-white"
                      : "font-medium text-gray-600"
                      }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                ) : null}
              </div>
            </Link>
          );
        }
      }
    });
  };
  // BRAND
  return <>{createLinks(routes)}</>;
};

export default SidebarLinks;
