import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card";
import DivLoader from "components/divloader/DivLoader";
import React from "react";
import { PaymentMethodRowObj } from "..";
import CreatePaymentMethoddModal from "./CreateRoleMethod";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import DeleteRoleModal from "./DeleteRoleModal";
import Pagination from "components/pagination";
import CustomToolTip from "components/tooltips";
import { useNavigate } from "react-router-dom";
import Searchbox from "components/fields/Searchbox";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { RiArrowUpDownFill } from "react-icons/ri";


function RoleMethodTable(props: {
  tableData: any;
  fetchRole: () => void;
  fetchSingleRole: () => void;
  isLoading: boolean;
  page: number;
  setPage: any;
  totalpage: number;
  totalItems: number;
  currentPage: number;
  pageSize: number;
  setPageSize: any;
  singleRoleData: any;
  onValueChange: (value: string) => void;
}) {
  const {
    tableData,
    fetchRole,
    fetchSingleRole,
    page,
    setPage,
    currentPage,
    totalpage,
    totalItems,
    pageSize,
    setPageSize,
    singleRoleData,
    onValueChange
  } = props;
  let defaultData = tableData;

  const [{ pageIndex }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  });
  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const navigate = useNavigate();


  const columns = [
    columnHelper.accessor("role_name", {
      id: "role_name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Role name
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info?.row?.original?.role_name}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("users", {
      id: "users",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">Assigned Users</p>
      ),
      cell: (info) => {
        const userList: any = info.row.original.users || [];
        const userCount = userList.length;
      
        return (
          <div className="text-navy-700 font-bold">
            <CustomToolTip  
              content={
                userCount > 0 ? (
                  <div className="flex flex-wrap gap-2 max-w-[200px]">  
                    {userList.map((user: any, index: any) => (
                      <span 
                        key={index} 
                        className="bg-gray-200 text-gray-800 px-2 py-1 rounded text-xs font-medium cursor-pointer"
                        onClick={() => navigate("/admin/users", { state: { search: user.name } })}
                      >
                        {user.name}
                      </span>
                    ))}
                  </div>
                ) : (
                  <span className="text-gray-500 text-xs">No Users</span>
                )
              }
              trigger={userCount}
              header="Assigned Users"
            />
          </div>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const gatewaysA = rowA.getValue(columnId) as Record<string, boolean>; // Explicit type
        const gatewaysB = rowB.getValue(columnId) as Record<string, boolean>;
      
        const aCount = Object.keys(gatewaysA || {}).filter((key) => gatewaysA[key]).length;
        const bCount = Object.keys(gatewaysB || {}).filter((key) => gatewaysB[key]).length;
      
        return aCount - bCount; // Sort numerically by gateway count
      },
      enableSorting: true,       
    }),
    columnHelper.accessor("dashboard", {
      id: "dashboard",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Dashboard
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.dashboard?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.dashboard?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.dashboard?.status as keyof typeof order || "none";
        const statusB = rowB.original.dashboard?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("client", {
      id: "client",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Client
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.client?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.client?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.client?.status as keyof typeof order || "none";
        const statusB = rowB.original.client?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("organization", {
      id: "organization",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Organization
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.organization?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.organization?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.organization?.status as keyof typeof order || "none";
        const statusB = rowB.original.organization?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("payment", {
      id: "payment",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Transactions
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.payment?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.payment?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.payment?.status as keyof typeof order || "none";
        const statusB = rowB.original.payment?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("token", {
      id: "token",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Tokens
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.token?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.token?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.token?.status as keyof typeof order || "none";
        const statusB = rowB.original.token?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("user", {
      id: "user",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">User</p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.user?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.user?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.user?.status as keyof typeof order || "none";
        const statusB = rowB.original.user?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Payout
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.payout?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.payout?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.payout?.status as keyof typeof order || "none";
        const statusB = rowB.original.payout?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("payment_method", {
      id: "payment_method",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Gateways
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.payment_method?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.payment_method?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.payment_method?.status as keyof typeof order || "none";
        const statusB = rowB.original.payment_method?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("role", {
      id: "role",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">Role</p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.role?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.role?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.role?.status as keyof typeof order || "none";
        const statusB = rowB.original.role?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("subscription", {
      id: "subscription",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Subscription
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.subscription?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.subscription?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.subscription?.status as keyof typeof order || "none";
        const statusB = rowB.original.subscription?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("settlement", {
      id: "settlement",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Settlement
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.settlement?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.settlement?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.settlement?.status as keyof typeof order || "none";
        const statusB = rowB.original.settlement?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("api", {
      id: "api",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">API</p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.api?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.api?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.api?.status as keyof typeof order || "none";
        const statusB = rowB.original.api?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("blacklist", {
      id: "blacklist",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Black List
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.blacklist?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.blacklist?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.blacklist?.status as keyof typeof order || "none";
        const statusB = rowB.original.blacklist?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("whitelist", {
      id: "whitelist",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          White List
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.whitelist?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.whitelist?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.whitelist?.status as keyof typeof order || "none";
        const statusB = rowB.original.whitelist?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("settings", {
      id: "settings",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Settings
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.settings?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.settings?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.settings?.status as keyof typeof order || "none";
        const statusB = rowB.original.settings?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("customers", {
      id: "customers",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Customers
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.customers?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.customers?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.customers?.status as keyof typeof order || "none";
        const statusB = rowB.original.customers?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          ACTION
        </p>
      ),
      cell: (info: any) => (
        <p className="flex items-center gap-3 text-sm font-bold">
          <CreatePaymentMethoddModal
            fetchRole={fetchRole}
            id={info?.row?.original?.id}
            data={info.row?.original}
            singleRoleData={singleRoleData}
            fetchSingleRole={fetchSingleRole}
          />
          <CreatePaymentMethoddModal
            fetchRole={fetchRole}
            is_info={true}
            id={info?.row?.original?.id}
            data={info.row?.original}
            singleRoleData={singleRoleData}
            fetchSingleRole={fetchSingleRole}
          />
          {singleRoleData?.[0]?.role?.value?.delete_role && (
            <DeleteRoleModal
              fetchRole={fetchRole}
              id={info?.row?.original?.id}
              data={info?.row?.original}
              roles={tableData}
              name={info?.row?.original?.role_name ?? ""}
            />
          )}
        </p>
      ),
      enableSorting:false
    }),
  ]; // eslint-disable-next-line
  const columnsNonAction = [
    columnHelper.accessor("role_name", {
      id: "role_name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Role name
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info?.row?.original?.role_name}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("users", {
      id: "users",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">Assigned Users</p>
      ),
      cell: (info) => {
        const userList: any = info.row.original.users || [];
        const userCount = userList.length;
      
        return (
          <div className="text-navy-700 font-bold">
            <CustomToolTip  
              content={
                userCount > 0 ? (
                  <div className="flex flex-wrap gap-2 max-w-[200px]">  
                    {userList.map((user: any, index: any) => (
                      <span 
                        key={index} 
                        className="bg-gray-200 text-gray-800 px-2 py-1 rounded text-xs font-medium cursor-pointer"
                        onClick={() => navigate("/admin/users", { state: { search: user.name } })}
                      >
                        {user.name}
                      </span>
                    ))}
                  </div>
                ) : (
                  <span className="text-gray-500 text-xs">No Users</span>
                )
              }
              trigger={userCount}
              header="Assigned Users"
            />
          </div>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const gatewaysA = rowA.getValue(columnId) as Record<string, boolean>; // Explicit type
        const gatewaysB = rowB.getValue(columnId) as Record<string, boolean>;
      
        const aCount = Object.keys(gatewaysA || {}).filter((key) => gatewaysA[key]).length;
        const bCount = Object.keys(gatewaysB || {}).filter((key) => gatewaysB[key]).length;
      
        return aCount - bCount; // Sort numerically by gateway count
      },
      enableSorting: true,       
    }),
    columnHelper.accessor("dashboard", {
      id: "dashboard",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Dashboard
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.dashboard?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.dashboard?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.dashboard?.status as keyof typeof order || "none";
        const statusB = rowB.original.dashboard?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("client", {
      id: "client",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Client
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.client?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.client?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.client?.status as keyof typeof order || "none";
        const statusB = rowB.original.client?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("organization", {
      id: "organization",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Organization
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.organization?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.organization?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.organization?.status as keyof typeof order || "none";
        const statusB = rowB.original.organization?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("payment", {
      id: "payment",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Transactions
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.payment?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.payment?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.payment?.status as keyof typeof order || "none";
        const statusB = rowB.original.payment?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("token", {
      id: "token",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Tokens
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.token?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.token?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.token?.status as keyof typeof order || "none";
        const statusB = rowB.original.token?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("user", {
      id: "user",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">User</p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.user?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.user?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.user?.status as keyof typeof order || "none";
        const statusB = rowB.original.user?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Payout
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.payout?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.payout?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.payout?.status as keyof typeof order || "none";
        const statusB = rowB.original.payout?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("payment_method", {
      id: "payment_method",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Gateways
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.payment_method?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.payment_method?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.payment_method?.status as keyof typeof order || "none";
        const statusB = rowB.original.payment_method?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("role", {
      id: "role",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">Role</p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.role?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.role?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.role?.status as keyof typeof order || "none";
        const statusB = rowB.original.role?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("subscription", {
      id: "subscription",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Subscription
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.subscription?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.subscription?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.subscription?.status as keyof typeof order || "none";
        const statusB = rowB.original.subscription?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("settlement", {
      id: "settlement",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Settlement
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.settlement?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.settlement?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.settlement?.status as keyof typeof order || "none";
        const statusB = rowB.original.settlement?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("api", {
      id: "api",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">API</p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.api?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.api?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.api?.status as keyof typeof order || "none";
        const statusB = rowB.original.api?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("blacklist", {
      id: "blacklist",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Black List
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.blacklist?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.blacklist?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.blacklist?.status as keyof typeof order || "none";
        const statusB = rowB.original.blacklist?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("whitelist", {
      id: "whitelist",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          White List
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.whitelist?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.whitelist?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.whitelist?.status as keyof typeof order || "none";
        const statusB = rowB.original.whitelist?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("settings", {
      id: "settings",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Settings
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.settings?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.settings?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.settings?.status as keyof typeof order || "none";
        const statusB = rowB.original.settings?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
    columnHelper.accessor("customers", {
      id: "customers",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Customers
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.customers?.status === "all" ? (
            <MdCheckCircle className="h-5 w-5 text-teal-500" />
          ) : info?.row?.original?.customers?.status === "partial" ? (
            <MdCheckCircle className="h-5 w-5 text-orange-400" />
          ) : (
            <MdCancel className="h-5 w-5 text-red-500" />
          )}
        </p>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.original.customers?.status as keyof typeof order || "none";
        const statusB = rowB.original.customers?.status as keyof typeof order || "none";
        const order = { all: 1, partial: 2, none: 3 };
        return order[statusA] - order[statusB];
      },
      enableSorting: true, 
    }),
  ]; // eslint-disable-next-line

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [data, setData] = React.useState(() => [...defaultData]);
  const [searchVal, setSearchVal] = React.useState<any>("");

  React.useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const table = useReactTable({
    data,
    columns:
      singleRoleData?.[0]?.role?.value?.view_role ||
      singleRoleData?.[0]?.role?.value?.delete_role ||
      singleRoleData?.[0]?.role?.value?.edit_role
        ? columns
        : columnsNonAction,

    state: {
      pagination,
      sorting,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handleValueChange = (e: any) => {

    onValueChange(e);
    setSearchVal(e);
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex flex-wrap items-center justify-between pt-5">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Role
        </div> 
        <Searchbox onSearch={handleValueChange} />
      </header>
      <div className="relative mt-4 overflow-x-auto overflow-x-auto shadow-md scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 sm:rounded-lg ">
        {props.isLoading ? (
          <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
        ) : (
          <table className="w-full w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              {table.getHeaderGroups().map((headerGroup: any) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header: any) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-200 p-2 pb-2 pr-4 pt-4 text-start"
                      >
                        <div className="flex items-center text-xs text-gray-200">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.getCanSort() && ( // Only show if sorting is enabled
                            <span className="ml-2">
                              {header.column.getIsSorted() === "asc" ? (
                                <BsArrowUp size={14} className="text-gray-900" />
                              ) : header.column.getIsSorted() === "desc" ? (
                                <BsArrowDown size={14} className="text-gray-900" />
                              ) : (
                                <RiArrowUpDownFill size={14} className="text-gray-900" />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row: any) => {
                return (
                  <tr
                    key={row.id}
                    className="border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                  >
                    {row.getVisibleCells().map((cell: any) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 p-2  py-3 pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <Pagination
        setPage={setPage}
        page={page}
        totalpage={totalpage}
        currentPage={currentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        arraySize={[50, 100, 200]}
      />
    </Card>
  );
}

export default RoleMethodTable;
const columnHelper = createColumnHelper<PaymentMethodRowObj>();
