import toast from "react-hot-toast";
import authRequest from "utils/authRequest";

export const getSettings = (authRequired: boolean = true): Promise<any> => {
  
    return new Promise((resolve, reject) => {
      authRequest({
        url: `/api/v1/settings`,
        method: "get",
        authRequired
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
};

export const updateSettings = (data: any): Promise<any> => {
  
    return new Promise((resolve, reject) => {
      authRequest({
        url: `/api/v1/settings`,
        method: "put",
        data
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
};