import authRequest from "../utils/authRequest";
import moment from "moment";


export const getAllPayouts = (
    page: number,
    pageSize: number = 10,
    type: any,
    searchTexts: any

): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            // url: "/api/v1/payout/details/1/10?is_live=",
            // url: "/api/v1/payout/details"+ "?is_live=" + "false",
            url: `/api/v1/payout/details?is_live=${type == "all" ? "all" : type == "live" ? true : false}&page=${searchTexts ? 1 : page}&per_page=${pageSize}&searchTexts=${searchTexts ? searchTexts : ""}`,

            method: "get",
            // params: params
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const DownloadCSV = (
  type?: string,
  client?: any,
  gateway?: any,
  payment_status?: any,
  start_date?: any,
  end_date?: any,


): Promise<any> => {
  return new Promise((resolve, reject) => {
    const data = `${('?is_live=' + (type === "live" ? true : type === "test" ? false : "all"))}${client?.length > 0 ? ('&client-ids=' + client) : ""}${gateway?.length > 0 ? ('&gateway-id=' + gateway) : ""}${payment_status?.length > 0 ? ('&status=' + payment_status) : ""}${start_date ? ('&start-date=' + moment(start_date).format("YYYY-MM-DD")) : ""}${end_date ? ('&end-date=' + moment(end_date).format("YYYY-MM-DD")) : ""}`
    authRequest({
      url: "/api/v1/payout/download-csv" + `${data}`,
      method: "get",
    })
      .then(({ data }) => {
        //// console.log('data49', data)
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}


