import React, { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import { getId } from "utils/auth";
// import { getSingleRole } from "api/role";
import { getSingleRole } from "api/graphQL_role";
import { getSubscriptuionsFilter } from "api/subscription";
import { getAllSubscription } from "api/graphQL_subscriptions";
// import { getAllGateways } from "api/gateways";
import { getAllGateways } from "api/graphQL_gateways";
import SubscriptionList from "./components/subscriptionlist";
import { ClientContext } from "ClientProvider";
export type SubscriptionObj = {
  start_date: string;
  interval: string;
  managed_by:any;
  duration: number;
  amount: number;
  gateway_hash: string;
  currency: string;
  gateway_name: string;
  descriptor: string;
  paid_payments: number;
  transaction: string;
  billing_cycles: number;
  status: string;
  id: string;
  client: string;
  client_id:string;
  client_secret:string;
  subscription_id: string;
  subscription_provider : string;
  subscription_provider_name : string;
  payment: any;
  created_at: any;
  next_payment_date: any;
};
const Subscription = () => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionObj[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [page, setPage] = useState<number>(1);
  const [totalpage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [statusChanged,setStatusChanged] = useState(false);
  const [pageSize, setPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTexts, setSearchTexts] = useState<string>("");
  const [allGateways, setAllGateways] = useState();
  const { singleRoleData } = useContext(ClientContext);
  const [isRoleDataLoaded, setIsRoleDataLoaded] = useState(false);

  useEffect(() => {
    if (Array.isArray(singleRoleData) && singleRoleData.length > 0) {
      setIsRoleDataLoaded(true);
    }
  }, [singleRoleData]);

  const [filter, setFilter] = useState({
    clients: [],
    interval: [],
    duration: null,
    status: [],
  });
  const fetchSubscriptions = () => {
    setIsLoading(true);
    if (pageSize === null || pageSize === undefined) {
      setIsLoading(false);
      return;
    }
    const params = { page: page, per_page: pageSize, searchTexts: searchTexts,filter:filter };
    getAllSubscription(params)
      .then((data) => {
        setSubscriptions(data?.subscriptions ?? []);
        setTotalPage(data.total_pages);
        setTotalItems(data.total_items);
        setCurrentPage(data.current_page);
        setIsLoading(false);
      })
      .catch((err) => {
        setSubscriptions([]);
       
      })
      
  };

  const fetchAllGateways = () => {
    setIsLoading(true);
    getAllGateways()
      .then((data) => {
        
        setAllGateways(data);
      })
      .catch((err) => {
       
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
   
    fetchAllGateways();
  }, []);
  const serchboxValueChange = async (searchTerm: string) => {
    const search_txt = searchTerm || "";
    if (search_txt !== searchTexts && searchTexts !== "") {
      await setSearchTexts(search_txt);
      setPage(1);
      setCurrentPage(1);
    } else {
      await setSearchTexts(search_txt);
    }
  };

  const filterData = () => {
    setIsLoading(true);
    const params = { page: page, per_page: pageSize, searchTexts: searchTexts };
    getAllSubscription(params)
      .then((data) => {
        setSubscriptions(data?.subscriptions ?? []);
        setTotalPage(data.total_pages);
        setTotalItems(data.total_items);
        setCurrentPage(data.current_page);
      })
      .catch((err) => {
        setSubscriptions([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  // useEffect(() => {
  //   if (page !== 0 && searchTexts === "") {
  //     if (filter?.status) {
  //       filterData();
  //     }
  //   } else {
  //     fetchSearchs();
  //   }
  // }, [filter?.status, filter?.clients, filter?.duration, filter?.interval,statusChanged,isRoleDataLoaded]);

  useEffect(() => {
    fetchSearchs();
  }, [searchTexts, page, pageSize,statusChanged,isRoleDataLoaded,filter]);

  const fetchSearchs = () => {
    fetchSubscriptions();
  };
  return (
    <>
      <div className="mt-5">
        {isRoleDataLoaded && (
          <SubscriptionList
            pageSize={pageSize}
            setPageSize={setPageSize}
            data={subscriptions}
            isLoading={isLoading}
            page={page}
            setPage={setPage}
            totalpage={totalpage}
            totalItems={totalItems}
            currentPage={currentPage}
            onValueChange={serchboxValueChange}
            roleData={singleRoleData}
            allGateways={allGateways}
            fetchSubscriptions={fetchSubscriptions}
            filterData={filterData}
            filter={filter}
            setFilter={setFilter}
            setStatusChanged={setStatusChanged}
            setIsLoading={setIsLoading}
          />
        )}
      </div>
    </>
  );
};

export default Subscription;
