import React, { useState, useEffect, useRef } from 'react';
import { BsArrowLeft, BsArrowRight, BsCheck, BsCopy, BsLockFill } from "react-icons/bs";
import { allCountries } from 'utils/countryList';
import { allCryptoCurrency , allBlockChainNetwork } from 'utils/cryptoCurrenctList';
import bm2payLogo from "./assets/img/bm2pay.svg";
import lightningChecksLogo  from "./assets/img/mainlogo.png";
import paxumLogo  from "./assets/img/paxum.png";
import { createPayment, getCheckOutDetails, getClient , generateCheckoutLink, checkLink, getCheckOutDetailsCheckoutPage } from "api/clients";
import { useParams } from 'react-router-dom';
import Link from 'antd/es/typography/Link';
import toast from 'react-hot-toast';
import pix from 'assets/img/payment-clients/pix.png';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillExclamationCircle } from 'react-icons/ai';
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import { useDisclosure } from "@chakra-ui/hooks";
import DivLoader from "components/divloader/DivLoader";
import sampleImage  from "assets/img/payment-clients/placeholder.png";
import { validate } from 'uuid';
import { getCurrencySymbol } from "utils/currencyList"; 
import { stubTrue } from 'lodash';
import { QRCodeCanvas } from "qrcode.react";


interface PaymentData {
    first_name:string;
    middle_name:string;
    last_name:string;
    name: string;
    email: string;
    phone: string;
    cardHolderName: string;
    cardNumber: string;
    expiry: string;
    cvv: string;
    address: string;
    state: string;
    city: string;
    country: string;
    postalCode: string;
}

interface PixPaymentData {
    first_name:string;
    last_name:string;
    identification_number: string;
    identification_type: string;
}

interface CryptoPaymentData {
    first_name:string;
    last_name:string;
    email: string;
    phone: string;
    country: string;
    crypto_currency : string;
    blockchain_network : string;
}

const hostname = window.location.hostname;
const logos : any = {
    'gateway-dev.lightningchecks.com': lightningChecksLogo,
    'gateway.lightningchecks.com': lightningChecksLogo,
    'testing.lightningchecks.com': lightningChecksLogo,
    'gateway-dev.bm2pay.com': bm2payLogo,
    'gateway.bm2pay.com': bm2payLogo,
    'gateway-stagging.bm2pay.com': bm2payLogo,
    'pay.paxum.com': paxumLogo,
};
const mainlogo = (
    <img
        style={{ height: "auto", width: hostname === "pay.paxum.com" ? "80px" : "30px" }}
        src={logos[hostname] || bm2payLogo}
        className="ml-2"
        alt="Gateway Image"
    />
);

function CheckoutPage() {
    const { client_id, checkout_id, unique_payment_link_id , internal_tx_id } = useParams()
    const [client_secret,setClientSecret] = useState('');
    const [brandColor, setBrandColor] = useState('#2020e4');
    const [style, setStyle] = useState("rounded");
    const [selectedFont, setSelectedFont] = useState("'Poppins'");
    const [showForm,setShowForm] = useState(false);
    const [paymentType,setPaymentType] = useState('one-time-payment');
    const [imagePreview, setImagePreview] = useState(sampleImage);
    const [businessName,setBusinessName] = useState("");
    const [paymentData, setPaymentData] = useState<PaymentData>({
        first_name: "",
        middle_name: "",
        last_name: "",
        name: "",
        email: "",
        phone: "",
        cardHolderName : '',
        cardNumber: '',
        expiry : '',
        cvv : '',
        address : '',
        state : '',
        city : '',
        country : 'CA',
        postalCode : ''
    });
    const [pixPaymentData, setPixPaymentData] = useState<PixPaymentData>({
        first_name: "",
        last_name: "",
        identification_number: "",
        identification_type: ""
    });
    const [cryptoPaymentData, setCryptoPaymentData] = useState<CryptoPaymentData>({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country : 'CA',
        crypto_currency : "",
        blockchain_network : ""
    });
    const [timeLeft, setTimeLeft] = useState(0); 
    const [isExpired, setIsExpired] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState<string>("USD");
    const [amount, setAmount] = useState("");
    const [amountNotSet, setAmountNotSet] = useState(false);
    const [targetDateTime, setTargetDateTime] = useState(null);
    const [expiresAt, setExpiresAt] = useState(null);
    const [showPreview,setShowPreview] = useState(true);
    const [successPreview,setSuccessPreview] = useState(false);
    const [pendingPreview,setPendingPreview] = useState(false);
    const [failedPreview,setFailedPreview] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [isUsed, setIsUsed] = useState(false);    
    const [checkoutPaymentMethod,setCheckoutPaymentMethod] = useState(null);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef(null);
    const [paymentError,setPaymentError] = useState("");
    const [oneErrors, setOneErrors] = useState<Record<string, string>>({});
    const [pixErrors, setPixErrors] = useState<Record<string, string>>({});
    const [cryptoErrors, setCryptoErrors] = useState<Record<string, string>>({});
    const [ipAddress,setIpAddress] = useState("0.0.0.0");
    const [paymentCompeleted,setPaymentCompleted] = useState(false);
    const [amountError,setAmountError] = useState("");
    const [isMultiOption,setIsMultiOption] = useState(true);
    const [initialLoading,setInitialLoading] = useState(true);
    const [payinNotEnabled,setPayinNotEnabled] = useState(false);
    const [return_url,setReturnUrl] = useState("");
    const [callback_url,setCallbackUrl] = useState("");
    const [addressPreview,setAddressPreview] = useState(false);
    const [blockChainNetworks,setBlockChainNetworks] = useState([]);
    const [selectedCryptoCurrency,setSelectedCryptoCurrency] = useState("")
    const [cryptoAddress,setCryptoAddress] = useState("1FfmbHfnpaZjKFvyi1okTjJJusN455paPH")
    const [cryptoAmount,setCryptoAmount] = useState("0.00154568")
    const [copiedAddress, setCopiedAddress] = useState(false);
    const [copiedAmount, setCopiedAmount] = useState(false);
    const [currentFiatAmount, setCurrentFiatAmount] = useState("123456");
    const [qrCode, setQrCode] = useState("");

    const handleCopyAddress = () => {
        navigator.clipboard.writeText(cryptoAddress);
        setCopiedAddress(true);

        setTimeout(() => {
            setCopiedAddress(false);
        }, 2000);
    };

    const handleCopyAmount = () => {
        navigator.clipboard.writeText(cryptoAmount);
        setCopiedAmount(true);

        setTimeout(() => {
            setCopiedAmount(false);
        }, 2000);
    };

    const handleEdit = () => {
        setIsEditing(true);
        setTimeout(() => inputRef.current?.focus(), 0);
    };

    const validateOneTimeFields = () => {
        const newErrors: Record<string, string> = {};
        if (!paymentData.first_name.trim()) newErrors.first_name = 'Field is required';
        if (!paymentData.middle_name.trim()) newErrors.middle_name = 'Field is required';
        if (!paymentData.last_name.trim()) newErrors.last_name = 'Field is required';
        if (!paymentData.email.trim()) newErrors.email = 'Field is required';
        // if (!/^\S+@\S+\.\S+$/.test(paymentData.email)) newErrors.email = 'Enter a valid email';
        if (!paymentData.phone.trim()) newErrors.phone = 'Field is required';
        if (!paymentData.cardHolderName.trim()) newErrors.cardHolderName = 'Field is required';
        if (!paymentData.cardNumber.trim()) newErrors.cardNumber = 'Field is required';
        // if (!/^\d{16}$/.test(paymentData.cardNumber)) newErrors.cardNumber = 'Enter a valid card number';
        if (!paymentData.expiry.trim()) newErrors.expiry = 'Field is required';
        if (!paymentData.cvv.trim()) newErrors.cvv = 'Field is required';
        // if (!/^\d{3}$/.test(paymentData.cvv)) newErrors.cvv = 'Enter a valid CVV';
        if (!paymentData.address.trim()) newErrors.address = 'Field is required';
        if (!paymentData.state.trim()) newErrors.state = 'Field is required';
        if (!paymentData.city.trim()) newErrors.city = 'Field is required';
        if (!paymentData.country.trim()) newErrors.country = 'Field is required';
        if (!paymentData.postalCode.trim()) newErrors.postalCode = 'Field is required';
       
        if(amountNotSet && amount === null) {
            setAmountError("Field is required");
            newErrors.amount = "Field is required"
        } else {
            delete newErrors.amount; 
        }
    
        setOneErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleAmountSet = (field : any, value :any) => {
        setAmount(value);
        setIsEditing(true);
        if(value == "") {
            setAmountError("Field is required");
        } else {
            setAmountError("");
        }
    }

    const validatePixFields = () => {
        const newErrors: Record<string, string> = {};
        if (!pixPaymentData.first_name.trim()) newErrors.first_name = 'Field is required';
        if (!pixPaymentData.last_name.trim()) newErrors.last_name = 'Field is required';
        if (!pixPaymentData.identification_number.trim()) newErrors.identification_number = 'Field is required';
        if (!pixPaymentData.identification_type.trim()) newErrors.identification_type = 'Field is required';
        if(amountNotSet && amount === null) {
            setAmountError("Field is required");
            newErrors.amount = "Field is required"
        } else {
            delete newErrors.amount; 
        }
        setPixErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateCryptoFields = () => {
        const newErrors: Record<string, string> = {};
        if (!cryptoPaymentData.first_name.trim()) newErrors.first_name = 'Field is required';
        if (!cryptoPaymentData.last_name.trim()) newErrors.last_name = 'Field is required';
        if (!cryptoPaymentData.email.trim()) newErrors.email = 'Field is required';
        if (!cryptoPaymentData.phone.trim()) newErrors.phone = 'Field is required';
        if (!cryptoPaymentData.crypto_currency.trim()) newErrors.crypto_currency = 'Field is required';
        if (!cryptoPaymentData.blockchain_network.trim()) newErrors.blockchain_network = 'Field is required';
        if(amountNotSet && amount === null) {
            setAmountError("Field is required");
            newErrors.amount = "Field is required"
        } else {
            delete newErrors.amount; 
        }
        setCryptoErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleOneFieldChange = (field : any, value :any) => {
        setPaymentData({ ...paymentData, [field]: value });
        if(value != "") {
            setOneErrors({ ...oneErrors, [field]: '' });
        } else {
            setOneErrors({ ...oneErrors, [field]: 'Field is required' });
        }
    };

    const handlePixFieldChange = (field : any, value :any) => {
        setPixPaymentData({ ...pixPaymentData, [field]: value });
        if(value != "") {
            setPixErrors({ ...pixErrors, [field]: '' });
        } else {
            setPixErrors({ ...pixErrors, [field]: 'Field is required' });
        }
    };

    const handleCryptoFieldChange = (field : any, value :any) => {
        setCryptoPaymentData({ ...cryptoPaymentData, [field]: value });
        if(value != "") {
            setCryptoErrors({ ...cryptoErrors, [field]: '' });
        } else {
            setCryptoErrors({ ...cryptoErrors, [field]: 'Field is required' });
        }
    };

    useEffect(() => {
        if (targetDateTime) {
            const targetDate: any = new Date(targetDateTime); 
            
    
            let intervalId: NodeJS.Timeout; 
            
            const updateTimeLeft = () => {
                
                const currentTime: any = new Date();
                
                const difference = targetDate - currentTime;
    
                if (difference <= 0) {
                    setIsExpired(true);
                    setTimeLeft(0);
                    clearInterval(intervalId); 
                    // generateNewLink();
                    if(!paymentCompeleted) {
                        onOpen();
                    }
                } else {
                    setTimeLeft(difference);
                }
            };
    
            updateTimeLeft(); // Initial call
            intervalId = setInterval(updateTimeLeft, 1000); 
    
            return () => clearInterval(intervalId); 
        }
    }, [targetDateTime]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (client_id) {
                   
                    // const clientResponse = await getClient(client_id);
                   
                    const client_secret = '';
                    setClientSecret(client_secret);
                    const checkLinkResponse = await checkLink(client_id, checkout_id, unique_payment_link_id, client_secret);
                    setPaymentData((prevData) => ({ ...prevData, name: checkLinkResponse?.data?.client_name }));                   
                    const isUsed = checkLinkResponse?.data?.is_used;
                    setIsUsed(isUsed);
                    if (!isUsed) {
                        const checkoutResponse = await getCheckOutDetailsCheckoutPage(checkout_id, client_id, client_secret);
                        const responseData = checkoutResponse?.data;
                        setBrandColor(responseData?.brand_color);
                        setStyle(responseData?.brand_style);
                        setSelectedFont(responseData?.brand_font);
                        setSelectedCurrency(checkLinkResponse?.data?.currency);
                        setAmount(checkLinkResponse?.data?.amount);

                        if (responseData?.business_name) {
                            setBusinessName(responseData.business_name);
                        }
                        if (responseData?.business_logo) {
                            setImagePreview("data:image/jpeg;base64,"+responseData.business_logo);
                        }

                        if (checkLinkResponse?.data?.amount == null) {
                            setAmountNotSet(true);
                        }

                        if(checkLinkResponse?.data?.return_url != "" || checkLinkResponse?.data?.return_url != "") {
                            setReturnUrl(checkLinkResponse.data.return_url);
                        }

                        if(checkLinkResponse?.data?.callback_url != "" || checkLinkResponse?.data?.callback_url != "") {
                            setCallbackUrl(checkLinkResponse.data.callback_url);
                        }

                        const targetDateFromAPI = new Date(checkLinkResponse?.data?.expires_at);
                        setTargetDateTime(targetDateFromAPI);
                        setExpiresAt(checkLinkResponse?.data?.expires_at);
                        setCheckoutPaymentMethod(checkLinkResponse?.data?.payment_type);
                        if (!localStorage.getItem(unique_payment_link_id)) {
                            localStorage.setItem(unique_payment_link_id, 'no');
                        } else if (localStorage.getItem(unique_payment_link_id) === 'yes') {
                        } else {
                        localStorage.setItem(unique_payment_link_id, 'no');
                        }

                        const paymentTypes = checkLinkResponse?.data?.payment_type || [];

                        if (paymentTypes.includes("ALL")) {
                            setPaymentType("one-time-payment");

                            if(checkLinkResponse?.data?.is_payin_enabled) {
                                const payin_data = checkLinkResponse?.data?.is_payin_enabled;

                                if(payin_data?.is_Active) {
                                    setIsMultiOption(true);
                                } else {
                                    setShowForm(true);
                                    setIsMultiOption(false);
                                }
                            }

                        } else if (paymentTypes.length === 1 && paymentTypes.includes("PIX")) {

                            if(checkLinkResponse?.data?.is_payin_enabled) {
                                const payin_data = checkLinkResponse?.data?.is_payin_enabled;
                                if(payin_data?.is_Active) {
                                    setPaymentType("pix-payment");
                                    setShowForm(true);
                                    setIsMultiOption(false);
                                    setPayinNotEnabled(false);
                                } else {
                                    setShowPreview(false)
                                    setPayinNotEnabled(true);
                                }
                            }
                        } else if (paymentTypes.includes("PIX") && paymentTypes.includes("ONE_TIME") && paymentTypes.includes("CRYPTO")) {
                            if(checkLinkResponse?.data?.is_payin_enabled) {
                                const payin_data = checkLinkResponse?.data?.is_payin_enabled;
                                if(payin_data?.is_Active) {
                                    setIsMultiOption(true);
                                    // setPaymentType(paymentTypes[0] === "PIX" ? "pix-payment" : "one-time-payment");
                                    setPaymentType("one-time-payment");
                                } else {
                                    setPaymentType("one-time-payment");
                                    setShowForm(true);
                                    setIsMultiOption(false);
                                }
                            }
                        } else if (paymentTypes.includes("PIX") && paymentTypes.includes("ONE_TIME")) {
                            if(checkLinkResponse?.data?.is_payin_enabled) {
                                const payin_data = checkLinkResponse?.data?.is_payin_enabled;
                                if(payin_data?.is_Active) {
                                    setIsMultiOption(true);
                                    setPaymentType(paymentTypes[0] === "PIX" ? "pix-payment" : "one-time-payment");
                                    // setPaymentType("one-time-payment");
                                } else {
                                    setPaymentType("one-time-payment");
                                    setShowForm(true);
                                    setIsMultiOption(false);
                                }
                            }
                        } else if (paymentTypes.includes("CRYPTO") && paymentTypes.includes("ONE_TIME")) {
                            if(checkLinkResponse?.data?.is_payin_enabled) {
                                const payin_data = checkLinkResponse?.data?.is_payin_enabled;
                                if(payin_data?.is_Active) {
                                    setIsMultiOption(true);
                                    setPaymentType(paymentTypes[0] === "CRYPTO" ? "crypto" : "one-time-payment");
                                    // setPaymentType("one-time-payment");
                                } else {
                                    setPaymentType("one-time-payment");
                                    setShowForm(true);
                                    setIsMultiOption(false);
                                }
                            }
                        } else if (paymentTypes.includes("CRYPTO") && paymentTypes.includes("PIX")) {
                            if(checkLinkResponse?.data?.is_payin_enabled) {
                                const payin_data = checkLinkResponse?.data?.is_payin_enabled;
                                if(payin_data?.is_Active) {
                                    setIsMultiOption(true);
                                    setPaymentType(paymentTypes[0] === "CRYPTO" ? "crypto" : "pix-payment");
                                    setPayinNotEnabled(true);
                                    // setPaymentType("one-time-payment");
                                } else {
                                    setShowPreview(false)
                                    setPayinNotEnabled(true);
                                }
                            }
                        } else if (paymentTypes.length === 1 && paymentTypes.includes("ONE_TIME")) {
                            setPaymentType("one-time-payment");
                            setShowForm(true);
                            setIsMultiOption(false);
                        } else if (paymentTypes.length === 1 && paymentTypes.includes("CRYPTO")) {

                            const payin_data = checkLinkResponse?.data?.is_payin_enabled;
                            if(payin_data?.is_Active) {
                                setPaymentType("crypto");
                                setShowForm(true);
                                setIsMultiOption(false);
                                setPayinNotEnabled(false);
                            } else {
                                setShowPreview(false)
                                setPayinNotEnabled(true);
                            }
                            
                        }

                        setInitialLoading(false)
                    } else {
                        setInitialLoading(false)
                    }
                } 
                
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const fetchIP = async () => {
            try {
                const response = await fetch('https://geolocation-db.com/json/');
                const data = await response.json();
                setIpAddress(data.IPv4);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };
    
        fetchData();
        fetchIP();
    }, [client_id]);
    
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    const getBorderRadiusClass = () => {
        if (style === "sharp") {
          return "rounded-none";
        } else if (style === "pill") {
          return "rounded-full";
        } else {
          return "rounded";
        }
    };

    const getBorderRadiusCardClass = () => {
        if (style === "sharp") {
          return "rounded-none";
        } else if (style === "pill") {
          return "rounded-[25px]";
        } else {
          return "rounded";
        }
    };

    const handleCardNumberChange = (e : any) => {
        let input = e.target.value.replace(/\D/g, "");
        input = input.slice(0, 16);
        const formattedInput = input.replace(/(.{4})/g, "$1 ").trim();
        setPaymentData({ ...paymentData, cardNumber: formattedInput })
        if(formattedInput != "") {
            setOneErrors({ ...oneErrors, ['cardNumber']: '' });
        } else {
            setOneErrors({ ...oneErrors, ['cardNumber']: 'Field is required' });
        }
    };
    
    const handleExpiryDateChange = (e : any) => {
        let input = e.target.value.replace(/\D/g, "");
        input = input.slice(0, 4);
        if (input.length > 2) {
          input = `${input.slice(0, 2)} / ${input.slice(2)}`;
        }
        setPaymentData({ ...paymentData, expiry: input })
        if(input != "") {
            setOneErrors({ ...oneErrors, ['expiry']: '' });
        } else {
            setOneErrors({ ...oneErrors, ['expiry']: 'Field is required' });
        }
    };
    
    const handleCvvChange = (e : any) => {
        let input = e.target.value.replace(/\D/g, "");
        input = input.slice(0, 4);
        setPaymentData({ ...paymentData, cvv: input })
        if(input != "") {
            setOneErrors({ ...oneErrors, ['cvv']: '' });
        } else {
            setOneErrors({ ...oneErrors, ['cvv']: 'Field is required' });
        }
    };

    const handleCountryChange = (e : any) => {
        setPaymentData({ ...paymentData, country: e.target.value })
        if(e.target.value != "") {
            setOneErrors({ ...oneErrors, ['country']: '' });
        } else {
            setOneErrors({ ...oneErrors, ['country']: 'Field is required' });
        }
    };

    const handleCryptoCurrencyChange = (e : any) => {
        const networks = allBlockChainNetwork(e.target.value);
        setBlockChainNetworks(networks);
        setCryptoPaymentData({ ...cryptoPaymentData, crypto_currency: e.target.value , blockchain_network : "" })
        if(e.target.value != "") {
            setCryptoErrors({ ...cryptoErrors, ['crypto_currency']: '' });
        } else {
            setCryptoErrors({ ...cryptoErrors, ['crypto_currency']: 'Field is required' });
            setBlockChainNetworks([]);
        }
        setSelectedCryptoCurrency(e.target.options[e.target.selectedIndex].text)
    };

    const handleBlockChainChange = (e : any) => {
        setCryptoPaymentData({ ...cryptoPaymentData, blockchain_network : e.target.value })
        if(e.target.value != "") {
            setCryptoErrors({ ...cryptoErrors, ['blockchain_network']: '' });
        } else {
            setCryptoErrors({ ...cryptoErrors, ['blockchain_network']: 'Field is required' });
        }
    };

    const handleCryptoCountryChange = (e : any) => {
        setCryptoPaymentData({ ...cryptoPaymentData, country: e.target.value })
        if(e.target.value != "") {
            setCryptoErrors({ ...cryptoErrors, ['country']: '' });
        } else {
            setCryptoErrors({ ...cryptoErrors, ['country']: 'Field is required' });
        }
    };

    const handleBackButton = () => {
        setAddressPreview(false);
        setIsLoading(false);
    }

    const getQRValue = () => {
        switch (cryptoPaymentData.crypto_currency) {
            case "Bitcoin":
            case "BTC":
                return `bitcoin:${cryptoAddress}?amount=${cryptoAmount}`;
            case "Ethereum":
            case "ETH":
                return `ethereum:${cryptoAddress}?value=${cryptoAmount}`;
            case "USDT":
                return `ethereum:${cryptoAddress}?contract=1234567890&value=${cryptoAmount}`;
            default:
                return cryptoAddress;
        }
    };

    const handlePayNow   = () => {
        setIsLoading(true);
        
        if(paymentType == 'one-time-payment') {
            if (!validateOneTimeFields()) {
                setIsLoading(false);
                return;
            }
            const [month, year] = paymentData.expiry.split(" / ").map((part) => part.trim());
            const payload = {
                "payment_type": 'ONE_TIME',
                "checkout_details": {
                    "id": checkout_id,
                    "expires_at": expiresAt,
                    "client_id": client_id
                },
                "payment_request_data": {
                    "internal_tx_id" : internal_tx_id,
                    "first_name": paymentData.first_name,
                    "middle_name": paymentData.middle_name,
                    "last_name": paymentData.last_name,
                    "email": paymentData.email,
                    "phone": paymentData.phone,
                    "city": paymentData.city,
                    "address": paymentData.address,
                    "postal_code": paymentData.postalCode,
                    "state": paymentData.state,
                    "country": paymentData.country,
                    "ip": ipAddress,
                    "cardholder_name": paymentData.cardHolderName,
                    "card_number": paymentData.cardNumber.replace(/\s+/g, ''),
                    "expiration_month": month,
                    "expiration_year": year,
                    "cvv": paymentData.cvv,
                    "amount": parseFloat(amount).toFixed(2),
                    "currency": selectedCurrency,
                    ...(return_url && return_url.trim() !== "" && { return_url }),
                    ...(callback_url && callback_url.trim() !== "" && { callback_url })
                }
            }
            createPayment(payload,client_id,checkout_id,unique_payment_link_id,client_secret)
            .then((response) => {
                setIsProcess(false);
                if(response.status == false) {
                    setIsLoading(false);
                    setPaymentError( "Something went wrong please contact admin");
                    setShowPreview(false);
                    setFailedPreview(true);
                    setSuccessPreview(false);
                    setPendingPreview(false);
                } else {
                    const { initiated, message } = response.data.payment_response;

                    const isObject = (value : any) => value && typeof value === 'object' && !Array.isArray(value);

                    let isError;
                    if (initiated) {
                        isError = !isObject(message);
                    } else {
                        isError = !isObject(message);
                    }
                    if(isError) {
                        setIsLoading(false);
                        setPaymentError( "Something went wrong please contact admin");
                        setShowPreview(false);
                        setFailedPreview(true);
                        setSuccessPreview(false);
                        setPendingPreview(false);
                    } else {
                        setIsLoading(false);
                        setTargetDateTime(null);
                        const paymentData = response?.data?.payment_response
                        const status = paymentData?.message?.status;
                        if(status == 'DECLINED' || status == 'CANCELLED' || status == 'REJECTED' || status == 'ERRORED') {
                            if(return_url == null || return_url == "") {
                                setShowPreview(false);
                                setFailedPreview(true);
                                setSuccessPreview(false);
                                setPendingPreview(false);
                            } else {
                                window.location.href = return_url;
                            }
                        } else if(status == 'APPROVED') {
                            if(return_url == null || return_url == "") {
                                setShowPreview(false);
                                setFailedPreview(false);
                                setSuccessPreview(true);
                                setPendingPreview(false);
                            } else {
                                window.location.href = return_url;
                            }
                        } else if(status == 'PENDING') {
                            setShowPreview(false);
                            setFailedPreview(false);
                            setSuccessPreview(false);
                            setPendingPreview(true);

                            setTimeout(() => {
                                const redirectUri = response?.data?.redirect_uri;
                                if (redirectUri) {
                                    // window.open(redirectUri, '_blank');
                                    window.location.href = redirectUri;
                                }
                            }, 2000);
                            
                        }

                    }
                }
            })
            .catch((error) => {
                setPaymentError( "Something went wrong please contact admin");
                setShowPreview(false);
                setFailedPreview(true);
                setSuccessPreview(false);
                setPendingPreview(false);
            });

        } else if(paymentType == 'pix-payment') {
            if (!validatePixFields()) {
                setIsLoading(false);
                return;
            }
            const payload = {
                "payment_type": 'PIX',
                "internal_tx_id" : internal_tx_id,
                "checkout_details": {
                    "id": checkout_id,
                    "expires_at": expiresAt,
                    "client_id": client_id
                },
                "payment_request_data": {
                    "sender_details" : {
                        "first_name": pixPaymentData.first_name,
                        "last_name": pixPaymentData.last_name,
                        "identification_number": pixPaymentData.identification_number,
                        "identification_type": pixPaymentData.identification_type,
                    },
                    "payin_information" : {
                        "amount": parseFloat(amount).toFixed(2),
                        "currency": selectedCurrency,
                        ...(return_url && return_url.trim() !== "" && { return_url }),
                        ...(callback_url && callback_url.trim() !== "" && { callback_url })
                    }
                }
            }
            createPayment(payload,client_id,checkout_id,unique_payment_link_id,client_secret)
            .then((response) => {
                setIsProcess(false);
                
                if(response.status == false) {
                    setIsLoading(false);
                    setPaymentError( "Something went wrong please contact admin");
                    setShowPreview(false);
                    setFailedPreview(true);
                    setSuccessPreview(false);
                    setPendingPreview(false);
                } else {
                    const { initiated, message } = response.data.payment_response;

                    const isObject = (value : any) => value && typeof value === 'object' && !Array.isArray(value);

                    let isError;
                    if (initiated) {
                        isError = !isObject(message);
                    } else {
                        isError = !isObject(message);
                    }
                    console.log("isError",isError);
                    if(isError) {
                        setIsLoading(false);
                        setPaymentError( "Something went wrong please contact admin");
                        setShowPreview(false);
                        setFailedPreview(true);
                        setSuccessPreview(false);
                        setPendingPreview(false);
                    } else {
                        setIsLoading(false);
                        setTargetDateTime(null);
                        const paymentData = response?.data?.payment_response
                        const status = paymentData?.message?.status;

                        if(status == 'DECLINED' || status == 'CANCELLED' || status == 'REJECTED' || status == 'ERRORED') {
                            if(return_url == null || return_url == "") {
                                setShowPreview(false);
                                setFailedPreview(true);
                                setSuccessPreview(false);
                                setPendingPreview(false);
                            } else {
                                window.location.href = return_url;
                            }
                        } else if(status == 'APPROVED') {
                            if(return_url == null || return_url == "") {
                                setShowPreview(false);
                                setFailedPreview(false);
                                setSuccessPreview(true);
                                setPendingPreview(false);
                            } else {
                                window.location.href = return_url;
                            }
                        } else if(status == 'PENDING') {
                            setShowPreview(false);
                            setFailedPreview(false);
                            setSuccessPreview(false);
                            setPendingPreview(true);

                            // setTimeout(() => {
                            //     const redirectUri = paymentData?.message?.description?.redirect_uri;
                            //     if (redirectUri) {
                            //         window.open(redirectUri, '_blank');
                            //     }
                            // }, 2000);
                        }

                    }
                }
            })
            .catch((error) => {
                setPaymentError( "Something went wrong please contact admin");
                setShowPreview(false);
                setFailedPreview(true);
                setSuccessPreview(false);
                setPendingPreview(false);
            });
        } else if(paymentType == 'crypto') {
            if (!validateCryptoFields()) {
                setIsLoading(false);
                return;
            }

            const qrValue = getQRValue() 
            setQrCode(qrValue)
            setIsLoading(false);
            setAddressPreview(true);
        }
        
    } 

    const handleNext = () => {
        setShowForm(true);
    }

    const handleGoBack = () => {
        setShowForm(false);
    }

    const handleClose = () => {
        onClose();
      };

    const generateNewLink = () => {
        setIsLoading(true);
        const payload = {
            "client_id" : client_id,
            "checkout_id": checkout_id,
            "unique_payment_link_id" : unique_payment_link_id
        }
        generateCheckoutLink(payload,client_id,client_secret)
        .then((response) => {
            // let updatedUrl = '';

            // if (window.location.hostname === "gateway-dev.lightningchecks.com") {
            //     const newBaseUrl = "https://gateway-dev.lightningchecks.com/";
            //     updatedUrl  = response?.data?.payment_link.replace(
            //     "https://backend-dev.bm2pay.com/api/v1/",
            //     newBaseUrl
            //     );
            // } else if (window.location.hostname === "gateway.lightningchecks.com") {
            //     const newBaseUrl = "https://gateway.lightningchecks.com/";
            //     updatedUrl  = response?.data?.payment_link.replace(
            //     "https://backend.lightningchecks.com/api/v1/",
            //     newBaseUrl
            //     );
            // } else if (window.location.hostname === "gateway-dev.bm2pay.com") {
            //     const newBaseUrl = "https://gateway-dev.bm2pay.com/";
            //     updatedUrl  = response?.data?.payment_link.replace(
            //     "https://backend-dev.bm2pay.com/api/v1/",
            //     newBaseUrl
            //     );
            // } else if (window.location.hostname === "gateway.bm2pay.com") {
            //     const newBaseUrl = "https://gateway.bm2pay.com/";
            //     updatedUrl  = response?.data?.payment_link.replace(
            //     "https://backend.bm2pay.com/api/v1/",
            //     newBaseUrl
            //     );
            // } else if (window.location.hostname === "pay.paxum.com") {
            //     const newBaseUrl = "https://pay.paxum.com/";
            //     updatedUrl  = response?.data?.payment_link.replace(
            //     "https://backend.paxum.com/api/v1/",
            //     newBaseUrl
            //     );
            // } else {
            //     // const newBaseUrl = "https://gateway-dev.bm2pay.com/";
            //     const newBaseUrl = "http://localhost:3000/";
            //     updatedUrl  = response?.data?.payment_link.replace(
            //     "https://backend-dev.bm2pay.com/api/v1/",
            //     newBaseUrl
            //     );
            // }

            // if (updatedUrl) {
                handleClose();
                setIsLoading(false);
                toast.success(response?.message)
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
               localStorage.setItem(unique_payment_link_id,'yes');
            // }
        })
        .catch((error) => {
            console.error("Error generating new link:", error);
        });
    }

    return (
        <>
        {initialLoading ? (
            <div className="mt-5 pt-5">
                <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
                <p className="text-center">Loading, please wait...</p>
            </div>
        ) : (
            <>
                {!isUsed ? (
                    !showForm ? (
                    <div className="flex flex-col lg:flex-row  mx-auto h-[100vh]">
                        {showPreview ? (
                            <>
                                <div className="h-full md:w-2/6 lg:w-2/6 sm:w-full border-r-2 p-5" style={{ backgroundColor: brandColor , color : '#FFFFFF' }}>
                                    <div className="flex flex-col h-full justify-center items-center gap-6">
                                        <img
                                        style={{
                                            height: "auto",
                                            width: hostname === "pay.paxum.com" ? "80px" : "150px",
                                            filter: "drop-shadow(0 4px 6px rgba(0,0,0,0.15))",
                                        }}
                                        src={logos[hostname] || bm2payLogo}
                                        alt="Gateway Image"
                                        />
                                        <div className="text-center">
                                        <h3 className="text-lg font-semibold text-white" style={{ fontFamily: selectedFont }}>Fast. Secure. Reliable.</h3>
                                        <p className="text-lg text-indigo-200 italic" style={{ fontFamily: selectedFont }}>Your trusted gateway for seamless payments.</p>
                                        </div>
                                        <div className="text-center">
                                            <h3 className="text-lg font-semibold text-white" style={{ fontFamily: selectedFont }}>
                                                {isExpired ? (
                                                <div className="flex items-center gap-x-2">
                                                    {/* <span>Generating new link.....</span> */}
                                                    {/* <button
                                                        className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200  active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                                                        onClick={generateNewLink}
                                                    > 
                                                        Generate New Link
                                                    </button> */}
                                                </div>
                                                ) : (
                                                `Expires In : ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
                                                )}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div  className="h-full md:w-4/6 lg:w-4/6 sm:w-full px-14 py-10">
                                    <div className="flex flex-col h-full">
                                        <h3 
                                            style={{ fontFamily: selectedFont }} 
                                            className="mb-6 text-2xl font-bold text-gray-800 dark:text-gray-100"
                                        >
                                            Choose Payment Type
                                        </h3>
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                                            {checkoutPaymentMethod == null || checkoutPaymentMethod.includes('ALL') ? (
                                                <>
                                                    <div
                                                    className={`group cursor-pointer flex flex-col items-center gap-4 p-6 rounded-2xl shadow-lg border transition-transform transform hover:scale-105 ${
                                                        paymentType === 'one-time-payment'
                                                        ? 'border-blue-600 bg-gradient-to-br from-blue-100 to-blue-50'
                                                        : 'border-gray-200 hover:border-gray-400'
                                                    }`}
                                                    onClick={() => setPaymentType('one-time-payment')}
                                                    >
                                                    <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 dark:text-gray-200" style={{ fontFamily: selectedFont }} >
                                                        One Time Payment
                                                    </h3>
                                                    <p className="text-sm text-gray-500 dark:text-gray-400 text-center" style={{ fontFamily: selectedFont }} >
                                                        A single payment option for a one-time transaction.
                                                    </p>
                                                    </div>
                                                    <div
                                                    className={`group cursor-pointer flex flex-col items-center gap-4 p-6 rounded-2xl shadow-lg border transition-transform transform hover:scale-105 ${
                                                        paymentType === 'pix-payment'
                                                        ? 'border-blue-600 bg-gradient-to-br from-blue-100 to-blue-50'
                                                        : 'border-gray-200 hover:border-gray-400'
                                                    }`}
                                                    onClick={() => setPaymentType('pix-payment')}
                                                    >
                                                    <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 dark:text-gray-200" style={{ fontFamily: selectedFont }} >
                                                        PIX Payment
                                                    </h3>
                                                    <p className="text-sm text-gray-500 dark:text-gray-400 text-center" style={{ fontFamily: selectedFont }} >
                                                        Use PIX for secure and instant payment.
                                                    </p>
                                                    </div>
                                                    <div
                                                    className={`group cursor-pointer flex flex-col items-center gap-4 p-6 rounded-2xl shadow-lg border transition-transform transform hover:scale-105 ${
                                                        paymentType === 'crypto'
                                                        ? 'border-blue-600 bg-gradient-to-br from-blue-100 to-blue-50'
                                                        : 'border-gray-200 hover:border-gray-400'
                                                    }`}
                                                    onClick={() => setPaymentType('crypto')}
                                                    >
                                                    <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 dark:text-gray-200" style={{ fontFamily: selectedFont }} >
                                                        Crypto Payment
                                                    </h3>
                                                    <p className="text-sm text-gray-500 dark:text-gray-400 text-center" style={{ fontFamily: selectedFont }} >
                                                        Use Crypto for secure and instant payment.
                                                    </p>
                                                    </div>
                                                </>
                                            ) : (
                                                checkoutPaymentMethod.map((method: any, index: number) => {
                                                    if (method === 'ONE_TIME') {
                                                        return (
                                                            <div
                                                            key={index}
                                                            className={`group cursor-pointer flex flex-col items-center gap-4 p-6 rounded-2xl shadow-lg border transition-transform transform hover:scale-105 ${
                                                                paymentType === 'one-time-payment'
                                                                ? 'border-blue-600 bg-gradient-to-br from-blue-100 to-blue-50'
                                                                : 'border-gray-200 hover:border-gray-400'
                                                            }`}
                                                            onClick={() => setPaymentType('one-time-payment')}
                                                            >
                                                                <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 dark:text-gray-200" style={{ fontFamily: selectedFont }} >
                                                                    One Time Payment
                                                                </h3>
                                                                <p className="text-sm text-gray-500 dark:text-gray-400 text-center" style={{ fontFamily: selectedFont }} >
                                                                    A single payment option for a one-time transaction.
                                                                </p>
                                                            </div>  
                                                        )
                                                    }

                                                    if (method === 'PIX') {
                                                        return (
                                                            <div
                                                            key={index}
                                                            className={`group cursor-pointer flex flex-col items-center gap-4 p-6 rounded-2xl shadow-lg border transition-transform transform hover:scale-105 ${
                                                                paymentType === 'pix-payment'
                                                                ? 'border-blue-600 bg-gradient-to-br from-blue-100 to-blue-50'
                                                                : 'border-gray-200 hover:border-gray-400'
                                                            }`}
                                                            onClick={() => setPaymentType('pix-payment')}
                                                            >
                                                                <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 dark:text-gray-200" style={{ fontFamily: selectedFont }} >
                                                                    PIX Payment
                                                                </h3>
                                                                <p className="text-sm text-gray-500 dark:text-gray-400 text-center" style={{ fontFamily: selectedFont }} >
                                                                    Use PIX for secure and instant payment.
                                                                </p>
                                                            </div>
                                                        )
                                                    }

                                                    if (method === 'CRYPTO') {
                                                        return (
                                                            <div
                                                            key={index}
                                                            className={`group cursor-pointer flex flex-col items-center gap-4 p-6 rounded-2xl shadow-lg border transition-transform transform hover:scale-105 ${
                                                                paymentType === 'crypto'
                                                                ? 'border-blue-600 bg-gradient-to-br from-blue-100 to-blue-50'
                                                                : 'border-gray-200 hover:border-gray-400'
                                                            }`}
                                                            onClick={() => setPaymentType('crypto')}
                                                            >
                                                                <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 dark:text-gray-200" style={{ fontFamily: selectedFont }} >
                                                                    Crypto Payment
                                                                </h3>
                                                                <p className="text-sm text-gray-500 dark:text-gray-400 text-center" style={{ fontFamily: selectedFont }} >
                                                                    Use Crypto for secure and instant payment.
                                                                </p>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            )}
                                        </div>
                                        <div className="flex justify-end mt-6">
                                            <button
                                            className={`flex ${getBorderRadiusClass()} items-center gap-3 px-6 py-3 text-white text-sm font-medium shadow-md transition-transform transform hover:scale-105`}
                                            style={{
                                                backgroundColor: brandColor,
                                                boxShadow: `0px 10px 15px -3px ${brandColor}66, 0px 4px 6px -4px ${brandColor}33`,
                                            }}
                                            onClick={handleNext}
                                            disabled={isExpired}
                                            >
                                            <span style={{ fontFamily: selectedFont }} >Next</span>
                                            <BsArrowRight className="w-5 h-5" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : successPreview ? (
                            <div
                                className="flex flex-col items-center justify-center w-full p-5 rounded-lg"
                                style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                            >
                                <AiFillCheckCircle className="w-20 h-20 mb-4" />
                                <span className="text-xl font-semibold mb-4">Payment Success</span>
                            </div>
                        ) : pendingPreview ? (
                            <div
                                className="flex flex-col items-center justify-center w-full p-5 rounded-lg"
                                style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                            >
                                <AiFillExclamationCircle  className="w-20 h-20 mb-4" />
                                <span className="text-xl font-semibold mb-4">Payment Pending</span>
                            </div>
                        ) : failedPreview ? (
                            <div
                                className="flex flex-col items-center justify-center w-full p-5 rounded-lg"
                                style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                            >
                                <AiFillCloseCircle  className="w-20 h-20 mb-4" />
                                {paymentError == "" && (
                                <span className="text-xl font-semibold mb-4">Payment Failed</span>
                                )}
                                {paymentError != "" && (
                                    <span className="text-xl font-semibold mb-4">{paymentError}</span>
                                )}
                            </div>
                        ) : payinNotEnabled ? (
                            <div className="flex flex-col lg:flex-row w-full mx-auto h-[100vh]">
                                <div className="h-full md:w-2/6 lg:w-2/6 sm:w-full border-r-2 p-5" style={{ backgroundColor: brandColor , color : '#FFFFFF' }}>
                                    <div className="flex flex-col h-full justify-center items-center gap-6">
                                        <img
                                        style={{
                                            height: "auto",
                                            width: hostname === "pay.paxum.com" ? "80px" : "150px",
                                            filter: "drop-shadow(0 4px 6px rgba(0,0,0,0.15))",
                                        }}
                                        src={logos[hostname] || bm2payLogo}
                                        alt="Gateway Image"
                                        />
                                        <div className="text-center">
                                        <h3 className="text-lg font-semibold text-white" style={{ fontFamily: selectedFont }}>Fast. Secure. Reliable.</h3>
                                        <p className="text-lg text-indigo-200 italic" style={{ fontFamily: selectedFont }}>Your trusted gateway for seamless payments.</p>
                                        </div>
                                    </div>
                                </div>
                                <div  className="h-full md:w-4/6 lg:w-4/6 sm:w-full px-14 py-10">
                                    <div className="flex flex-col h-full justify-center items-center">
                                        <h3 className="text-xl font-semibold mb-4 relative text-center" style={{ fontFamily: selectedFont }}>
                                            <span className="bg-white px-2 relative z-10" style={{ fontFamily: selectedFont }} >PIX payment is not enabled !</span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    ) : (
                    <div className={`flex flex-col lg:flex-row mx-auto ${paymentType === 'pix-payment' ? 'min-h-screen' : 'min-h-screen'}`}>
                        {showPreview ? (
                            <>
                                <div  className="w-full md:w-2/6 lg:w-2/6 sm:w-full border-r-2 px-16 py-10 "  style={{ backgroundColor: brandColor , color : '#FFFFFF' }}>
                                    <div className="flex items-center justify-between gap-2 w-full">
                                        <div className="flex items-center gap-2">
                                            {isMultiOption && (
                                                <BsArrowLeft onClick={handleGoBack} className="mr-2 cursor-pointer" />
                                            )}
                                            {imagePreview && (
                                            <img
                                                src={imagePreview}
                                                alt="Business Logo Preview"
                                                className="w-10 h-10 rounded-full border border-gray-300"
                                            />
                                            )}
                                            <h3 
                                                className="flex text-[22px] font-bold items-center" 
                                                style={{ fontFamily: selectedFont }}
                                            >
                                                {businessName ? businessName : paymentData?.name}
                                            </h3>
                                        </div>
                                        <h3 className="text-lg font-semibold text-white" style={{ fontFamily: selectedFont }}>
                                            {isExpired ? (
                                            <></>
                                            ) : (
                                                `Expires In : ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
                                            )}
                                        </h3>
                                    </div>
                                    <div className="flex justify-between items-center">
                                    <h2
                                        className="text-[50px] font-medium my-4"
                                        style={{ fontFamily: selectedFont }}
                                    >
                                        
                                        {isEditing ? (
                                            <>
                                            <div className="flex items-center space-x-4">
                                                <span className="text-[30px]">{getCurrencySymbol(selectedCurrency)?.icon || "$"}</span>
                                                <input
                                                    ref={inputRef}
                                                    type="number"
                                                    value={Number(amount).toFixed(2)}
                                                    onChange={(e) => handleAmountSet('amount', e.target.value)}
                                                    className="text-[30px] w-[150px] border-none bg-transparent font-medium px-2 py-1 rounded-lg focus:outline-none"
                                                    style={{ fontFamily: selectedFont , backgroundColor:'transparent' }}
                                                />
                                                
                                            </div>
                                            
                                            </>
                                        ) : (
                                            <>
                                            {!amountNotSet ? (
                                                <span>
                                                {getCurrencySymbol(selectedCurrency)?.icon || "$"} {Number(amount).toFixed(2)}
                                            </span>
                                            ) : (
                                                <span
                                                    className="cursor-pointer"
                                                    onClick={handleEdit}
                                                >
                                                    <span className="text-[30px] text-gray-500">Add Amount</span>
                                                </span>
                                            )}
                                            </>
                                            
                                        )}
                                    </h2>
                                    </div>

                                </div>
                                <div className="w-full sm:w-full md:w-4/6 lg:w-4/6 px-5 py-10 md:px-28 md:py-10 lg:px-28 lg:py-10 ">
                                    <div className="flex flex-col space-y-6">
                                        {paymentType == 'one-time-payment' ? ( 
                                            <>
                                                <h3 className="text-xl font-semibold mb-4 relative text-center" style={{ fontFamily: selectedFont }}>
                                                    <span className="absolute left-0 top-1/2 w-full border-t border-gray-300" style={{ transform: 'translateY(-50%)' }}></span>
                                                    <span className="bg-white px-2 relative z-10" style={{ fontFamily: selectedFont }} >Pay with card</span>
                                                </h3>
                                                <div className="flex gap-4 mb-5">
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            First Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${oneErrors.first_name ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="first name"
                                                            value={paymentData?.first_name}
                                                            onChange={(e) => handleOneFieldChange('first_name', e.target.value)}
                                                        />
                                                        {oneErrors.first_name && <span className="text-red-500 text-sm">{oneErrors.first_name}</span>}
                                                    </div>
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Middle Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${oneErrors.middle_name ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="middle name"
                                                            value={paymentData?.middle_name}
                                                            onChange={(e) => handleOneFieldChange('middle_name', e.target.value)}
                                                        />
                                                        {oneErrors.middle_name && <span className="text-red-500 text-sm">{oneErrors.middle_name}</span>}
                                                    </div>
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Last Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${oneErrors.last_name ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="last name"
                                                            value={paymentData?.last_name}
                                                            onChange={(e) => handleOneFieldChange('last_name', e.target.value)}
                                                        />
                                                        {oneErrors.last_name && <span className="text-red-500 text-sm">{oneErrors.last_name}</span>}
                                                    </div>
                                                </div>
                                                <div className="flex gap-4 mb-5">
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Email
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${oneErrors.email ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="test@gmail.com"
                                                            value={paymentData?.email}
                                                            onChange={(e) => handleOneFieldChange('email', e.target.value)}
                                                        />
                                                        {oneErrors.email && <span className="text-red-500 text-sm">{oneErrors.email}</span>}
                                                    </div>
                                            
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Phone Number
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${oneErrors.phone ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="1234567890"
                                                            value={paymentData?.phone}
                                                            onChange={(e) => handleOneFieldChange('phone', e.target.value)}
                                                        />
                                                        {oneErrors.phone && <span className="text-red-500 text-sm">{oneErrors.phone}</span>}
                                                    </div>
                                                    {amountNotSet && (
                                                        <div className="flex flex-col w-full">
                                                            <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                                Amount
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className={`border p-4 w-full ${amountError ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                                style={{ fontFamily: selectedFont }}
                                                                placeholder="amount"
                                                                value={amount}
                                                                onChange={(e) => handleAmountSet('amount', e.target.value)}
                                                            />
                                                            {amountError && <span className="text-red-500 text-sm">{amountError}</span>}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="flex flex-col">
                                                    <label
                                                    className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200 flex justify-between items-center"
                                                    style={{ fontFamily: selectedFont }}
                                                    >
                                                        <span>Card Information</span>
                                                        {(oneErrors.cardHolderName || oneErrors.cardNumber || oneErrors.expiry || oneErrors.cvv) && (
                                                            <span className="text-red-500 text-xs " style={{ fontFamily: selectedFont }}>
                                                            Fields are required
                                                            </span>
                                                        )}
                                                    </label>
                                                    <div
                                                        className={`border ${getBorderRadiusCardClass()} ${(oneErrors.cardHolderName || oneErrors.cardNumber || oneErrors.expiry || oneErrors.cvv) ? 'border-red-500' : ''} border-gray-300 dark:border-white/10 focus-within:ring-2 focus-within:ring-blue-500 transition-all shadow-sm hover:shadow-lg transition-shadow`}
                                                    >
                                                        <div className=" border-b-[1px]">
                                                        <input
                                                            type="text"
                                                            style={{ fontFamily: selectedFont }}
                                                            className={`w-full p-4 border-transparent ${getBorderRadiusCardClass()} outline-none placeholder-gray-400 dark:placeholder-gray-500`}
                                                            placeholder="Card Holder Name"
                                                            value={paymentData?.cardHolderName}
                                                            onChange={(e) => handleOneFieldChange('cardHolderName', e.target.value)}
                                                        />
                                                        </div>

                                                        <div className=" border-b-[1px]">
                                                        <input
                                                            type="text"
                                                            style={{ fontFamily: selectedFont }}
                                                            className="w-full p-4 border-none outline-none placeholder-gray-400 dark:placeholder-gray-500"
                                                            placeholder="1234 1234 1234 1234"
                                                            value={paymentData?.cardNumber}
                                                            onChange={handleCardNumberChange}
                                                        />
                                                        </div>

                                                        <div className="flex gap-4">
                                                        <input
                                                            type="text"
                                                            style={{ fontFamily: selectedFont }}
                                                            className={`w-1/2 p-4 border-r-[1px] rounded-r-none border-transparent ${getBorderRadiusCardClass()} outline-none placeholder-gray-400 dark:placeholder-gray-500`}
                                                            placeholder="MM / YY"
                                                            value={paymentData?.expiry}
                                                            onChange={handleExpiryDateChange}
                                                        />
                                                        <input
                                                            type="text"
                                                            className={`w-1/2 p-4 border-transparent ${getBorderRadiusCardClass()} outline-none placeholder-gray-400 dark:placeholder-gray-500`}
                                                            placeholder="CVV"
                                                            style={{ fontFamily: selectedFont }}
                                                            value={paymentData?.cvv}
                                                            onChange={handleCvvChange}
                                                        />
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="flex flex-col">
                                                    <label
                                                    className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200 flex justify-between items-center"
                                                    style={{ fontFamily: selectedFont }}
                                                    >
                                                        Address information
                                                        {(oneErrors.address || oneErrors.state || oneErrors.city || oneErrors.country || oneErrors.postalCode) && (
                                                            <span className="text-red-500 text-xs " style={{ fontFamily: selectedFont }}>
                                                            Fields are required
                                                            </span>
                                                        )}
                                                    </label>
                                                    <div
                                                        className={`border ${getBorderRadiusCardClass()} ${(oneErrors.address || oneErrors.state || oneErrors.city || oneErrors.country || oneErrors.postalCode) ? 'border-red-500' : ''} border-gray-300 dark:border-white/10 focus-within:ring-2 focus-within:ring-blue-500 transition-all shadow-sm hover:shadow-lg transition-shadow`}
                                                    >
                                                        <div className=" border-b-[1px]">
                                                            <input
                                                            type="text"
                                                            style={{ fontFamily: selectedFont }}
                                                            className={`border-transparent ${getBorderRadiusCardClass()} p-4 w-full  outline-none placeholder-gray-400 dark:placeholder-gray-500 `}
                                                            placeholder="Address"
                                                            value={paymentData?.address}
                                                            onChange={(e) => handleOneFieldChange('address', e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="flex gap-4 border-b-[1px]">
                                                            <input
                                                            type="text"
                                                            style={{ fontFamily: selectedFont }}
                                                            className={` border-r-[1px] p-4 w-1/2 outline-none placeholder-gray-400 dark:placeholder-gray-500 `}
                                                            placeholder="State"
                                                            value={paymentData?.state}
                                                            onChange={(e) => handleOneFieldChange('state', e.target.value)}
                                                            />
                                                            <input
                                                            type="text"
                                                            style={{ fontFamily: selectedFont }}
                                                            className={`border-none p-4 w-1/2 outline-none placeholder-gray-400 dark:placeholder-gray-500 `}
                                                            placeholder="City"
                                                            value={paymentData?.city}
                                                            onChange={(e) => handleOneFieldChange('city', e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="flex gap-4">
                                                        <select
                                                        className={`border-transparent ${getBorderRadiusCardClass()} rounded-r-none border-r-[1px] p-4 w-1/2 outline-none placeholder-gray-400 dark:placeholder-gray-500`}
                                                        onChange={handleCountryChange}
                                                        style={{ fontFamily: selectedFont }}
                                                        value={paymentData?.country}
                                                        >
                                                        {allCountries.map((country) => (
                                                            <option key={country.code} value={country.code}>
                                                            {country.name}
                                                            </option>
                                                        ))}
                                                        </select>
                                                        <input
                                                        type="text"
                                                        style={{ fontFamily: selectedFont }}
                                                        className={`border-transparent ${getBorderRadiusCardClass()} p-4 w-1/2 outline-none placeholder-gray-400 dark:placeholder-gray-500 `}
                                                        placeholder="Postal Code"
                                                        value={paymentData?.postalCode}
                                                        onChange={(e) => handleOneFieldChange('postalCode', e.target.value)}
                                                        />
                                                    </div>
                                                    </div>

                                                </div>

                                                <div className="flex flex-col mt-6">
                                                    <button
                                                        className={`flex items-center justify-center gap-3 px-6 py-4  text-white font-semibold ${getBorderRadiusClass()} shadow-md transition-transform transform hover:scale-105`}
                                                        style={{
                                                            backgroundColor: brandColor,
                                                            boxShadow: `0px 10px 15px -3px ${brandColor}66, 0px 4px 6px -4px ${brandColor}33`,
                                                        }}
                                                        onClick={handlePayNow}
                                                        disabled={isExpired || isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <DivLoader className="h-6 w-6 border-red-400" />
                                                        ) : (
                                                            <>
                                                            <BsLockFill className="h-5 w-5" />
                                                            <span style={{ fontFamily: selectedFont }} > Pay Now</span>
                                                            </>
                                                        )}
                                                        
                                                    </button>
                                                </div>
                                            </>   
                                        ): paymentType === 'pix-payment' ? (
                                            <>
                                                <h3 className="text-xl font-semibold mb-4 relative text-center" style={{ fontFamily: selectedFont }}>
                                                        <span className="absolute left-0 top-1/2 w-full border-t border-gray-300" style={{ transform: 'translateY(-50%)' }}></span>
                                                        <span className="bg-white px-2 relative z-10" style={{ fontFamily: selectedFont }} >Pay with PIX</span>
                                                </h3>
                                                <div className="flex gap-4 mb-5">
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            First Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${pixErrors.first_name ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="first name"
                                                            value={pixPaymentData?.first_name}
                                                            onChange={(e) => handlePixFieldChange('first_name', e.target.value)}
                                                        />
                                                        {pixErrors.first_name && <span className="text-red-500 text-sm">{pixErrors.first_name}</span>}
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                        Last Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`border p-4 w-full ${pixErrors.last_name ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                        style={{ fontFamily: selectedFont }}
                                                        placeholder="last name"
                                                        value={pixPaymentData?.last_name}
                                                        onChange={(e) => handlePixFieldChange('last_name', e.target.value)}
                                                    />
                                                        {pixErrors.last_name && <span className="text-red-500 text-sm">{pixErrors.last_name}</span>}
                                                </div>
                                                {amountNotSet && (
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Amount
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${amountError ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="amount"
                                                            value={amount}
                                                            onChange={(e) => handleAmountSet('amount', e.target.value)}
                                                        />
                                                        {amountError && <span className="text-red-500 text-sm">{amountError}</span>}
                                                    </div>
                                                )}
                                                </div>
                                                <div className="flex gap-4 mb-5">
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Identification Number
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${pixErrors.identification_number ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="Identification Number"
                                                            value={pixPaymentData?.identification_number}
                                                            onChange={(e) => handlePixFieldChange('identification_number', e.target.value)}
                                                        />
                                                            {pixErrors.identification_number && <span className="text-red-500 text-sm">{pixErrors.identification_number}</span>}
                                                    </div>
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Identification Type
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${pixErrors.identification_type ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="Identification Type"
                                                            value={pixPaymentData?.identification_type}
                                                            onChange={(e) => handlePixFieldChange('identification_type', e.target.value)}
                                                            />
                                                                {pixErrors.identification_type && <span className="text-red-500 text-sm">{pixErrors.identification_type}</span>}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-6">
                                                    <button
                                                        className={`flex items-center justify-center gap-3 px-6 py-4  text-white font-semibold ${getBorderRadiusClass()} shadow-md transition-transform transform hover:scale-105`}
                                                        style={{
                                                            backgroundColor: brandColor,
                                                            boxShadow: `0px 10px 15px -3px ${brandColor}66, 0px 4px 6px -4px ${brandColor}33`,
                                                        }}
                                                        onClick={handlePayNow}
                                                        disabled={isExpired || isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <DivLoader className="h-6 w-6 border-red-400" />
                                                        ) : (
                                                            <>
                                                            <BsLockFill className="h-5 w-5" />
                                                            <span style={{ fontFamily: selectedFont }} > Pay Now</span>
                                                            </>
                                                        )}
                                                    </button>
                                                </div>
                                            </>
                                        ): paymentType === 'crypto' ? (
                                            <>
                                                <h3 className="text-xl font-semibold mb-4 relative text-center" style={{ fontFamily: selectedFont }}>
                                                        <span className="absolute left-0 top-1/2 w-full border-t border-gray-300" style={{ transform: 'translateY(-50%)' }}></span>
                                                        <span className="bg-white px-2 relative z-10" style={{ fontFamily: selectedFont }} >Pay with CRYPTO</span>
                                                </h3>
                                                <div className="flex gap-4 mb-5">
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            First Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${cryptoErrors.first_name ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="first name"
                                                            value={cryptoPaymentData?.first_name}
                                                            disabled={addressPreview}
                                                            onChange={(e) => handleCryptoFieldChange('first_name', e.target.value)}
                                                        />
                                                        {cryptoErrors.first_name && <span className="text-red-500 text-sm">{cryptoErrors.first_name}</span>}
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                        Last Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`border p-4 w-full ${cryptoErrors.last_name ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                        style={{ fontFamily: selectedFont }}
                                                        placeholder="last name"
                                                        value={cryptoPaymentData?.last_name}
                                                        disabled={addressPreview}
                                                        onChange={(e) => handleCryptoFieldChange('last_name', e.target.value)}
                                                    />
                                                        {cryptoErrors.last_name && <span className="text-red-500 text-sm">{cryptoErrors.last_name}</span>}
                                                </div>
                                                {amountNotSet && (
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Amount
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${amountError ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="amount"
                                                            value={amount}
                                                            disabled={addressPreview}
                                                            onChange={(e) => handleAmountSet('amount', e.target.value)}
                                                        />
                                                        {amountError && <span className="text-red-500 text-sm">{amountError}</span>}
                                                    </div>
                                                )}
                                                </div>
                                                <div className="flex gap-4 mb-5">
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Email
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${cryptoErrors.email ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="test@gmail.com"
                                                            value={cryptoPaymentData?.email}
                                                            disabled={addressPreview}
                                                            onChange={(e) => handleCryptoFieldChange('email', e.target.value)}
                                                        />
                                                        {cryptoErrors.email && <span className="text-red-500 text-sm">{cryptoErrors.email}</span>}
                                                    </div>
                                            
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Phone Number
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`border p-4 w-full ${cryptoErrors.phone ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 dark:border-white/10 dark:text-white focus:ring-2 focus:ring-blue-500 transition-all outline-none placeholder-gray-400 dark:placeholder-gray-500 shadow-sm hover:shadow-lg transition-shadow`}
                                                            style={{ fontFamily: selectedFont }}
                                                            placeholder="1234567890"
                                                            value={cryptoPaymentData?.phone}
                                                            disabled={addressPreview}
                                                            onChange={(e) => handleCryptoFieldChange('phone', e.target.value)}
                                                        />
                                                        {cryptoErrors.phone && <span className="text-red-500 text-sm">{cryptoErrors.phone}</span>}
                                                    </div>
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Country
                                                        </label>
                                                        <select
                                                            className={`border ${cryptoErrors.country ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 p-4 w-full outline-none placeholder-gray-400 dark:placeholder-gray-500`}
                                                            onChange={handleCryptoCountryChange}
                                                            style={{ fontFamily: selectedFont }}
                                                            value={cryptoPaymentData?.country}
                                                            disabled={addressPreview}
                                                            >
                                                            {allCountries.map((country) => (
                                                                <option key={country.code} value={country.code}>
                                                                {country.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {cryptoErrors.country && <span className="text-red-500 text-sm">{cryptoErrors.country}</span>}
                                                    </div>
                                                </div>
                                                <div className="flex gap-4 mb-5">
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Crypto Currency
                                                        </label>
                                                        <select
                                                            className={`border ${cryptoErrors.crypto_currency ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 p-4 w-full outline-none placeholder-gray-400 dark:placeholder-gray-500`}
                                                            onChange={handleCryptoCurrencyChange}
                                                            style={{ fontFamily: selectedFont }}
                                                            value={cryptoPaymentData?.crypto_currency}
                                                            disabled={addressPreview}
                                                            >
                                                            {allCryptoCurrency.map((currency : any) => (
                                                                <option key={currency.code} value={currency.code}>
                                                                {currency.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {cryptoErrors.crypto_currency && <span className="text-red-500 text-sm">{cryptoErrors.crypto_currency}</span>}
                                                    </div>
                                                    <div className="flex flex-col w-full">
                                                        <label className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" style={{ fontFamily: selectedFont }}>
                                                            Blockchain Network
                                                        </label>
                                                        <select
                                                            className={`border ${cryptoErrors.blockchain_network ? 'border-red-500' : ''} ${getBorderRadiusClass()} border-gray-300 p-4 w-full outline-none placeholder-gray-400 dark:placeholder-gray-500`}
                                                            onChange={handleBlockChainChange}
                                                            style={{ fontFamily: selectedFont }}
                                                            value={cryptoPaymentData?.blockchain_network}
                                                            disabled={addressPreview}
                                                            >
                                                            {blockChainNetworks.map((network : any) => (
                                                                <option key={network.code} value={network.code}>
                                                                {network.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {cryptoErrors.blockchain_network && <span className="text-red-500 text-sm">{cryptoErrors.blockchain_network}</span>}
                                                    </div>
                                                </div>
                                                {!addressPreview ? (
                                                    <div className="flex flex-col mt-6">
                                                        <button
                                                            className={`flex items-center justify-center gap-3 px-6 py-4  text-white font-semibold ${getBorderRadiusClass()} shadow-md transition-transform transform hover:scale-105`}
                                                            style={{
                                                                backgroundColor: brandColor,
                                                                boxShadow: `0px 10px 15px -3px ${brandColor}66, 0px 4px 6px -4px ${brandColor}33`,
                                                            }}
                                                            onClick={handlePayNow}
                                                            disabled={isExpired || isLoading}
                                                        >
                                                            {isLoading ? (
                                                                <DivLoader className="h-6 w-6 border-white" />
                                                            ) : (
                                                                <>
                                                                <BsLockFill className="h-5 w-5" />
                                                                <span style={{ fontFamily: selectedFont }} > Generate Address</span>
                                                                </>
                                                            )}
                                                            
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <>
                                                    <div className="flex flex-col">
                                                        <label
                                                            className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-200 flex justify-between items-center"
                                                            style={{ fontFamily: selectedFont }}
                                                        >
                                                            <span>Deposit Address</span>
                                                        </label>
                                                        <div className={`border ${getBorderRadiusCardClass()} ${(oneErrors.cardHolderName || oneErrors.cardNumber || oneErrors.expiry || oneErrors.cvv) ? 'border-red-500' : ''} border-gray-300 dark:border-white/10 focus-within:ring-2 focus-within:ring-blue-500 transition-all shadow-sm hover:shadow-lg transition-shadow`}>
                                                            <div className="flex gap-4">
                                                                <div className="flex flex-col w-1/4 py-8 pl-8">
                                                                    <QRCodeCanvas  value={qrCode} size={170} level="H" />
                                                                </div>
                                                                <div className="flex flex-col w-3/4 py-8 pr-8">
                                                                    <h4 className='font-extrabold text-2xl'>{selectedCryptoCurrency} ({cryptoPaymentData.blockchain_network}) Address</h4>
                                                                    <div className='w-full  p-2 mt-2 rounded bg-gray-200'>
                                                                        <div className="flex items-center gap-3">
                                                                            <h4 className='font-semibold text-lg'>
                                                                                {cryptoAddress}
                                                                            </h4>
                                                                            {copiedAddress ? (
                                                                                <BsCheck className="h-5 w-5" />
                                                                            ) : (
                                                                                <BsCopy className="h-5 w-5 cursor-pointer" onClick={handleCopyAddress} />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-full  p-2 mt-2 rounded bg-gray-200'>
                                                                        <div className="flex items-center gap-3">
                                                                            <h4 className='font-semibold text-lg'>
                                                                               = {cryptoAmount} {cryptoPaymentData.crypto_currency}
                                                                            </h4>
                                                                            {copiedAmount ? (
                                                                                <BsCheck className="h-5 w-5" />
                                                                            ) : (
                                                                                <BsCopy className="h-5 w-5 cursor-pointer" onClick={handleCopyAmount} />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-full  mt-2'>
                                                                        <div className="flex items-center gap-3">
                                                                            <h4 className='font-medium text-lg'>
                                                                               1 {selectedCryptoCurrency} = {getCurrencySymbol(selectedCurrency)?.icon || "$"} {currentFiatAmount}
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                            <div className="w-full px-8 pb-8">
                                                                <div className="font-medium text-lg border border-red-500 bg-gray-200 rounded p-4">
                                                                    You must ensure that you are sending <span className="font-bold">on the selected network</span> otherwise your assets/funds will be lost.
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col mt-6">
                                                        <button
                                                            className={`flex items-center justify-center gap-3 px-6 py-4  text-white font-semibold ${getBorderRadiusClass()} shadow-md transition-transform transform hover:scale-105`}
                                                            style={{
                                                                backgroundColor: brandColor,
                                                                boxShadow: `0px 10px 15px -3px ${brandColor}66, 0px 4px 6px -4px ${brandColor}33`,
                                                            }}
                                                            onClick={handleBackButton}
                                                        >
                                                            <BsArrowLeft className="h-5 w-5" />
                                                            <span style={{ fontFamily: selectedFont }} >Back</span>
                                                        </button>
                                                    </div>
                                                    </>
                                                )}
                                            </>  
                                        ): (
                                            
                                            <></>  
                                        )}
                                        <div className="flex items-center justify-center mt-6 text-sm text-gray-500 dark:text-gray-400" style={{ fontFamily: selectedFont }} >
                                            Powered by {mainlogo}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : successPreview ? (
                            <div
                                className="flex flex-col items-center justify-center w-full h-[100vh] p-5 rounded-lg"
                                style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                            >
                                <AiFillCheckCircle className="w-20 h-20 mb-4" />
                                <span className="text-xl font-semibold mb-4">Payment Success</span>
                            </div>
                        ) : pendingPreview ? (
                            <div
                                className="flex flex-col items-center justify-center w-full h-[100vh] p-5 rounded-lg"
                                style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                            >
                                <AiFillExclamationCircle  className="w-20 h-20 mb-4" />
                                <span className="text-xl font-semibold mb-4">Payment Pending</span>
                            </div>
                        ) : failedPreview ? (
                            <div
                                className="flex flex-col items-center justify-center w-full h-[100vh] p-5 rounded-lg"
                                style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                            >
                                <AiFillCloseCircle  className="w-20 h-20 mb-4" />
                                {paymentError == "" && (
                                <span className="text-xl font-semibold mb-4">Payment Failed</span>
                                )}
                                {paymentError != "" && (
                                    <span className="text-xl font-semibold mb-4">{paymentError}</span>
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    )
                ) : (
                    <div className="flex flex-col lg:flex-row  mx-auto h-[100vh]">
                        <div className="h-full md:w-2/6 lg:w-2/6 sm:w-full border-r-2 p-5" style={{ backgroundColor: brandColor , color : '#FFFFFF' }}>
                            <div className="flex flex-col h-full justify-center items-center gap-6">
                                <img
                                style={{
                                    height: "auto",
                                    width: hostname === "pay.paxum.com" ? "80px" : "150px",
                                    filter: "drop-shadow(0 4px 6px rgba(0,0,0,0.15))",
                                }}
                                src={logos[hostname] || bm2payLogo}
                                alt="Gateway Image"
                                />
                                <div className="text-center">
                                <h3 className="text-lg font-semibold text-white" style={{ fontFamily: selectedFont }}>Fast. Secure. Reliable.</h3>
                                <p className="text-lg text-indigo-200 italic" style={{ fontFamily: selectedFont }}>Your trusted gateway for seamless payments.</p>
                                </div>
                            </div>
                        </div>
                        <div  className="h-full md:w-4/6 lg:w-4/6 sm:w-full px-14 py-10">
                            <div className="flex flex-col h-full justify-center items-center">
                                <h3 className="text-xl font-semibold mb-4 relative text-center" style={{ fontFamily: selectedFont }}>
                                    <span className="bg-white px-2 relative z-10" style={{ fontFamily: selectedFont }} >Payment link already used</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                )}
                <Modal isOpen={isOpen} onClose={() => {}}>
                    <ModalOverlay className="bg-[#000] !opacity-30" />
                    {/* <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]"> */}
                    <ModalContent className="sm:max-w-xxl z-[1002] !m-auto flex min-h-[100vh] max-w-[800px] justify-center !p-3 sm:my-8 sm:w-full">
                    <ModalBody>
                        <Card extra="px-[30px] pt-[38px] pb-5 max-w-[800px]  flex flex-col !z-[1004]">
                        <h1 className="mb-[20px] text-2xl font-bold">Your link is expired!</h1>
                        {localStorage.getItem(unique_payment_link_id) == 'no' && (
                            <>
                            <p>
                                Would you like to extend its validity?
                                If yes, simply click the Extend Expiry button below.The link will extend for 5 min.
                            </p>
                        

                            <div className="mt-5 flex gap-2">
                                {/* <button
                                onClick={onClose}
                                className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                                >
                                Cancel
                                </button> */}
                                <button
                                onClick={generateNewLink}
                                className="linear rounded-xl bg-red-50 px-5 py-2 text-base font-medium text-red-500 outline-none transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                                >
                                {isLoading ? (
                                    <DivLoader className="h-6 w-6 border-red-400" />
                                ) : (
                                    "Extend Link"
                                )}
                                </button>
                            </div>
                            </>
                        )}
                        </Card>
                    </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        )}
        </>
    )
}

export default CheckoutPage