// import authRequest from "../utils/authRequest";
import authGraphQLRequest from "utils/authGraphQLRequest";

export interface OrganizationInfo {
  id?: string;
  name?: string;
  description: string;
  website?: string;
  email?: string;
  phone?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
  payment_method_ids ?: any;
  payment_methods ?: any;
}

export const createOrganization = (data: OrganizationInfo): Promise<any> => {
  return new Promise((resolve, reject) => {
    
    let { name, description, email, phone, website, address, city, state, country, postal_code, payment_methods } = data;
    let queryString = `mutation {
  createOrg(
        name: "${name}",
        description: "${description}",
        email: "${email}",
        phone: "${phone}",
        website: "${website}",
        address: "${address}",
        city: "${city}",
        state: "${state}",
        country: "${country}",
        postal_code: "${postal_code}",
        payment_method_ids: [${payment_methods.map((id : any) => `"${id}"`).join(", ")}]
      ) { 
    org {
    id
    name
    description
    website
    email
    phone
    address
    city
    state
    country
    created_at
    postal_code
    payment_methods
  }
}
}
`;


    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_create_organization'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response all org  ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err all org ::', { err });
        reject(err);
      });
  });
}

// export const createOrganization = (data: OrganizationInfo): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/organization",
//       method: "post",
//       data,
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const updateOrganization = (data: OrganizationInfo): Promise<any> => {
  
  return new Promise((resolve, reject) => {
    
    let { id, name, description, email, phone, website, address, city, state, country, postal_code, payment_methods } = data;
    console.log("payment_method_ids",payment_methods);
    let queryString = `mutation {
  updateOrg(
        id: "${id}",
        name: "${name}",
        description: "${description}",
        email: "${email}",
        phone: "${phone}",
        website: "${website}",
        address: "${address}",
        city: "${city}",
        state: "${state}",
        country: "${country}",
        postal_code: "${postal_code}",
        payment_methods: [${payment_methods.map((id : any) => `"${id}"`).join(", ")}]
      ) { 
    org {
    id
    name
    description
    website
    email
    phone
    address
    city
    state
    country
    created_at
    postal_code
    payment_methods
  }
}
}
`;


    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_update_organization'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response all org  ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err all org ::', { err });
        reject(err);
      });
  });
}
// export const updateOrganization = (data: OrganizationInfo): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/organization/" + data.id,
//       method: "put",
//       data,
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const getOrganization = (data: { id: string }): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `{
  organization(id: "${data.id}") {
    id
    name
    description
    website
    email
    phone
    address
    city
    state
    country
    postal_code
    created_at
    payment_methods
  }
}`;
    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_organization'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response org by id  ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err org by id ::', { err });
        reject(err);
      });
  });
};

// export const getAllOrganizationsUser = (): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/organization/user-all-orgainzation-list",
//       method: "get",
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };
export const getAllOrganizations = (
  page?: number,
  pageSize?: number,
  searchTexts?: string,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    // Build query parameters if they are provided
    const params = [
      page !== undefined ? `page: ${page}` : '',
      pageSize !== undefined ? `per_page: ${pageSize}` : '',
      searchTexts ? `search_term: "${searchTexts}"` : ''
    ].filter(Boolean).join(', ');

    let queryString = `{ 
       allOrgs${params ? `(${params})` : ''} {
        orgs {
          id
          name
          description
          website
          email
          phone
          address
          city
          state
          country
          postal_code
          created_at
          payment_methods
        }
      }
    }`;


    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_organization'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response all org  ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err all org ::', { err });
        reject(err);
      });
  });
};

export const deleteOrganization = (id: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    
    let queryString = `mutation {
  deleteOrg(org_id: "${id}") {
    status
    message
  }
}
`;


    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_delete_organization'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response all org  ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err all org ::', { err });
        reject(err);
      });
  });
}
// export const deleteOrganization = (id: string): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/organization/" + id,
//       method: "delete",
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };


