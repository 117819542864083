import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { get2FTAuth , get2FTAuthVerify } from "api/graphQL_users";
import Card from "components/card/custom";
import DivLoader from "components/divloader/DivLoader";
import InputField from "components/fields/InputField";
import React, { useEffect, useRef } from "react";
import { ModalFooter, ModalHeader } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { MdCheck, MdFileCopy, MdHighlightOff } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { setToken } from "utils/auth";

const TwoFactorAuthentication = ({
  status,
  data,
  profileData,
  fetch2fa,
  fetchProfile,
  model2FA,
  setModel2FA,
}: {
  status?: any;
  data?: any;
  fetch2fa?: () => void;
  profileData?: any;
  fetchProfile?: () => void;
  model2FA?: any;
  setModel2FA?: any;
}) => {
  const location = useLocation();
  const verifyData = data;
  const rootForm = {
    otp: "",
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [copy, setCopy] = React.useState<boolean>(false);
  const [copyKey, setCopyKey] = React.useState<boolean>(false);
  const [formValues, setFormValues] = React.useState<any>(rootForm);
  const [formValuesErr, setFormValuesErr] = React.useState<any>(rootForm);
  const [isCloseModal, setIsCloseModal] =  React.useState<boolean>(false);
  const [authCode, setAuthCode] = React.useState(["", "", "", "", "", ""]);
  const inputRefs = useRef<Array<HTMLInputElement | null>>(
      new Array(6).fill(null)
    );
  const navigate = useNavigate();
  const handleClose = () => {
    setIsCloseModal(true)
    setFormValues(rootForm);
    setFormValuesErr(rootForm);
    model2FA && setModel2FA("");
    setTimeout(() => {
      setIsCloseModal(false)
      onClose();
    }, 300);
  };
  useEffect(() => {
    if (status) {
      onOpen();
    }
  }, [status, model2FA]);
  function handleValueChange(e: any) {
    setFormValues((preVal: any) => {
      if (e.target.id === "otp") {
        return { ...preVal, [e.target.id]: e.target.value.slice(0, 6) };
      } else {
        return { ...preVal, [e.target.id]: e.target.value };
      }
    });
    setFormValuesErr((preVal: any) => {
      return { ...preVal, [e.target.id]: "" };
    });
  }
  function validateData() {
    const  final_otp : any = authCode.join("");
    if (final_otp < 6) {
      setFormValuesErr((preval: any) => {
        return {
          ...preval,
          otp: "OTP must be 6 characters",
        };
      });
      return false;
    }
    return true;
  }
  async function handleSubmit(e: any) {
    e.preventDefault();
    let validate = validateData();
    if (validate) {
      try {
        const  final_otp : any = authCode.join("");
        setIsLoading(true);
        await get2FTAuthVerify({
          otp: final_otp,
          user_id: verifyData?.user_id ? verifyData?.user_id : profileData?.id,
          secret_key: verifyData?.secret_key,
          recover_key: model2FA ? model2FA : verifyData?.recover_key,
          inside_flag: model2FA ? true : false,
        })
          .then((data) => {
            if(data.status == "false") {
              toast.error(data.message);
            } else {
              if (model2FA) {
                setToken(data.auth_token);
              }
              get2FTAuth({
                enable_2FA: true,
                secret_key: verifyData?.secret_key,
                recover_key: model2FA ? model2FA : verifyData?.recover_key,
                user_id: verifyData?.user_id
                  ? verifyData?.user_id
                  : profileData?.id,
              })
                .then((data) => {
                  if (model2FA) {
                    navigate("/admin/dashboard");
                  }
                  toast.success("Two-Factor Authentication Activated");
                  if (location?.pathname !== "/auth/sign-in") {
                    fetchProfile();
                  }
                })
                .catch((err) => {
                  toast.error(
                    err?.response?.data?.message ??
                      "Something went wrong while fetching proflie"
                  );
                })
                .finally(() => {});
            }
            
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.message ??
                "Something went wrong while fetching proflie"
            );
          })
          .finally(() => {});
        handleClose();
      } catch (err: any) {
        if (err?.response?.status === 422) {
          toast.error("Please provide valid values");
        }
      } finally {
        setIsLoading(false);
      }
    }
  }
  const bindInput = (value: any) => {
    const regex = new RegExp("^[^0-9]*$");
    const key = String.fromCharCode(
      !value.charCode ? value.which : value.charCode
    );
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };
  const handelEnter = (event: any) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      handleSubmit(event);
    }
  };

  const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...authCode];
      newCode[index] = value;
      setAuthCode(newCode);

      // Move to the next box if a digit is entered
      if (value !== "" && index < 5 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && authCode[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault(); 
    const pastedData = e.clipboardData.getData("text").trim(); 
  
    if (/^\d{6}$/.test(pastedData)) { 
      const newCode = pastedData.split(""); 
      setAuthCode(newCode);
      inputRefs.current[5]?.focus();
    }
  };

  const handleSubmit2FA = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit(e)
  };

  return (
    <>
      {status ? null : !profileData?.auth_2fa ? (
        <button
          onClick={() => {
            onOpen();
          }}
          className="w-fit rounded-xl border-2 border-indigo-200 bg-indigo-50 px-5 py-2 text-indigo-500 outline-none"
        >
          Activate 2FA
        </button>
      ) : (
        <button
          onClick={() => {
            fetch2fa();
          }}
          className="w-fit rounded-xl border-2 border-indigo-200 bg-indigo-50 px-5 py-2 text-indigo-500 outline-none"
        >
          Deactivate 2FA
        </button>
      )}
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <Card extra={`fixed top-0 right-0 h-full rounded-none max-w-[600px] sm:w-full flex flex-col justify-start !z-[1004] overflow-y-auto scrollbar-hide overflow-auto ${
          isOpen && !isCloseModal ? "card-enter" : "card-exit"
        }`}>
          <ModalHeader className=" flex py-[20px] mb-[20px] px-[30px] text-2xl font-bold bg-brand-500 text-white">
            <MdHighlightOff
              className="absolute right-7 top-6 h-8 w-8 cursor-pointer text-white"
              onClick={handleClose}
            />
            Two-Factor Authentication (2FA)
          </ModalHeader>
          <ModalBody className="p-4">
           
              <div className=" overflow-auto overflow-x-hidden ">
                <h2 className="mx-[30px] border-b-2 pt-5 text-lg font-medium font-semibold text-indigo-500">
                  Configuring Google Authenticator or Authy
                </h2>
                <p className="px-[30px] pt-3 text-[15px] text-gray-900 dark:text-white">
                  1. Install Google Authenticator (IOS- Android) or Authy (IOS-
                  Android).
                </p>
                <p className="px-[30px] text-[15px] text-gray-900 dark:text-white">
                  2. In the authenticator app, select "+" icon.
                </p>
                <p className="px-[30px] text-[15px] text-gray-900 dark:text-white">
                  3. Select "Scan a barcode (or QR code)" and use the phone's
                  camera to scan this barcode.
                </p>
                <h2 className="mx-[30px] border-b-2 pt-5 text-lg font-medium font-semibold text-indigo-500">
                  Scan QR Code
                </h2>
                <div className="mt-3 flex justify-center">
                  <img
                    src={`data:image/png;base64 , ${data?.base64_image}`}
                    alt="QRCode"
                    className="max-h-[200px] max-w-[200px] lg:max-h-[350] lg:max-w-[350]"
                  />
                </div>
                <h2 className="mx-[30px] border-b-2 pt-5 text-lg font-medium font-semibold text-indigo-500">
                  Or Enter Code Into Your App
                </h2>
                <div className="flex pl-[30px] pt-3">
                  <p className="pr-[10px] text-[15px] text-gray-900 dark:text-white">
                    SecretKey : {data?.secret_key}
                  </p>
                  {copyKey ? (
                    <MdCheck className="mt-1 cursor-pointer text-teal-500" />
                  ) : (
                    <MdFileCopy
                      className="mt-1 cursor-pointer text-teal-500"
                      onClick={() => {
                        navigator.clipboard.writeText(data?.secret_key);
                        setCopyKey(true);
                        setTimeout(() => {
                          setCopyKey(false);
                        }, 1500);
                      }}
                    />
                  )}
                </div>
                {!model2FA && (
                  <div className="flex pl-[30px] pt-3">
                    <p className="pr-[10px] text-[15px] text-gray-900 dark:text-white">
                      RecoverKey : {data?.recover_key}
                    </p>
                    {copy ? (
                      <MdCheck className="mt-1 cursor-pointer text-teal-500" />
                    ) : (
                      <MdFileCopy
                        className="mt-1 cursor-pointer text-teal-500"
                        onClick={() => {
                          navigator.clipboard.writeText(data?.recover_key);
                          setCopy(true);
                          setTimeout(() => {
                            setCopy(false);
                          }, 1500);
                        }}
                      />
                    )}
                  </div>
                )}
                <h2 className="mx-[30px] border-b-2 pt-5 text-lg font-medium font-semibold text-indigo-500">
                  Verify Code
                </h2>
                <p className="px-[30px] pt-3 text-[15px] text-gray-900 dark:text-white">
                  For changing the setting, please verify the authentication
                  code:
                </p>
                <div className="max-h-[calc(100vh-300px)] px-[30px] scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                  <div className="flex justify-center space-x-2 mt-4">
                    {authCode.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleChange(index, e)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        onPaste={index === 0 ? handlePaste : undefined}
                        className="w-12 h-12 text-center text-lg border border-gray-300 rounded-lg focus:ring-2 focus:ring-brand-500"
                      />
                    ))}
                  </div>
                </div>
              </div>
              
           
          </ModalBody>
          <ModalFooter className="mt-[10px] p-4">
            <div className="mt-5 flex gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
                <button
                  onClick={handleSubmit}
                  className="linear rounded-xl bg-indigo-50 px-5 py-2 text-base font-medium text-indigo-500 hover:text-white outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                >
                  {isLoading ? (
                    <DivLoader className="h-6 w-6 border-indigo-500" />
                  ) : (
                    "Verify & Activate"
                  )}
                </button>
              </div>
          </ModalFooter>
        </Card>
      </Modal>
    </>
  );
};
export default TwoFactorAuthentication;
