import React, { useState, useRef, useEffect , useContext } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import DivLoader from "components/divloader/DivLoader";
import { FaChevronDown } from "react-icons/fa";
import { useOnClickOutsideSingel } from "utils/useOnClickOutside";
import { MultiSelect } from "react-multi-select-component";
import { ClientContext } from "ClientProvider";
import { getAllGatewaysOrg } from "api/graphQL_gateways";

interface OrgGateway {
  gatewayId: string;
  gatewayName: string;
}

interface OrgGatewaysResponse {
  gateways: OrgGateway[];
}


const ClientPayment = ({
  Organization,
  slectedOrg,
  assignPaymentMethod,
  showPaymnets = true,
  handleSelectionChange,
  errorclass,
  methodName,
  viewMode,
  allGateways,
  formData
}: {
  Organization?: any;
  slectedOrg?: string;
  assignPaymentMethod?: any;
  showPaymnets?: boolean;
  handleSelectionChange: any;
  errorclass?: boolean;
  methodName?: boolean;
  viewMode?: boolean;
  allGateways: any;
  formData : any;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = React.useState([]);
  const [options, setGatewayOption] = React.useState([]);
  const [allGatewaysOrg,setAllGatewaysOrg] = React.useState([]);

  const { singleRoleData } = useContext(ClientContext);

  const handleChildChange = (e: any) => {
    setSelected(e);
    handleSelectionChange(e);
  };

  React.useEffect(() => {
    if (selected && assignPaymentMethod.length > 0 && allGatewaysOrg.length > 0) {
      console.log("gggg",allGatewaysOrg);
      const opt = assignPaymentMethod.map((ele: any) => {
        console.log("optopt",allGatewaysOrg)
        const gateway = allGatewaysOrg?.find((item: any) => item.id === ele.value);
        console.log("optopt",ele)
        console.log("optopt",gateway)
        return {
          value: ele.value,
          label: ( singleRoleData[0]?.client?.value?.client_show_method_name )  ? gateway?.name : ele.label,
        };
      });
      console.log("optopt",opt)
      setSelected(opt);
    }
    let gatewayData: any = [];
    console.log("OrganizationOrganization",Organization)
    console.log("allGatewaysallGateways",allGatewaysOrg)
    if (Organization !== undefined && allGatewaysOrg.length > 0) {
      const newOptions = Organization.map((ele: any) => {
        const gateway = allGateways?.find((item: any) => item.id === ele);
        if (gateway) {
          const data = {
            value: ele,
            label: ( singleRoleData[0]?.client?.value?.client_show_method_name )  ? gateway?.name : ele,
          };
          gatewayData.push(data);
          return data;
        }
      });
      setGatewayOption(gatewayData)
    }
  }, [Organization, allGatewaysOrg]);
  useEffect(() => {
    if(allGatewaysOrg.length > 0) {
      setGatewayOption(
        allGatewaysOrg.map((ele: any) => {
          return {
            ...ele,
            value: ele.id,
            label: ( singleRoleData[0]?.client?.value?.client_show_method_name ) ? ele?.name : ele?.id,
          };
        })
      );
    } else {
      setGatewayOption([]);
    }
  }, [allGatewaysOrg]);


  const fetchOrgGateways = (org_id : any) => {
    getAllGatewaysOrg(org_id)
      .then((data : OrgGatewaysResponse) => {
        if(data.gateways.length > 0) {
          const formattedGateways = data.gateways.map(({ gatewayId, gatewayName }) => ({
              id: gatewayId,
              name: gatewayName
          }));

          setAllGatewaysOrg(formattedGateways);
        } else {
          setAllGatewaysOrg([])
          setSelected([])
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if(formData.org_id != "") {
      fetchOrgGateways(formData.org_id);
    } 
  },[formData.org_id])

  return (
    <>
      {slectedOrg !== "" ? (
        <div className="mb-2 mt-2 text-sm text-navy-700 dark:text-white">
          <label className="ml-1.5 block pb-1 text-sm font-bold text-gray-900 dark:text-white">
            Gateways*
          </label>
          <MultiSelect
            options={options}
            value={selected}
            onChange={handleChildChange}
            labelledBy={"Select"}
            disabled={viewMode}
            className={
              errorclass === false
                ? " "
                : viewMode === true
                ? "disableClass"
                : ""
            }
            overrideStrings={{ "noOptions": "Gateway not found!" }}
            valueRenderer={(selected, options) => {
              if (selected.length === 0) return "Select gateway";
              if (selected.length === options.length) return "All gateways are selected";
              return selected.map((option) => option.label).join(", ");
            }}
          />
          {!errorclass && (
            <p className="text-sm text-orange-500">The field is required</p>
          )}
        </div>
      ) : (
        <label className="ml-1.5 block pb-1 text-sm font-bold text-gray-900 dark:text-white">
          No Payment Gateways
        </label>
      )}
    </>
  );
};

export default ClientPayment;
