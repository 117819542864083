import authGraphQLRequest from "utils/authGraphQLRequest"

let prevSearchText = "";
let prevFilterString = JSON.stringify({ clients: [], interval: [], duration: null, status: []});

export const getAllSubscription = ({ page, per_page, searchTexts,filter }: any): Promise<any> => {
  return new Promise((resolve, reject) => {

    let currentPage = page;

    if (searchTexts && !prevSearchText) {
      currentPage = 1; 
    }

    prevSearchText = searchTexts || "";

    const filterString = JSON.stringify(filter);
    if (prevFilterString !== filterString) {
      currentPage = 1;
    }
    prevFilterString = filterString;
    

    let clientsStr = filter.clients?.map((client:any) => client.value).join(",") || "";
    let intervalStr = filter.interval?.map((int:any) => int.value).join(",") || "";
    let statusStr = filter.status?.map((st:any) => st.value).join(",") || "";
    let durationStr = filter.duration || "";

    let queryString = `
    {
        allSubscription(
          page: ${currentPage}, 
          per_page: ${per_page}, 
          searchTexts: "${searchTexts}"
          ${clientsStr ? `, clients: "${clientsStr}"` : ""}
          ${statusStr ? `, status: "${statusStr}"` : ""}
          ${intervalStr ? `, interval: "${intervalStr}"` : ""}
          ${durationStr ? `, duration: "${durationStr}"` : ""}
          ){
            subscriptions
            total_count 
            current_page
            total_pages
        }
    }
    `;
    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_subscriptions'
    )
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cancelSubscription = (data:any,clientId : any,clientSecret:any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `
    mutation {
      cancelSubscription(input:  { subscription_id : "${data.subscription_id}" }) {
        status
        message
      }
    }
      `;
        authGraphQLRequest<any>(
          queryString,
          '/api/v1/graphql_cancel_subscription',
          {},
          clientId,
          clientSecret
        )
          .then((responseData) => {
            resolve(responseData);
          })
          .catch((err) => {
            reject(err);
          });
  }); 
}