import moment from 'moment';
export default function heldReportExcelSheets(jsonData:any, workbook:any, templateColor:any){
    try {
        // let reportData = jsonData.excelData.filter((sheetData: any) => sheetData.sheet_type == "CARD-TYPES" && sheetData?.sheetname?.includes('Amex-USD') && sheetData?.sheet_generation == true)
        let reportData = jsonData.excelData.filter((sheetData: any) => 
            sheetData.sheettype === "HELD" && sheetData?.sheetname === "HELD-USD"
        );
        
        reportData?.map((sheets: any, index: number) => {
            // card report for amex card
            // if (sheets?.sheet_type == 'CARD-TYPES' && sheets?.sheetname?.includes('Amex') && sheets?.sheet_generation == true) {
            // Approved
            const heldCount = Array.isArray(sheets?.sheetdata?.client_transaction_data.transactions_data) 
            ? sheets?.sheetdata?.client_transaction_data.transactions_data.length 
            : Object.keys(sheets?.sheetdata?.client_transaction_data.transactions_data ?? {}).length;

            if (heldCount > 0) {

                const worksheet = workbook?.addWorksheet(`${sheets?.sheetname}`);

                
                // column beautification
                worksheet.getColumn('B').width = 14.18;
                worksheet.getColumn('C').width = 40.18;
                worksheet.getColumn('D').width = 40;
                worksheet.getColumn('E').width = 41.27;
                worksheet.getColumn('F').width = 17.82;
                worksheet.getColumn('G').width = 27.64;
                worksheet.getColumn('H').width = 16.18;
                worksheet.getColumn('I').width = 13.82;
                worksheet.getColumn('J').width = 20.27;
                


                // merge cells for summary heading
                worksheet.mergeCells('A2:J2');
                worksheet.getCell('A2').value = 'Summary';
                worksheet.getCell('A2').alignment = { horizontal: 'center', vertical: 'middle' }
                worksheet.getCell('A2').font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell('A2').fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.yellow }
                };

                // add empty row
                worksheet.addRow([])


                let cardSummaryHeader = worksheet?.lastRow?.number + 1;

                // multiple headers with data with cell of blue color fill

                //  # of transactions
                worksheet.mergeCells(`A${cardSummaryHeader}:G${cardSummaryHeader}`);
                worksheet.getCell(`A${cardSummaryHeader}`).value = "# of Transactions";
                worksheet.getCell(`A${cardSummaryHeader}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`A${cardSummaryHeader}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };

                //  Amount
                worksheet.mergeCells(`H${cardSummaryHeader}:J${cardSummaryHeader}`);
                worksheet.getCell(`H${cardSummaryHeader}`).value = "Amount";
                worksheet.getCell(`H${cardSummaryHeader}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`H${cardSummaryHeader}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };

                

                let cardSummaryHeaderValue = worksheet?.lastRow?.number + 1;

                // value of # of transactions
                worksheet.mergeCells(`A${cardSummaryHeaderValue}:G${cardSummaryHeaderValue}`);
                worksheet.getCell(`A${cardSummaryHeaderValue}`).value = `${sheets?.sheetdata?.transactions_total?.total_transactions ?? ""}`;
                worksheet.getCell(`A${cardSummaryHeaderValue}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`A${cardSummaryHeaderValue}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };

                // value of Amount
                worksheet.mergeCells(`H${cardSummaryHeaderValue}:J${cardSummaryHeaderValue}`);
                worksheet.getCell(`H${cardSummaryHeaderValue}`).value = `${sheets?.sheetdata?.transactions_total?.total_amount.toFixed(2) ?? ""}`;
                worksheet.getCell(`H${cardSummaryHeaderValue}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`H${cardSummaryHeaderValue}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };

                

                // add empty row
                worksheet.addRow([])


                Object?.entries(sheets?.sheetdata?.client_transaction_data.transactions_data)?.forEach(([key, value]: any) => {
                    console.log("value",value)
                    // if (Array?.isArray(value) && value?.length > 0) {
                        

                        let clientDetailsRow = worksheet?.lastRow?.number + 1;

                        worksheet.mergeCells(`A${clientDetailsRow}:J${clientDetailsRow}`);
                        worksheet.getCell(`A${clientDetailsRow}`).value = `${value.client_name ?? ""}`;
                        worksheet.getCell(`A${clientDetailsRow}`).alignment = { horizontal: 'center', vertical: 'middle' }
                        worksheet.getCell(`A${clientDetailsRow}`).font = { bold: true, color: { argb: templateColor?.white } };
                        worksheet.getCell(`A${clientDetailsRow}`).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: templateColor?.yellow }
                        };

                        // adding empty row
                        worksheet.addRow([])

                        let lastRowForDataHeaderSummary = worksheet?.lastRow?.number + 1;

                        // #
                        worksheet.getCell(`A${lastRowForDataHeaderSummary}`).value = "";
                        worksheet.getCell(`A${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`A${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // Merchant
                        worksheet.getCell(`B${lastRowForDataHeaderSummary}`).value = "Merchant";
                        worksheet.getCell(`B${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`B${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // ID
                        worksheet.getCell(`C${lastRowForDataHeaderSummary}`).value = "Internal ID";
                        worksheet.getCell(`C${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`C${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // // Gateway id
                        // worksheet.getCell(`D${lastRowForDataHeaderSummary}`).value = "Gateway id";
                        // worksheet.getCell(`D${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        // worksheet.getCell(`D${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // ET_ID
                        worksheet.getCell(`D${lastRowForDataHeaderSummary}`).value = "Transaction ID";
                        worksheet.getCell(`D${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`D${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // // AUTH
                        // worksheet.getCell(`F${lastRowForDataHeaderSummary}`).value = "AUTH";
                        // worksheet.getCell(`F${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        // worksheet.getCell(`F${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // DATE
                        worksheet.getCell(`E${lastRowForDataHeaderSummary}`).value = "Date";
                        worksheet.getCell(`E${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`E${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // AMOUNT
                        worksheet.getCell(`F${lastRowForDataHeaderSummary}`).value = "Amount";
                        worksheet.getCell(`F${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`F${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // CURRENCY
                        worksheet.getCell(`G${lastRowForDataHeaderSummary}`).value = "Currency"; 
                        worksheet.getCell(`G${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`G${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // BRAND
                        worksheet.getCell(`H${lastRowForDataHeaderSummary}`).value = "Brand";
                        worksheet.getCell(`H${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`H${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // BIN
                        worksheet.getCell(`I${lastRowForDataHeaderSummary}`).value = "Bin";
                        worksheet.getCell(`I${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`I${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // last 4
                        worksheet.getCell(`J${lastRowForDataHeaderSummary}`).value = "Last 4 Digits";
                        worksheet.getCell(`J${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`J${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        
                        value?.transactions?.forEach((clientTransactionData : any, clientTransactionDataIndex : any) => {

                            // values of #
                            worksheet.getCell(`A${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionDataIndex + 1}`;
                            // worksheet.getCell(`A${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`A${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of Merchant
                            worksheet.getCell(`B${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${value.client_name ?? ""}`;
                            // worksheet.getCell(`B${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`B${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of ID
                            worksheet.getCell(`C${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.internal_id ?? ""} `;
                            // worksheet.getCell(`C${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`C${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // // values of Gateway id
                            // worksheet.getCell(`D${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.gateway_id ?? ""}`;
                            // worksheet.getCell(`D${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            // worksheet.getCell(`D${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of ET_ID
                            worksheet.getCell(`D${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.et_id ?? ""}`;
                            // worksheet.getCell(`D${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`D${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // // values of AUTH
                            // worksheet.getCell(`F${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.auth}`;
                            // worksheet.getCell(`F${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            // worksheet.getCell(`E${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of DATE
                            worksheet.getCell(`E${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.created_at ?? ""}`;
                            // worksheet.getCell(`G${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`E${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of AMOUNT
                            worksheet.getCell(`F${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${(clientTransactionData?.transaction_amount?.toFixed(2)||"") ?? ""}`;
                            // worksheet.getCell(`H${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`F${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of CURRENCY
                            worksheet.getCell(`G${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.transaction_currency ?? ""}`;
                            // worksheet.getCell(`I${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`G${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of BRAND
                            worksheet.getCell(`H${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.card_brand ?? ""}`;
                            // values of BIN
                            worksheet.getCell(`I${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.bin ?? ""}`;
                            // values of last 4
                            worksheet.getCell(`J${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.last_4 ?? ""}`;
                            // worksheet.getCell(`J${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            // worksheet.getCell(`J${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                        }

                        )

                        // add empty row
                        worksheet.addRow([])

                        
                        // calculations
                        let lastRowForDataCalculations = worksheet?.lastRow?.number + 1;
                        // #
                        worksheet.getCell(`A${lastRowForDataCalculations}`).value = `${value?.total_transactions ?? ""}`;
                        worksheet.getCell(`A${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`A${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // Merchant
                        worksheet.getCell(`B${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`B${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`B${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // ID
                        worksheet.getCell(`C${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`C${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`C${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // // Gateway id
                        // worksheet.getCell(`D${lastRowForDataCalculations}`).value = "";
                        // worksheet.getCell(`D${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        // worksheet.getCell(`D${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // ET_ID
                        worksheet.getCell(`D${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`D${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`D${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // // AUTH
                        // worksheet.getCell(`F${lastRowForDataCalculations}`).value = "";
                        // worksheet.getCell(`F${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        // worksheet.getCell(`F${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // DATE
                        worksheet.getCell(`E${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`E${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`E${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // AMOUNT
                        worksheet.getCell(`F${lastRowForDataCalculations}`).value = `${value.total_amount.toFixed(2) ?? ""}`;
                        worksheet.getCell(`F${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`F${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // CURRENCY
                        worksheet.getCell(`G${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`G${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`G${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // BRAND
                        worksheet.getCell(`H${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`H${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`H${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // BIN
                        worksheet.getCell(`I${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`I${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`I${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // Last 4
                        worksheet.getCell(`J${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`J${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`J${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                       

                        // add empty row
                        worksheet.addRow([])
                    // }
                });

            }

        })
    } catch (error) {
        // console.log("error in card type amex report", error)
    }

}