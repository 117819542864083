import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay , ModalHeader , ModalFooter} from "@chakra-ui/modal";
import { deleteRole } from "api/graphQL_role";
import Card from "components/card/custom";
import DivLoader from "components/divloader/DivLoader";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { MdDelete, MdHighlightOff, MdWarning } from "react-icons/md";
import { Select as AntSelect } from "antd";



const DeleteRoleModal = ({
  name, 
  id,
  fetchRole,
  data,
  roles
}: {
  name: string;
  id: string;
  fetchRole: () => void;
  data : any;
  roles : any;
}) => {

  const filteredRoles = roles.filter((role: any) => role.role_name !== name);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isCloseModal, setIsCloseModal] =  useState<boolean>(false);
  const [selectedRole, setSelectedRole] =  useState("");
  const [roleError, setRoleError] =  useState("");

  const handleClose = () => {
    setSelectedRole("")
    setIsCloseModal(true)
    setTimeout(() => {
      setIsCloseModal(false)
      onClose();
    }, 300);
  };

  const handleCredentialSelect = (event: any) => {
    setSelectedRole(event);
    setRoleError("")
  }

  const handleDelete = () => {

    if(data?.users.length > 0 && selectedRole == "") {
      setRoleError("Please select Role");
      return
    }

    setIsLoading(true);
    deleteRole(id, selectedRole || "")
      .then((data) => {
        fetchRole();
        toast.success("Role deleted");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message ?? "Role deletion failed");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <button
        className="text-red-500 outline-none dark:text-gray-200"
        title="Delete"
      >
        <MdDelete className="h-5 w-5 cursor-pointer " onClick={onOpen} />
      </button>
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
          <Card extra={`fixed top-0 right-0 h-full rounded-none max-w-[600px] sm:w-full flex flex-col justify-start !z-[1004] overflow-y-auto scrollbar-hide overflow-auto ${
              isOpen && !isCloseModal ? "card-enter" : "card-exit"
            }`}>
            <ModalHeader className=" flex py-[20px] mb-[20px] px-[30px] text-2xl font-bold bg-brand-500 text-white">
              <MdHighlightOff
                className="absolute right-7 top-6 h-8 w-8 cursor-pointer text-white"
                onClick={handleClose}
              />
              Delete Role
            </ModalHeader> 
            <ModalBody className="p-4">
                {data?.users && data?.users.length == 0 && (
                  <div className="p-4 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-800 rounded-md shadow-md">
                    <div className="flex items-center">
                      <MdWarning className="w-6 h-6 mr-2 text-yellow-600" />
                      <span className="">
                      This will delete the role <strong>{name}</strong> which is irreversible action. Do you still want to continue?
                      </span>
                    </div>
                  </div>
                )}
                {data?.users && data?.users.length > 0 && (
                  <div className="p-4 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-800 rounded-md shadow-md">
                    <div className="flex items-center">
                      <MdWarning className="w-6 h-6 mr-2 text-yellow-600" />
                      <span className="font-semibold">
                        The <strong>{name}</strong> role is currently assigned to the following users.
                      </span>
                    </div>
                    <p className="mt-2 text-sm">
                      To proceed with its removal, please reassign another role to these users first.
                    </p>
    
                    <div className="mt-4 p-4 border border-gray-300 bg-white rounded-lg shadow-md">
                      <h3 className="text-sm font-semibold text-gray-700 mb-2">Assigned Users:</h3>
                      <ul className="divide-y divide-gray-200">
                        {data?.users?.map((us: any) => (
                          <li key={us.id} className="py-2 flex items-center">
                            <span className="font-medium text-gray-800">{us.name}</span>
                            <span className="ml-auto text-gray-500 text-xs">({us.id})</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="mb-2 mt-4">
                      <label
                        htmlFor="org_id"
                        className={`ml-1.5 text-sm font-bold text-gray-900 dark:text-white `}
                      >
                        Select Role
                        <span className="important">*</span>
                      </label>

                      <AntSelect
                        showSearch
                        placeholder="Select credential"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          String(option?.label ?? "").toUpperCase().includes(input?.toUpperCase())
                        }
                        filterSort={(optionA, optionB) =>
                          String(optionA?.label ?? "").toLowerCase().localeCompare(
                            String(optionB?.label ?? "").toLowerCase()
                          )
                        }
                        className="w-full"
                        options={filteredRoles.map((role: any) => ({
                          value: role?.id,
                          label: String(role?.role_name),
                        }))}
                        value={selectedRole}
                        onChange={handleCredentialSelect}
                        notFoundContent={"No options found"}
                        status={roleError ? "error" : ""}
                        
                      />
                      {roleError && (
                        <div className="error-message mt-1" style={{ color: 'red', fontSize: '12px' }}>
                          {roleError}
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </ModalBody>
            <ModalFooter className="mt-[10px] p-4">
              <div className="mt-5 flex gap-2">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  className="linear rounded-xl bg-red-50 px-5 py-2 text-base font-medium text-red-600 outline-none transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 disabled:opacity-50 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                >
                  {isLoading ? (
                    <DivLoader className="h-6 w-6 border-red-400" />
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </ModalFooter>
          </Card>
      </Modal>
    </>
  );
};
export default DeleteRoleModal;
