import axios from 'axios'
import { APIPath } from 'baserURL'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BiSolidError } from 'react-icons/bi';
import DivLoader from 'components/divloader/DivLoader';

function PaymentRedirectPage() {
    const [isLoading, setLoading] = useState(true);
    const [isHTML, setIsHTML] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState("");
    const [scriptsLoaded, setScriptsLoaded] = useState(false);
    const { gatewayid, internalid } = useParams();
    const navigate = useNavigate();

    const fetchPaymentURI = async () => {
        try {
            const apiurl = `${APIPath}/api/v1/payment/redirect-uri/${gatewayid}/${internalid}`;
            const res = await axios.get(apiurl);
            
            if (res?.data?.redirect_uri_available) {
                window.location.replace(res?.data?.redirect_uri);
            }

            if (res?.data?.redirect_html_available) {
                setIsHTML(true);
                setPaymentUrl(res?.data?.html_structure);
            }
        } catch (error) {
            console.error("Error fetching payment URI:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPaymentURI();
    }, []);

    useEffect(() => {
        if (isHTML && paymentUrl) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = paymentUrl;

            // Extract script tags
            const scripts = Array.from(tempDiv.getElementsByTagName('script'));
            const externalScripts = scripts.filter(script => script.src);
            const inlineScripts = scripts.filter(script => !script.src);

            // Function to load external scripts first
            const loadExternalScripts = async () => {
                for (const script of externalScripts) {
                    await new Promise((resolve, reject) => {
                        const newScript = document.createElement('script');
                        newScript.src = script.src;
                        newScript.async = true;
                        newScript.onload = () => {
                            console.log(`Loaded: ${script.src}`);
                            resolve();
                        };
                        newScript.onerror = reject;
                        document.body.appendChild(newScript);
                    });
                }
                setScriptsLoaded(true); // Mark external scripts as loaded
            };

            loadExternalScripts();
        }
    }, [paymentUrl]);

    useEffect(() => {
        
        if (scriptsLoaded && paymentUrl) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = paymentUrl;

            // Render the HTML content (excluding script tags)
            const contentDiv = document.getElementById('paymentContent');
            if (contentDiv) {
                contentDiv.innerHTML = tempDiv.innerHTML.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '');
            }

            // Execute inline scripts after rendering the HTML
            const inlineScripts = tempDiv.getElementsByTagName('script');
            for (let script of inlineScripts) {
                const newScript = document.createElement('script');
                newScript.textContent = script.innerHTML;
                document.body.appendChild(newScript);
            }

            const parser = new DOMParser();
            const doc = parser.parseFromString(paymentUrl, 'text/html');

            // Extract the body element
            const bodyElement = doc.querySelector('body');

            if (bodyElement) {
                const onloadFunction = bodyElement.getAttribute('onload'); // Get the onload attribute
                console.log("Extracted onload function:", onloadFunction);
            
                if (onloadFunction) {
                    try {
                        console.log("Executing onload function:", onloadFunction);
                        
                        // Check if it's a function call like "someFunction()"
                        const match = onloadFunction.match(/^([\w$]+)\(\);?$/);
                        
                        if (match) {
                            // If it's a named function call (e.g., submitForm();)
                            const functionName = match[1];
                
                            if (typeof window[functionName] === 'function') {
                                window[functionName](); // Execute the function safely
                            } else {
                                console.error(`Function ${functionName} is not defined.`);
                            }
                        } else {
                            // If it's a direct JavaScript statement (e.g., document.getElementById('frmMain').submit();)
                            if (onloadFunction.includes("document.getElementById")) {
                                try {
                                    // Manually execute the DOM operation safely
                                    const form = document.getElementById("frmMain");
                                    if (form) {
                                        form.submit();
                                    } else {
                                        console.error("Form #frmMain not found.");
                                    }
                                } catch (error) {
                                    console.error("Error executing document function:", error);
                                }
                            } else {
                                console.error("Unsupported onload function format:", onloadFunction);
                            }
                        }
                    } catch (error) {
                        console.error("Error executing onload function:", error);
                    }
                }
                
                
            }


            // Call invokeSubmitIfAvailable function after scripts and HTML are ready
            invokeSubmitIfAvailable();
        }
    }, [scriptsLoaded]);

    // Function to submit the form if available
    const invokeSubmitIfAvailable = () => {
       
        if (typeof window.submit === 'function') {
            console.log("Invoking submit function manually.");
            window.submit();
        } else {
            console.error("Submit function not available yet.");
        }
    };

    return (
        <div className="bg-white">
            {isLoading ? (
                <div className="mt-5 pt-5">
                    <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
                    <p className="text-center">Loading, please wait...</p>
                </div>
            ) : paymentUrl ? (
                <div id="paymentContent" className="h-full bg-white"></div>
            ) : (
                <div className="h-screen flex flex-col justify-center">
                    <div className="self-center">
                        <BiSolidError className="mx-auto text-6xl text-red-500" />
                    </div>
                    <h5 className="self-center text-black-500 opacity-50">
                        Something went wrong, please check the URL.
                    </h5>
                </div>
            )}
        </div>
    );
}

export default PaymentRedirectPage;
