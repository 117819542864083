import { Routes, Route, Navigate } from "react-router-dom";
import 'rsuite/dist/rsuite.min.css';
// import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
// import { toast, ToastContainer } from "react-toastify";
import NotFoundPage from "NotFoundPage";
import PaymentRedirectPage from "./PaymentRedirectPage";
import PaymentInternalRedirectPage from "./PaymentInternalRedirectPage";
import CheckoutPage from "./CheckoutPage";
import Bm2payRedirectPage from "./Bm2payRedirectPage";


// import PaymentApproved from "PaymentApproved";
// import PaymentErrored from "PaymentErrored";
import PaymentStatus from "PaymentStatus";
import { useEffect } from "react";
import {getSettings} from "api/settings"

const App = () => {

  useEffect(() => {
    const fetchColors = async () => {
      try {
        getSettings(false)
          .then((data) => {
              const styles = `
                :root {
                  --indigo-500: ${data.button_hover_color} !important;
                  --indigo-600: ${data.button_active_color} !important;
                  --indigo-700: ${data.button_hover_color} !important;
                  --indigo-800: ${data.button_hover_color} !important;

                  --brand-500: ${data.button_active_color} !important;
                  --brand-600: ${data.button_hover_color} !important;
                  --brand-700: ${data.button_hover_color} !important;
                }
              `;

              let styleTag = document.getElementById("dynamic-theme");
              styleTag = document.createElement("style");
              styleTag.id = "dynamic-theme";
              document.head.appendChild(styleTag);
              styleTag.innerHTML = styles;

            })
          .catch((err) => {
            
          })
          .finally(() => {
          });
      } catch (error) {
        console.error("Error fetching theme colors:", error);
      }
    };

    fetchColors();
  }, []);

  return (
    <>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        {/* <Route path="rtl/*" element={<RtlLayout />} /> */}
        <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
        <Route
          path="/payment/redirect-uri/:gatewayid/:internalid"
          element={<PaymentRedirectPage/>}
        />
        <Route
          path="/payment/internal-return-redirect-uri/:gateway_id/:gateway_uuid"
          element={<PaymentInternalRedirectPage/>}
        />
        <Route
          path="/checkout/payment-link/:client_id/:checkout_id/:unique_payment_link_id/:internal_tx_id"
          element={<CheckoutPage/>}
        />
         {/* <Route
          path="/payment/redirect-uri/htm/:gatewayid/:internalid"
          element={<Bm2payRedirectPage/>}
        /> */}
        {/* <Route path="/payment/approved" element={<PaymentApproved />} /> */}
        {/* <Route path="/payment/failed" element={<PaymentErrored />} /> */}
        {/* <Route path={`/payment/status`} element={<PaymentStatus />} /> */}
        <Route
          path={`/payment/status/gateway_id/:gateway_id/client_id/:client_id/payment_request_id/:payment_request_id`}
          element={<PaymentStatus />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default App;
