import React, { useEffect } from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import RTLDefault from "views/rtl/default";
import { RxTokens } from "react-icons/rx";
// Auth Imports
import SignIn from "views/auth/SignIn";
import { PiHandCoinsDuotone } from "react-icons/pi";
import { CiViewTable } from "react-icons/ci";



// Icon Imports
import {
  MdHome,
  MdPerson,
  MdLock,
  MdPeople,
  MdPayment,
  MdMoney,
  MdPayments,
  MdVerifiedUser,
  MdOutlineCardTravel,
  MdOutlinePayments,
  MdSettings,
} from "react-icons/md";
import SignUp from "views/auth/Signup";
import Organizations from "views/admin/organizations";
import Clients from "views/admin/clients";
import Customers from "views/admin/customers";
import Payments from "views/admin/payments";
import { FaIntercom, FaUser, FaUserCheck, FaUserCircle } from "react-icons/fa";
import { IoMdGlobe } from "react-icons/io";
import Gateways from "views/admin/gateways";
import Users from "views/admin/users";
import { TiFlowMerge } from "react-icons/ti";
import SmartRoutings from "views/admin/smartrouting/SmartRoutings";
import PaymentMethod from "views/admin/paymentMethod";
import { BsFillPeopleFill, BsFillPersonLinesFill } from "react-icons/bs";
import Settlement from "views/admin/settlement";
import Subscription from "views/admin/subscription";
import Role from "views/admin/role";
import Token from "views/admin/tokens";
// import Payout from "views/admin/payout";
import Payouts from "views/admin/payouts";
import { HiOutlineAdjustments } from "react-icons/hi";
import API_tab from "views/admin/API_tab";
import { TbBrandZapier } from "react-icons/tb";
import { ViewLogs } from "views/admin/ViewLogs";
import BlackListClients from "views/admin/BlackList";
import Settings from "views/admin/Settings";
import { MdOutlineBlock } from "react-icons/md";
import WhiteListClients from "views/admin/WhiteList";
import { FaListCheck } from "react-icons/fa6";
import { VscChecklist } from "react-icons/vsc";


const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    isHidden: false,
    icon: <MdHome className="h-6 w-6" />,
    hideInSidebar: false,
    component: <MainDashboard />,
  },
  {
    name: "Clients",
    layout: "/admin",
    path: "clients",
    isHidden: false,
    icon: <MdPayment className="h-6 w-6" />,
    hideInSidebar: false,
    component: <Clients />,
  },
  {
    name: "Customers",
    layout: "/admin",
    path: "customers",
    isHidden: false,
    icon: <BsFillPeopleFill  className="h-6 w-6" />,
    hideInSidebar: false,
    component: <Customers />,
  },
  {
    name: "Organizations",
    layout: "/admin",
    path: "organizations",
    isHidden: false,
    icon: <MdPeople className="h-6 w-6" />,
    hideInSidebar: false,
    component: <Organizations />,
  },
  {
    name: "Transactions",
    layout: "/admin",
    path: "transactions",
    isHidden: false,
    icon: <MdPayments className="h-6 w-6" />,
    hideInSidebar: false,
    component: <Payments />,
  },
  {
    name: "Tokens",
    layout: "/admin",
    path: "tokens",
    isHidden: false,
    icon: <RxTokens className="h-6 w-6" />,
    hideInSidebar: false,
    component: <Token />,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "users",
    isHidden: false,
    icon: <MdPerson className="h-6 w-6" />,
    hideInSidebar: false,
    component: <Users />,
  },
  {
    name: "Payout",
    layout: "/admin",
    path: "payout",
    isHidden: false,
    icon: <PiHandCoinsDuotone className="h-6 w-6" />,
    hideInSidebar: false,
    component: <Payouts />,
  },
  {
    name: "Gateways",
    layout: "/admin",
    path: "gateway",
    isHidden: false,
    icon: <MdOutlineCardTravel className="h-6 w-6" />,
    hideInSidebar: false,
    component: <PaymentMethod />,
  },

  {
    name: "Subscription",
    layout: "/admin",
    path: "subscription",
    isHidden: false,
    icon: <MdMoney className="h-6 w-6" />,
    hideInSidebar: false,
    component: <Subscription />,
  },
  {
    name: "Role",
    layout: "/admin",
    path: "role",
    isHidden: false,
    icon: <FaUserCheck className="h-6 w-6" />,
    hideInSidebar: false,
    component: <Role />,
  },
  {
    name: "Settlement",
    layout: "/admin",
    path: "settlement",
    isHidden: false,
    icon: <HiOutlineAdjustments className="h-6 w-6" />,
    hideInSidebar: false,
    component: <Settlement />,
  },
  {
    name: "API Access",
    layout: "/admin",
    path: "api",
    isHidden: false,
    icon: <TbBrandZapier className="h-6 w-6" />,
    hideInSidebar: false,
    component: <API_tab />,
  },

  {
    name: "Blacklist",
    layout: "/admin",
    path: "blacklist",
    isHidden: false,
    icon: <MdOutlineBlock  className="h-6 w-6" />,
    hideInSidebar: false,
    component: <BlackListClients />,
  },

  {
    name: "Settings",
    layout: "/admin",
    path: "settings",
    isHidden: false,
    icon: <MdSettings  className="h-6 w-6" />,
    hideInSidebar: false,
    component: <Settings />,
  },

  // {
  //   name: "Whitelist",
  //   layout: "/admin",
  //   path: "whitelist",
  //   isHidden: false,
  //   icon: <VscChecklist  className="h-6 w-6" />,
  //   hideInSidebar: false,
  //   component: <WhiteListClients />,
  // },


  
  {
    name: "View Logs",
    layout: "/admin",
    path: "logs",
    isHidden: false,
    icon: <CiViewTable className="h-6 w-6" />,
    hideInSidebar: false,
    component:<ViewLogs/>
  },

  {
    name: "Smart Routing",
    layout: "/admin",
    path: "smart-routing/:id?",
    isHidden: false,
    // isHidden: `${typeof routing?.params?.id === 'undefined' ? true : false}`,
    icon: <TiFlowMerge className="h-6 w-6" />,
    hideInSidebar: false,
    component: <SmartRoutings />,
  },
  // {
  //   name: "Gateways",
  //   layout: "/admin",
  //   path: "gateways",
  //   isHidden: false,
  //   icon: <IoMdGlobe className="h-6 w-6" />,
  //   component: <Gateways />,
  // },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   isHidden: "nfttrue,
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   isHidden: "datatrue,
  //   component: <DataTables />,
  // },
  {
    name: "My Profile",
    layout: "/admin",
    path: "profile",
    isHidden: true,
    icon: <FaUserCircle className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    isHidden: false,
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  // {
  //   name: "Sign Up",
  //   layout: "/auth",
  //   path: "sign-up",
  //   isHidden: "signtrue,
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignUp />,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   isHidden: false,
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;





