import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card";
import DivLoader from "components/divloader/DivLoader";
import React, { useEffect, useState , useContext } from "react";
import { TruncateCopy } from "views/admin/organizations/components/CreateOrgModal";
import { ClientRowObj } from "..";
import ArrangeClientModal from "./ArrangeClientModal";
import CreateClientGatewaysModal from "./CreateClientGatewaysModal";
import CheckoutProductClientModal from "./CheckoutProductClientModal";
import CreateClientModal from "./CreateClientModal";
import DeleteClientModal from "./DeleteClientModal";
import { FiSearch } from "react-icons/fi";
import ShortTruncateCopy from "components/common/ShortTruncateCopy";
import Searchbox from "components/fields/Searchbox";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import Pagination from "components/pagination";
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger } from "@chakra-ui/popover";
import { Portal } from "@chakra-ui/portal";
import TooltipHorizon from "components/tooltip";
import CustomToolTip from "components/tooltips";
import { ClientContext } from "ClientProvider";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { RiArrowUpDownFill } from "react-icons/ri";


function ClientsTable(props: {
  tableData: any;
  fetchClients: () => void;
  isLoading: boolean;
  roleData?: any;
  page: number;
  setPage: any;
  totalpage: number;
  totalItems: number;
  currentPage: number;
  pageSize: number;
  setPageSize: any;
  allGateways: any;
  onValueChange: (value: string) => void;
}) {
  const {
    tableData,
    fetchClients,
    roleData,
    page,
    setPage,
    currentPage,
    totalpage,
    totalItems,
    pageSize,
    setPageSize,
    allGateways,
    onValueChange,
    isLoading
  } = props;
  let defaultData = tableData;


  const { singleRoleData } = useContext(ClientContext);

  const getIdByName = (name : any) => {
    const foundItem = allGateways.find((item : any) => item.name === name);
    return foundItem ? foundItem.id : name; // Return ID if found, otherwise return null
  };

  const [{ pageIndex }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  });

  const pagination = React.useMemo(() => ({
    pageIndex,
    pageSize,
  }), [pageIndex, pageSize],
  );

  const columns = [
    columnHelper.accessor("org_name", {
      id: "org_name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Organization Name
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info?.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">NAME</p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("client_id", {
      id: "client_id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          CLIENT ID
        </p>
      ),
      cell: (info: any) => {
        return <>
          <ShortTruncateCopy info={info?.getValue()} showCopy={true} />
        </>
      },
      enableSorting: true,
    }),
    columnHelper.accessor("client_secret", {
      id: "client_secret",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          CLIENT SECRET
        </p>
      ),
      cell: (info: any) => {
        return <>
          <ShortTruncateCopy info={info?.getValue()} showCopy={true} />
        </>
      },
    }),
    columnHelper.accessor("client_gateway_active", {
      id: "client_gateway_active",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          ENABLED GATEWAYS
        </p>
      ),
      cell: (info: any) => { 
        const gateway_count = Object.keys(info.getValue() || {}).filter(
          (key) => info.getValue()[key]
        );
      
        return (
          <div className="text-navy-700 font-bold">
            <CustomToolTip  
              content={
                gateway_count.length > 0 ? (
                  <div className="flex flex-wrap gap-2 max-w-[200px]">  
                    {gateway_count.map((m: any) => (
                      <span 
                        key={m} 
                        className="bg-gray-200 text-gray-800 px-2 py-1 rounded text-xs font-medium"
                      >
                        {( singleRoleData[0]?.client?.value?.client_show_method_name ) ? m : getIdByName(m) }
                      </span>
                    ))}
                  </div>
                ) : (
                  <span className="text-gray-500 text-xs">No Gateways</span>
                )
              }
              trigger={gateway_count.length} 
              header="Enabled Gateways"
            />
          </div>
        );
      },   
      sortingFn: (rowA, rowB, columnId) => {
        const gatewaysA = rowA.getValue(columnId) as Record<string, boolean>; // Explicit type
        const gatewaysB = rowB.getValue(columnId) as Record<string, boolean>;
      
        const aCount = Object.keys(gatewaysA || {}).filter((key) => gatewaysA[key]).length;
        const bCount = Object.keys(gatewaysB || {}).filter((key) => gatewaysB[key]).length;
      
        return aCount - bCount; // Sort numerically by gateway count
      },
      enableSorting: true,   
    }),
    columnHelper.accessor("assigned_payment_methods", {
      id: "assigned_payment_methods",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          ACTION
        </p>
      ),
      cell: (info) => {
        let paymentmethods = info?.cell?.row?.original.assigned_payment_methods;
        const methodObj = {
          "assigned_payment_methods" : paymentmethods
        }
          return (
            <>
          <div className="flex items-center gap-4 text-sm font-bold">
            {roleData?.[0]?.client?.value?.client_gateway_settings && (
              <CreateClientGatewaysModal
                fetchClients={fetchClients}
                id={info?.cell?.row?.original.client_id}
                infoData={info}
                assigned_payment_methods={methodObj}
              />
            )}


            {roleData?.[0]?.client?.value?.client_checkout_setting && (
              <CheckoutProductClientModal
                fetchClients={fetchClients}
                id={info?.cell?.row?.original.client_id}
                infoData={info}
                assigned_payment_methods={methodObj}
              />
            )}
            

            {roleData?.[0]?.client?.value?.client_gateway_routing && (
              <ArrangeClientModal
              id={info?.cell?.row?.original.client_id}
                value={info?.row?.original}
              />
            )}

          
            <CreateClientModal
              fetchClients={fetchClients}
              id={info?.cell?.row?.original.client_id}
              roleData={roleData}
              allGateways={allGateways}
              infoData={info}

            />

            <>
            
      </>
            <CreateClientModal
              fetchClients={fetchClients}
              id={info.getValue()}
              info={true}
              roleData={roleData}
              allGateways={allGateways}
              infoData={info}

            />
            {roleData?.[0]?.client?.value?.delete_client && (
              <DeleteClientModal
                fetchClients={fetchClients}
                id={info.getValue()}
              />
            )}
          </div>
          </>
        )
      },
      enableSorting: false,
    }),
  ]; // eslint-disable-next-line
  const columnsNonAction = [
    columnHelper.accessor("org_name", {
      id: "org_name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Organization Name
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info?.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">NAME</p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("client_id", {
      id: "client_id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          CLIENT ID
        </p>
      ),
      cell: (info: any) => {
        return <>
          <ShortTruncateCopy info={info?.getValue()} showCopy={true} />
        </>
      },
      enableSorting: true,
    }),
    columnHelper.accessor("client_secret", {
      id: "client_secret",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          CLIENT SECRET
        </p>
      ),
      cell: (info: any) => {
        return <>
          <ShortTruncateCopy info={info?.getValue()} showCopy={true} />
        </>
      },
    }),
    columnHelper.accessor("client_gateway_active", {
      id: "client_gateway_active",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          ENABLED GATEWAYS
        </p>
      ),
      cell: (info: any) => { 
        const gateway_count = Object.keys(info.getValue() || {}).filter(
          (key) => info.getValue()[key]
        );
      
        return (
          <div className="text-navy-700 font-bold">
            <CustomToolTip  
              content={
                gateway_count.length > 0 ? (
                  <div className="flex flex-wrap gap-2 max-w-[200px]">  
                    {gateway_count.map((m: any) => (
                      <span 
                        key={m} 
                        className="bg-gray-200 text-gray-800 px-2 py-1 rounded text-xs font-medium"
                      >
                        {( singleRoleData[0]?.client?.value?.client_show_method_name ) ? m : getIdByName(m) }
                      </span>
                    ))}
                  </div>
                ) : (
                  <span className="text-gray-500 text-xs">No Gateways</span>
                )
              }
              trigger={gateway_count.length} 
              header="Enabled Gateways"
            />
          </div>
        );
      },   
      sortingFn: (rowA, rowB, columnId) => {
        const gatewaysA = rowA.getValue(columnId) as Record<string, boolean>; // Explicit type
        const gatewaysB = rowB.getValue(columnId) as Record<string, boolean>;
      
        const aCount = Object.keys(gatewaysA || {}).filter((key) => gatewaysA[key]).length;
        const bCount = Object.keys(gatewaysB || {}).filter((key) => gatewaysB[key]).length;
      
        return aCount - bCount; // Sort numerically by gateway count
      },
      enableSorting: true,   
    }),
  ]; // eslint-disable-next-line

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [data, setData] = React.useState(() => [...defaultData]);
  const [searchVal, setSearchVal] = useState<any>("");
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    const dataVal = tableData?.filter((item: any) => {
      return searchVal.toLowerCase() === ""
        ? item
        : item.name.toLowerCase().includes(searchVal);
    });

    setData(tableData)
  }, [tableData, searchVal]);

  const table = useReactTable({
    data,
    columns:
      roleData?.[0]?.client?.value?.client_checkout_setting ||
      roleData?.[0]?.client?.value?.client_gateway_settings ||
        roleData?.[0]?.client?.value?.client_gateway_routing ||
        roleData?.[0]?.client?.value?.delete_client ||
        roleData?.[0]?.client?.value?.edit_client ||
        roleData?.[0]?.client?.value?.view_client
        ? columns
        : columnsNonAction,
    state: {
      pagination,
      sorting,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handleValueChange = (e: any) => {

    onValueChange(e);
    setSearchVal(e);
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      {/* <header className="relative flex items-center justify-between pt-5"> */}
      <header className="relative flex items-center justify-between pt-5 flex-wrap">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          All Clients
        </div>
        {/* <div className=" flex items-start justify-center">
          <select className="mb-3  flex items-center justify-center text-sm font-bold text-gray-900 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
            <option value="organization">Organization</option>
          </select>
        </div> */}
        <Searchbox onSearch={handleValueChange} />
      </header>

      <div className="mt-4  overflow-x-auto scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 relative overflow-x-auto shadow-md sm:rounded-lg">
        {props.isLoading ? (
          <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
        ) : (
          <table className="w-full w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className=""
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2"
                      >
                        <div className="flex items-center text-xs text-gray-200">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.getCanSort() && ( // Only show if sorting is enabled
                            <span className="ml-2">
                              {header.column.getIsSorted() === "asc" ? (
                                <BsArrowUp size={14} className="text-gray-900" />
                              ) : header.column.getIsSorted() === "desc" ? (
                                <BsArrowDown size={14} className="text-gray-900" />
                              ) : (
                                <RiArrowUpDownFill size={14} className="text-gray-900" />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody >
              {(table.getRowModel().rows?.length > 0) ? table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ">
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              }) :

                <tr>
                  <td colSpan={5} >
                    <p className="text-center p-4" >No records found.</p>
                  </td>

                </tr>

              }


            </tbody>
          </table>
        )}
      </div>

      <Pagination
        setPage={setPage}
        page={page}
        totalpage={totalpage}
        currentPage={currentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        arraySize={[50, 100, 200]}
      />
    </Card>
  );
}

export default ClientsTable;
const columnHelper = createColumnHelper<ClientRowObj>();
