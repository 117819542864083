// import { getAllUsers } from "api/users";
import { getAllUsers } from "api/graphQL_users";
import { useEffect, useState, useContext, useRef } from "react";
import toast from "react-hot-toast";
import CreateUserModal from "./components/CreateUserModal";
import UsersTable from "./components/UsersList";
// import { getAllOrganizations } from "api/organizations";
import { getAllOrganizations } from "api/graphQL_organizations";
// import { getRoleDrop, getSingleRole } from "api/role";
import { getRoleDrop } from "api/role";
// import { getSingleRole } from "api/role";
import { getSingleRole } from "api/graphQL_role";
import { getId } from "utils/auth";
import { ClientContext } from "ClientProvider";
import { useLocation, useNavigate } from "react-router-dom";

export type UserRowObj = {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  last_sign_in_at: string;
  created_at: string;
  updated_at: string;
  id: string;
  password: string;
  organizations: any;
  auth_2fa: boolean;
  allOrganizations : []
};
const Users = () => {
  const [users, setUsers] = useState<UserRowObj[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isDataLoading, setIsDataLoading] = useState<boolean>();
  const [page, setPage] = useState<number>(1);
  const [totalpage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(500);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [roleDataDrop, setRoleDataDrop] = useState<any>([]);
  const [organizations, setOrganizations] = useState<any>([]);
  const { singleRoleData, setSingleRoleData } = useContext(ClientContext);
  const [searchTexts, setSearchTexts] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();
  

  const fetchOrganizations = () => {
    getAllOrganizations(
      page,
      pageSize,
      searchTexts,
    )
      .then((data) => {
        console.log("check data of organization users graphql", {data})
        setOrganizations(
          data?.[0]?.map((ele: any) => {
            return { ...ele, id: ele?.id };
          })
        );
      })
      .catch((err) => {
       
      })
      .finally(() => {});
  };

  const prevValues = useRef<{  searchTexts: string }>({
    searchTexts: "__init__", 
  });

  const fetchUsers = () => {
    setIsDataLoading(true);
    if (pageSize === null || pageSize === undefined) {
      setIsDataLoading(false);
      return;
    }
    let finalSearchText = ""
    if(location.state) {
      if (location.state?.search) {
        finalSearchText = location.state.search
        const searchTexts = location.state.search
        prevValues.current = { searchTexts };
        navigate(".", { replace: true, state: null }); 
      }
    } else {
        finalSearchText = searchTexts;
    }
    const params = { page: page, per_page: pageSize, searchTexts: finalSearchText };
    getAllUsers(params)
      .then((data) => {
        setUsers(data?.users ?? []);
        setTotalPage(data.total_pages);
        setTotalItems(data.total_items);
        setCurrentPage(data.current_page);
      })
      .catch((err) => {
        // toast.error(
        //   err?.response?.data?.message ??
        //     "Something went wrong while fetching organizations"
        // );
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  };

  
  const fetchRoleDrop = () => {
    getRoleDrop()
      .then((data) => {
        if (data?.length > 0) {
          setRoleDataDrop(data ?? []);
        } else {
          setRoleDataDrop([]);
        }
      })
      .catch((err) => {
      
      })
      .finally(() => {});
  };

      
    
  useEffect(() => {  
    const hasChanged = prevValues.current.searchTexts !== searchTexts;
    if (!hasChanged) {
      return;
    }
    fetchUsers();
    
  }, [page, searchTexts]);
  useEffect(() => {
    fetchOrganizations();
    fetchRoleDrop();
  }, []);

  const serchboxValueChange = async (searchTerm: string) => {
    const search_txt = searchTerm;
    if (search_txt !== searchTexts && searchTexts !== "") {
      setSearchTexts(search_txt);
      setPage(1);
      setCurrentPage(1);
    } else {
      setSearchTexts(search_txt);
    }
  };
  return (
    <>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <CreateUserModal
          fetchUsers={fetchUsers}
          organizations={organizations}
          roleDataDrop={roleDataDrop}
          roleData={singleRoleData}
        />
      </div>
      <div className="mt-5">
        <UsersTable
          pageSize={pageSize}
          setPageSize={setPageSize}
          tableData={users}
          fetchUsers={fetchUsers}
          isLoading={isDataLoading}
          page={page}
          setPage={setPage}
          totalpage={totalpage}
          totalItems={totalItems}
          currentPage={currentPage}
          organizations={organizations}
          roleData={singleRoleData}
          roleDataDrop={roleDataDrop}
          onValueChange={serchboxValueChange}
        />
      </div>
    </>
  );
};

export default Users;
