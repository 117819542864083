import authGraphQLRequest from "utils/authGraphQLRequest";
 

export const getCustomers = ({ page, per_page, searchTexts }: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `{ 
      allCustomers(page: ${page}, per_page: ${per_page}, searchTexts: "${searchTexts}") {
        customer{
            customer_id   
            client_id     
            first_name
            last_name
            middle_name
            email
            phone
            city
            address
            state
            country
            source_client
            created_at
            updated_at
            total_transactions
            transactionsList
            paymentMethods
        }
      }
    }`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_customer_list'
    )
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err ::', { err });
        reject(err);
      });
  });
};