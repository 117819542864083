// import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
// import { ClientContext } from "ClientProvider";
import { ClientContext } from "ClientProvider";
import NotFoundPage from "NotFoundPage";
import DivLoader from "components/divloader/DivLoader";
// import Card from "components/card";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React, { useContext } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import routes from "routes";
import { getToken } from "utils/auth";

export default function Admin(props: { [x: string]: any }) {
  const { ...rest } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [permissionChecked,setPermissionChecked] = React.useState(false);
  const [currentRoute, setCurrentRoute] = React.useState("");
  //  const {
  //    SettlementAPISttus,
  //    setisOpenSettlementAPISttus,
  //    isOpenSettlementAPISttus,
  //    setSettlementAPISttus,
  //  } = useContext(ClientContext);
  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const { singleRoleData } = useContext(ClientContext);

  const checkPermission = (route: any) => {
    
    let isStatus = false;
    if (route == "gateway") {
      isStatus =  singleRoleData?.[0]?.payment_method?.value?.view_payment_method_list;
    }
    if (route == "subscription") {
      isStatus = singleRoleData?.[0]?.subscription?.value?.view_subscription_list;
    }
    if (route == "clients") {
      isStatus = singleRoleData?.[0]?.client?.value?.view_client_list;
    }
    if (route == "customers") {
      isStatus = singleRoleData?.[0]?.customers?.value?.view_customers;
    }
    if (route == "api") {
      isStatus = singleRoleData?.[0]?.api?.value?.view_api_list;
    }
    if (route == "settlement") {
      isStatus = singleRoleData?.[0]?.settlement?.value?.view_settlement_list;
    }
    if (route == "organizations") {
      isStatus =  singleRoleData?.[0]?.organization?.value?.view_organization_list;
    }
    if (route == "blacklist") {
      isStatus =  singleRoleData?.[0]?.blacklist?.value?.view_blacklist_list;
    }
    if (route == "settings") {
      isStatus = singleRoleData?.[0]?.settings?.value?.view_settings;
    }
    if (route == "transactions") {
      isStatus = singleRoleData?.[0]?.payment?.value?.view_payment_list;
    }
    if (route == "tokens") {
      isStatus = singleRoleData?.[0]?.token?.value?.view_token_list;;
    }
    if (route == "payout") {
      isStatus = singleRoleData?.[0]?.payout?.value?.view_payout_list;
    }
    if (route == "role") {
      isStatus = singleRoleData?.[0]?.role?.value?.view_role_list;
    }
    if (route == "users") {
      isStatus = (singleRoleData?.[0]?.user?.value?.view_user_list);
    }
    if (route === "dashboard") {
      isStatus = true;
    }
    if (route === "profile") {
      isStatus = true;
    }

    return isStatus;
  };

  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  React.useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const route_name = pathSegments[pathSegments.length - 1];
    const route_permision = checkPermission(route_name)
    if(route_permision != undefined) {
      setPermissionChecked(true);
    }
    if(route_permision != undefined && !route_permision) {
      navigate("/admin/dashboard");
    }
  },[singleRoleData])
  
  React.useEffect(() => {
    let token = getToken();
    if (!token) {
      navigate("/auth/sign-in", { replace: true });
      window.location.reload()
    }
  }, [navigate]);
  React.useEffect(() => {
    let token = getToken();
    if (!token) {
      navigate("/auth/sign-in", { replace: true });
      window.location.reload()
    }
  }, []);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[25px] h-full flex-none transition-all md:pr-2 lg:ml-[250px] xl:ml-[250px]`}
        >
          {/* Routes */}
          <div className="h-full">
            
              <>
                <Navbar
                  onOpenSidenav={() => setOpen(!open)}
                  brandText={currentRoute}
                  secondary={getActiveNavbar(routes)}
                  {...rest}
                />
                {permissionChecked ? (
                  <div className="pt-5s mx-auto mb-auto mt-5 h-full min-h-[90vh] p-2 md:pr-2">
                    <Routes>
                      <>
                        {getRoutes(routes)}
                        <Route path="*" element={<NotFoundPage />} />
                        <Route
                          path="/"
                          element={<Navigate to="/admin/default" replace />}
                        />
                      </>
                    </Routes>
                  </div>
                ): (
                  <div className="h-[100vh]">
                    <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
                  </div>
                 )} 
              </>
            
            {/* <div className="p-3">
              <Footer />
            </div> */}
          </div>
        </main>
      </div>
   
    </div>
  );
}
