import React, { useRef, useState } from "react";
import { BsDownload } from "react-icons/bs";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
// import jsonData from './settlementReportData.json';
import { downloadSettlementReport } from "api/settlement";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import toast from "react-hot-toast";

// Import report functions
import summaryReportExcelSheets from "./settlementReportTypes/summary/summaryReports";
import cardTypeVisaReportsExcelSheets from "./settlementReportTypes/cards/CardTypeVisaReports";
import cardTypeAmexReportsExcelSheets from "./settlementReportTypes/cards/CardTypeAmexReports";
import cardTypeMasterCardReportsExcelSheets from "./settlementReportTypes/cards/CardTypeMasterCardReports";
import refundReportExcelSheets from "./settlementReportTypes/refunded/refundReports";
import cancelReportExcelSheets from "./settlementReportTypes/canceled/cancelReports";
import declinedReportExcelSheets from "./settlementReportTypes/declined/declinedReports";
import disputeReportExcelSheets from "./settlementReportTypes/dispute/disputeReports";
import chargebackReportExcelSheets from "./settlementReportTypes/chargeback/chargebackReports";
import heldReportExcelSheets from "./settlementReportTypes/held/heldReports";
import erroredReportExcelSheets from "./settlementReportTypes/errored/erroredReports";
import { FaDownload } from "react-icons/fa";

interface ClientTransactionData {
  settlementReportCanBeGenerated : boolean,
  excelData: any[],
}

function SettlementReport(props: {
  ReportData?: any;
  handleDownloadReport?: any;
  settlementInfo?: any;
  fetchAllsettlement?:any;

}) {
  const { ReportData, settlementInfo, handleDownloadReport ,fetchAllsettlement} = props;

  const [loadingDownload, setLoadingDownload] = useState(false);

  // const [jsonData, setJsonData] = useState([]);
  let jsonData: any = [];

  const createExcel = () => {
    try {
      let templateColor = {
        yellow: "b59a67",
        blue: "152046",
        black: "070708",
        white: "f5f5fc",
      };

      if (jsonData?.settlementReportCanBeGenerated) {
        // console.log({jsonData})
        const workbook = new ExcelJS.Workbook();

        summaryReportExcelSheets(jsonData, workbook, templateColor);
        cardTypeAmexReportsExcelSheets(jsonData, workbook, templateColor);
        cardTypeMasterCardReportsExcelSheets(jsonData, workbook, templateColor);
        cardTypeVisaReportsExcelSheets(jsonData, workbook, templateColor);
        refundReportExcelSheets(jsonData, workbook, templateColor);
        cancelReportExcelSheets(jsonData, workbook, templateColor);
        chargebackReportExcelSheets(jsonData, workbook, templateColor);
        disputeReportExcelSheets(jsonData, workbook, templateColor);
        heldReportExcelSheets(jsonData, workbook, templateColor);
        declinedReportExcelSheets(jsonData, workbook, templateColor);
        erroredReportExcelSheets(jsonData, workbook, templateColor);

        workbook.xlsx.writeBuffer().then((buffer: any) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, `Settlement Report.xlsx`);
        });
        fetchAllsettlement()
      } else {
        alert("Cannot create Excel.");
      }
    } catch (error) {
      console.error("Error in Excel download", error);
    }
  };

  const handleExcelData = () => {
    try {
      setLoadingDownload(true);

    //   const res2 : ClientTransactionData  = {
    //     "settlementReportCanBeGenerated" : true,
    //     "excelData" : [
    //       {
    //           "currency": "USD",
    //           "sheetname": "REFUND-USD",
    //           "sheettype": "CARD-TYPE",
    //           "sheetdata": {
    //               "client_transaction_data": {
    //                   "transactions_data": {
    //                       "REFUNDED-USD": [],
    //                       "HELD_REFUNDED-USD": []
    //                   }
    //               },
    //               "transactions_total": {
    //                   "REFUNDED-USD": {
    //                       "total_transactions": 0,
    //                       "total_amount": 0,
    //                       "total_percent_fee": 0,
    //                       "total_fixed_fee": 0,
    //                       "total_fee_amount": 0,
    //                       "total_reserve": 0,
    //                       "total_net_amount": 0
    //                   },
    //                   "HELD_REFUNDED-USD": {
    //                       "total_transactions": 0,
    //                       "total_amount": 0,
    //                       "total_percent_fee": 0,
    //                       "total_fixed_fee": 0,
    //                       "total_fee_amount": 0,
    //                       "total_reserve": 0,
    //                       "total_net_amount": 0
    //                   }
    //               }
    //           }
    //       },
    //       {
    //           "currency": "USD",
    //           "sheetname": "PENDING-USD",
    //           "sheettype": "PENDING",
    //           "sheetdata": {
    //               "client_transaction_data": {
    //                   "transactions_data": [
    //                       {
    //                           "client_id": "sFGQyJU0CdTynJL4oqApYlOfJ1wzM6ufAeI9gAawgCg",
    //                           "client_name": "Rapyd",
    //                           "transactions": [
    //                               {
    //                                   "client_name": "Rapyd",
    //                                   "internal_id": "d098a79e-38b0-46af-b898-ce61cf41ab2e",
    //                                   "et_id": "payment_4ef02a88aa04dddc870da1a11ec244a0",
    //                                   "created_at": "2025-02-03T05:05:00.153617",
    //                                   "transaction_amount": 4587.39,
    //                                   "transaction_currency": "USD",
    //                                   "card_brand": "VISA",
    //                                   "bin": "411111",
    //                                   "last_4": "1111",
    //                                   "reserved_fee_percent": "0",
    //                                   "fixed_fee": "0",
    //                                   "reserved_fee_amount": "0.0",
    //                                   "net_amount": "4128.651",
    //                                   "reserve": "458.73900000000003",
    //                                   "gateway_id": "e4b26ff7-a147-4bcf-b113-00921e8e8979"
    //                               }
    //                           ],
    //                           "total_transactions": 1,
    //                           "total_amount": 4587.39,
    //                           "total_percent_fee": 0,
    //                           "total_fixed_fee": 0.0,
    //                           "total_fee_amount": 0.0,
    //                           "total_reserve": 458.73,
    //                           "total_net_amount": 4128.65
    //                       }
    //                   ]
    //               },
    //               "transactions_total": {
    //                   "total_transactions": 1,
    //                   "total_amount": 4587.39,
    //                   "total_percent_fee": 0,
    //                   "total_fixed_fee": 0.0,
    //                   "total_fee_amount": 0.0,
    //                   "total_reserve": 458.73,
    //                   "total_net_amount": 4128.65
    //               }
    //           }
    //       },
    //       {
    //           "currency": "USD",
    //           "sheetname": "VISA-USD",
    //           "sheettype": "CARD-TYPE",
    //           "sheetdata": {
    //               "client_transaction_data": {
    //                   "transactions_data": {
    //                       "VISA_APPROVED-USD": [
    //                           {
    //                               "client_id": "sFGQyJU0CdTynJL4oqApYlOfJ1wzM6ufAeI9gAawgCg",
    //                               "client_name": "Rapyd",
    //                               "transactions": [
    //                                   {
    //                                       "client_name": "Rapyd",
    //                                       "internal_id": "1b265b67-f58f-4b01-b4d6-2fc83f3a1791",
    //                                       "et_id": "payment_1286800114a838722457063d5a4b40e1",
    //                                       "created_at": "2025-02-03T06:29:08.063743",
    //                                       "transaction_amount": 4587.39,
    //                                       "transaction_currency": "USD",
    //                                       "card_brand": "VISA",
    //                                       "bin": "411111",
    //                                       "last_4": "1111",
    //                                       "reserved_fee_percent": "8.75",
    //                                       "fixed_fee": "0.5",
    //                                       "reserved_fee_amount": "401.89662500000003",
    //                                       "net_amount": "3725.754375",
    //                                       "reserve": "459.23900000000003",
    //                                       "gateway_id": "e4b26ff7-a147-4bcf-b113-00921e8e8979"
    //                                   }
    //                               ],
    //                               "total_transactions": 1,
    //                               "total_amount": 4587.39,
    //                               "total_percent_fee": 401,
    //                               "total_fixed_fee": 0.5,
    //                               "total_fee_amount": 401.89,
    //                               "total_reserve": 459.23,
    //                               "total_net_amount": 3725.75
    //                           }
    //                       ]
    //                   }
    //               },
    //               "transactions_total": {
    //                   "VISA_APPROVED-USD": {
    //                       "total_transactions": 1,
    //                       "total_amount": 4587.39,
    //                       "total_percent_fee": 401,
    //                       "total_fixed_fee": 0.5,
    //                       "total_fee_amount": 401.89,
    //                       "total_reserve": 459.23,
    //                       "total_net_amount": 3725.75
    //                   }
    //               }
    //           }
    //       },
    //       {
    //           "currency": "USD",
    //           "sheetname": "SUMMARY-USD",
    //           "sheettype": "SUMMARY",
    //           "sheetdata": {
    //               "organization": "Demo Organization",
    //               "clients": [
    //                   "Rapyd"
    //               ],
    //               "settlement_period": "2025-W6",
    //               "transaction_currencies": "USD",
    //               "settlement_currency": "USDT",
    //               "date_range": [
    //                   {
    //                       "name": "VISA",
    //                       "starting_date": "2024-04-01",
    //                       "ending_date": "2024-05-16"
    //                   },
    //                   {
    //                       "name": "Amex",
    //                       "starting_date": "2024-04-01",
    //                       "ending_date": "2024-05-16"
    //                   },
    //                   {
    //                       "name": "MasterCard",
    //                       "starting_date": "2024-04-01",
    //                       "ending_date": "2024-05-16"
    //                   }
    //               ],
    //               "payout_date": "2024-10-10 10:24:36.026463",
    //               "psp": 1,
    //               "transaction_count": {
    //                   "approved_transactions": {
    //                       "count": 1,
    //                       "cost": 100.0
    //                   },
    //                   "declined_transactions": {
    //                       "count": 0,
    //                       "cost": 0.0
    //                   },
    //                   "total_transactions": 1
    //               },
    //               "gross_volume": {
    //                   "processe_amount": {
    //                       "count": 1,
    //                       "amount": 4587.39,
    //                       "cost": ""
    //                   }
    //               },
    //               "deductions": {
    //                   "not-rate": {
    //                       "chargeback_transactions": {
    //                           "cost": "",
    //                           "count": 0,
    //                           "amount": 0
    //                       },
    //                       "refund_transactions": {
    //                           "cost": "",
    //                           "count": 0,
    //                           "amount": 0
    //                       },
    //                       "total_deductions": {
    //                           "cost": "",
    //                           "count": "",
    //                           "amount": 0
    //                       }
    //                   },
    //                   "rate": {
    //                       "chargeback_rate": {
    //                           "cost": "",
    //                           "count": 0.0,
    //                           "amount": 0.0
    //                       },
    //                       "refund_rate": {
    //                           "cost": "",
    //                           "count": 0.0,
    //                           "amount": 0.0
    //                       },
    //                       "total_deductions_rate": {
    //                           "cost": "",
    //                           "count": "",
    //                           "amount": 0.0
    //                       }
    //                   }
    //               },
    //               "charge_fees": {
    //                   "transaction_fees": {
    //                       "cost": "",
    //                       "count": 1,
    //                       "amount": 401
    //                   },
    //                   "fixed_transaction_fees": {
    //                       "cost": "",
    //                       "count": 1,
    //                       "amount": 0.5
    //                   },
    //                   "declined_fees": {
    //                       "cost": "",
    //                       "count": 0,
    //                       "amount": 0
    //                   },
    //                   "chargeback_fees": {
    //                       "cost": "",
    //                       "count": 0,
    //                       "amount": 0
    //                   },
    //                   "refunded_fees": {
    //                       "cost": "",
    //                       "count": 0,
    //                       "amount": 0
    //                   },
    //                   "dispute_fee": {
    //                       "cost": "",
    //                       "count": 0,
    //                       "amount": 0
    //                   },
    //                   "total_charges": {
    //                       "cost": "",
    //                       "count": "",
    //                       "amount": 401.5
    //                   },
    //                   "payout_before_net_rolling_reserve": {
    //                       "cost": "",
    //                       "count": "",
    //                       "amount": 4185.89
    //                   }
    //               },
    //               "held": {
    //                   "held_brought_forward": {
    //                       "cost": "",
    //                       "count": "",
    //                       "amount": 53534.25
    //                   },
    //                   "current_period_held": {
    //                       "cost": "",
    //                       "count": 0,
    //                       "amount": 0
    //                   },
    //                   "current_period_held_approved": {
    //                       "cost": "",
    //                       "count": 0,
    //                       "amount": 0
    //                   },
    //                   "current_period_refunded": {
    //                       "cost": "",
    //                       "count": 0,
    //                       "amount": 0
    //                   },
    //                   "total_held": {
    //                       "cost": "",
    //                       "count": "",
    //                       "amount": 53534.25
    //                   }
    //               },
    //               "reserved": {
    //                   "balance_brought_forward": {
    //                       "cost": "",
    //                       "count": "",
    //                       "amount": -597719.2999999999
    //                   },
    //                   "current_period_reserve_deduction": {
    //                       "cost": "",
    //                       "count": 1,
    //                       "amount": 459.23
    //                   },
    //                   "current_period_reserve_release": {
    //                       "cost": "",
    //                       "count": "",
    //                       "amount": 459.23900000000003
    //                   },
    //                   "net_rolling_reserve": {
    //                       "cost": "",
    //                       "count": "",
    //                       "amount": -0.009000000000014552
    //                   },
    //                   "balance_carried_forward": {
    //                       "cost": "",
    //                       "count": "",
    //                       "amount": -597719.291
    //                   }
    //               }
    //           }
    //       }
    //   ]
    //   }
    //   // setJsonData(res)
    //   jsonData = res2;
    //   createExcel();

    //   setLoadingDownload(false);

      downloadSettlementReport(settlementInfo?.row?.original?.id)
        .then((res: any) => {
          if (res?.settlementReportCanBeGenerated) {
            jsonData = res;
            createExcel();
          } else {
            toast.error("Oops! There are no transaction of clients for the organization in the selected date period.");
          }
        })
        .catch(() => {
          toast.error("Something went wrong.");
        })
        .finally(() => {
          setLoadingDownload(false);
        });
    } catch (error) {
      console.error("Error in handling Excel data", error);
    }
  };

  return (
    <div>
      {loadingDownload ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <span onClick={handleExcelData}>
          <FaDownload
            title="Download file"
            className="h-5 w-5 cursor-pointer text-blue-500"
          />
        </span>
      )}
    </div>
  );
}

export default SettlementReport;
