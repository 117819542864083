import React, { useEffect, useState } from "react";
import SmartRouting from ".";
import toast from "react-hot-toast";
import { MdFileCopy } from "react-icons/md";
import TextArea from "components/fields/TextArea";
import { BsPlusCircle } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";
import { createSmartRouting, getSmartRouting } from "api/smart-routing";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import InfoModal from "./InfoModal";
import DivLoader from "components/divloader/DivLoader";

function SmartRoutings() {
  // debugger
  const [routingData, setRoutingData] = useState([]);
  const [routingDataForJSON, setRoutingDataForJSON] = useState([]);
  const [routingList, setRoutingList] = useState<any>([]);
  const [deleteRouteRow, setDeleteRouteRow] = useState<null | number>(null);
  const [OpenDeleteRowModal, setOpenDeleteRowModal] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState("FLOW");
  const [formErrorCheck,setFormErrorCheck] = useState(false);
  const prams = useParams();
  const onFormError = (e:any) => {
    console.log("type",e)
    setFormErrorCheck(e)
  }
  const { id: clientId } = prams;
  const getFomatObject = (data: any) => {
    let smart_condition: any = {
      id: data?.id,
      operational_operator: data?.condition_type,
      conditions: Array.isArray(data?.conditions)
        ? data?.conditions?.length > 0
          ? data?.conditions?.map((mData: any) => {
              return {
                param1: mData?.left?.name,
                param2: mData?.right?.value,
                condition_type: mData?.type,
              };
            })
          : []
        : [data?.conditions]?.map((mData: any) => {
            return {
              param1: mData?.left?.name,
              param2: mData?.right?.value,
              condition_type: mData?.type,
            };
          }),

      action:
        data[1]?.type == "ACTION"
          ? {
              name: "process_payment",
              arguments: {
                name: "gateway",
                value:
                  data[1]?.type == "ACTION"
                    ? data[1]?.action?.arguments[0]?.value
                    : "",
              },
            }
          : undefined,
      child_smart_condition:
        data[1]?.type == "CONDITION" ? getFomatObject(data[1]) : undefined,
    };
    return smart_condition;
  };
  const getRoutignData = (data: any) => {
    let a: any = [];
    if (Object.keys(data)?.length > 0) {
      setRoutingData((prev: any) => {
        if (prev.length > 0) {
          if (prev?.filter((f: any) => f?.id == data?.id)?.length > 0) {
            return prev?.map((m: any) => {
              if (m?.id == data?.id) {
                return data;
              } else {
                return m;
              }
            });
          } else {
            prev?.forEach((D: any) => {
              a.push(D);
            });
            a.push(data);
            return a;
          }
        } else {
          return [data];
        }
      });
    }
  };
  const crrateSmartRouting = () => {
    setRoutingList((prev: any) => {
      return [
        ...prev,
        {
          id: uuidv4(),
          component: <SmartRouting getRoutignData={getRoutignData} formErrorCheck = {onFormError}/>,
        },
      ];
    });
  };

  const saveChanges = async () => {
    try {
      setLoading(true);
      if (true) {
        // POST API
        if (false) {
        } else {
          await createSmartRouting(
            {
              smart_routing_conditions: routingData,
            },
            clientId
          );
          fetchRoutingData();
        }
        toast.success("Saved");
      }
    } catch (error) {
      toast.error("Invalid JSON Data");
    } finally {
      setLoading(false);
    }
  };

  const getType = (objData: any) => {
    if ("child_smart_condition" in objData) {
      return true;
    } else if ("action" in objData) {
      return false;
    }
  };
  const getConditions = (conditionsData: any) => {
    let formatData =
      conditionsData?.length > 1
        ? conditionsData?.map((mData: any) => {
            return {
              type: mData?.condition_type,
              left: {
                type: "VARIABLE",
                name: mData?.param1,
              },
              right: {
                type: "STATIC",
                value: mData?.param2,
              },
            };
          })
        : conditionsData?.map((mData: any) => {
            return {
              type: mData?.condition_type,
              left: {
                type: "VARIABLE",
                name: mData?.param1,
              },
              right: {
                type: "STATIC",
                value: mData?.param2,
              },
            };
          })[0];
    return formatData;
  };
  const getFomatAPIObject = (data: any) => {
    let formatdata: any = {
      "0": {},
      id: data?.id,
      type: getType(data) ? "CONDITION" : "ACTION",
      condition_type: data?.operational_operator,
      conditions: getConditions(data?.conditions),
      "1": getType(data)
        ? getFomatAPIObject(data?.child_smart_condition)
        : {
            type: "ACTION",
            id: uuidv4(),
            action: {
              type: "FUNCTION",
              name: data?.action?.name,
              arguments: [
                {
                  name: data?.action?.arguments?.name,
                  type: "STATIC",
                  value: data?.action?.arguments?.value,
                },
              ],
            },
          },
    };
    return formatdata;
  };
  useEffect(() => {
    fetchRoutingData();
  }, []);
  const fetchRoutingData = () => {
    // calling GET API
    setLoading(true);
    getSmartRouting(clientId)
      .then((data: any) => {
        if (data?.route_info?.smart_routing_conditions) {
          setRoutingList((prev: any) => {
            return data?.route_info?.smart_routing_conditions?.map(
              (mData: any) => {
                return {
                  id: uuidv4(),
                  component: (
                    <SmartRouting
                      initialRoutesValues={mData}
                      getRoutignData={getRoutignData}
                      formErrorCheck = {onFormError}
                    />
                  ),
                };
              }
            );
          });
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="p-3   py-5 pt-1">
      {isLoading && <DivLoader className="m-5 h-6 w-6 border-indigo-500" />}
      {true && (
        <div className="my-5 flex justify-between gap-3">
          <div className="flex gap-2">
            <button
              className={`w-fit rounded-lg border px-4 py-2 outline-none ${
                viewMode === "FLOW"
                  ? "border-indigo-300 bg-indigo-50 text-indigo-500"
                  : "border-gray-200 bg-white"
              }`}
              onClick={() => {
                setViewMode("FLOW");
              }}
            >
              FLOW View
            </button>
            <InfoModal heading={"Smart Routes JSON DATA"} info={routingData} />
          </div>
          <div className={` `}>
            <button
              className={`flex w-fit rounded-xl border border-teal-300 bg-teal-50 px-5 py-2 text-teal-500 outline-none`}
              onClick={saveChanges}
              disabled={isLoading || formErrorCheck}
            >
              <span className="flex">
                {isLoading && (
                  <span>
                    {" "}
                    <DivLoader className="m-0 h-3 w-3 border-indigo-500" />
                  </span>
                )}{" "}
                <span className="mx-2">Save Changes</span>
              </span>
            </button>
          </div>
        </div>
      )}
      <div>
        <hr />
      </div>
      {viewMode == "JSON" ? (
        <div className="relative">
          <TextArea
            variant="auth"
            extra="mb-2 w-full"
            label=""
            placeholder=""
            id={"routing"}
            cols={80}
            height="700px"
            maxHeight="1000px"
            value={JSON.stringify(routingDataForJSON, null, 4)}
          />
          <div className="absolute right-2 top-3 flex items-center gap-4">
            <MdFileCopy
              className="h-6 w-6 cursor-pointer text-teal-500"
              onClick={async () => {
                await navigator.clipboard.writeText(
                  JSON.stringify(routingData)
                );
                if (routingData) {
                  toast.success("Copied");
                }
              }}
            />
          </div>
        </div>
      ) : (
        false && (
          <div className="">
            {Object.keys(routingList)?.map((key: any) => {
              return (
                <div className="  my-3 ">
                  <div className="">{routingList[key]}</div>
                </div>
              );
            })}
          </div>
        )
      )}

      {viewMode != "JSON" && (
        <>
          {routingList?.map((mData: any, index: number) => {
            return (
              <div
                className="my-2 border bg-white p-2 shadow"
                key={String(mData?.id)}
              >
                {mData?.component}
                <div className="my-3 flex justify-end text-center">
                  <span className="me-2">
                    <InfoModal
                      heading={"Smart Routes JSON DATA"}
                      info={routingData[index]}
                    />
                  </span>

                  <button
                    onClick={() => {
                      setDeleteRouteRow(index);
                      setOpenDeleteRowModal(true);
                    }}
                    className="mb-6 flex  items-end justify-end gap-2 whitespace-nowrap  rounded border bg-red-500 p-2 text-brand-500 text-navy-700 text-white outline-none hover:bg-red-700 hover:text-white dark:text-gray-200"
                  >
                    <span className=" "> Delete </span>
                  </button>
                </div>
              </div>
            );
          })}
          <button
            onClick={() => {
              crrateSmartRouting();
            }}
            className="my-2 mb-6 flex items-end justify-end gap-2 whitespace-nowrap  rounded border bg-indigo-500 p-2 text-brand-500 text-navy-700 text-white outline-none hover:bg-indigo-700 hover:text-white dark:text-gray-200"
          >
            <BsPlusCircle className="h-5 w-5  " />
            <span className=" "> Add New Condition </span>
          </button>
        </>
      )}

      <Modal
        isOpen={OpenDeleteRowModal}
        onClose={() => {
          setOpenDeleteRowModal(false);
          setDeleteRouteRow(null);
        }}
      >
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="sm:max-w-xxl z-[1002] !m-auto flex min-h-[100vh] max-w-[800px] justify-center !p-3 sm:my-8 sm:w-full">
          <ModalBody>
            <Card extra=" max-w-[800px] px-[30px] pt-[35px] pb-[35px] flex flex-col !z-[1004]">
              <h1 className="mb-[20px] text-2xl font-bold">Confirm</h1>
              <p>{"Do you want delete thie record."}</p>
              <div className="mt-5 flex gap-2">
                <button
                  onClick={() => {
                    setOpenDeleteRowModal(false);
                    setDeleteRouteRow(null);
                  }}
                  className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    let filterComp = routingList?.filter(
                      (f: any, Cindex: number) => {
                        return Cindex != deleteRouteRow;
                      }
                    );
                    let filterData = routingData?.filter(
                      (f: any, Cindex: number) => {
                        return Cindex != deleteRouteRow;
                      }
                    );
                    setRoutingList(filterComp);
                    setRoutingData(filterData);
                    setOpenDeleteRowModal(false);
                    setDeleteRouteRow(null);
                  }}
                  className="linear rounded-xl bg-red-50 px-5 py-2 text-base font-medium text-red-500 outline-none transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                >
                  {"Delete"}
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default SmartRoutings;
