import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card";
import DivLoader from "components/divloader/DivLoader";
import React, { useContext } from "react";
// import {
//   MdCancel,
//   MdCheck,
//   MdCheckCircle,
//   MdError,
//   MdFileCopy,
//   MdOutlineReplayCircleFilled,
//   MdTimer,
// } from "react-icons/md";
import { convertToFloat } from "utils/formatNumber";
// import { GatewayTypes } from "views/admin/clients/components/CreateClientGatewaysModal";
import { PaymentRowObj } from "..";
import InfoModal from "./InfoModal";
import RefundModal from "./RefundModal";
//import { StatusRender } from "./PaymentsList";
import StatusRender from "./StatusRender";
import ShortTruncateCopy from "components/common/ShortTruncateCopy";
import { getImage } from "utils/commonFunction";
import { ClientContext } from "ClientProvider";
import CustomToolTip from "components/tooltips";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { RiArrowUpDownFill } from "react-icons/ri";

function PaymentsListDashboard(props: {
  tableData: any;
  fetchPayments: () => void;
  isLoading: boolean;
  paymentName: boolean;
  allGateways: any[];  
}) {
  const { tableData, fetchPayments, isLoading, paymentName , allGateways} = props;

  const { singleRoleData} = useContext(ClientContext);
  //// console.log("paymentName",paymentName);
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("client", {
      id: "client",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          CLIENT NAME
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm font-medium text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("amount", {
      id: "amount",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          AMOUNT
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm font-medium uppercase text-navy-700 dark:text-white">
            {/* {convertToFloat(info.getValue())} {info?.row?.original?.currency} */}

            <p className="text-sm font-bold uppercase text-navy-700 dark:text-white">
              {convertToFloat(info.getValue())}{" "}
              {info?.row?.original?.currency}
              {info?.row?.original?.converted_amount &&
                info?.row?.original?.converted_currency && (
                  <span>
                    {` (${info?.row?.original?.converted_amount} ${info?.row?.original?.converted_currency})`}
                  </span>
                )}
            </p>
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("gateway_id", {
      id: "gateway_id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          GATEWAY {paymentName ? "NAME" : "HASH ID"}
        </p>
      ),
      cell: (info: any) => {
        let gateway_name = info?.cell?.row?.original?.gateway_name;
        return (
          <>
            <div>
              {
                <>
                  {gateway_name === null ? (
                    <ShortTruncateCopy
                      info={info?.cell?.row?.original?.gateway_id}
                      showCopy={true}
                    />
                  ) : (
                    <span className="flex items-center ">
                      <img
                        style={{ height: "auto", width: "15px" }}
                        className="h-auto w-20"
                        src={getImage(info?.cell?.row?.original?.gateway_name)}
                        alt="Gateway Image"
                      />
                      <p className="px-2">
                        {info?.cell?.row?.original?.gateway_name}
                      </p>
                    </span>
                  )}
                </>
              }
            </div>
          </>
        );
      },
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          STATUS
        </p>
      ),
      cell: (info) => {
        // // console.log("infio")
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center gap-1.5 text-sm font-bold">
                
                {true ? (
                  <CustomToolTip
                    content={
                      info?.row?.original?.payment_history?.length > 0 ? (
                        info?.row?.original?.payment_history?.map(
                          (hisData: any, index: number) => {
                            let errorMessage = ""
                            if(info.getValue() == 'DECLINED' || info.getValue() == 'ERRORED' || info.getValue() == 'FAILED') {
                              const gatewayResponse = info?.row?.original?.gateway_response?.gateway_response;
                              const errorKeys = ["errorMessage", "message", "decline_reason","error_message"];
                              const findLastErrorMessage = (obj: any): string | null => {
                                let lastValue: string | null = null;
                              
                                const search = (data: any) => {
                                  if (!data || typeof data !== "object") return;
                              
                                  for (const key of Object.keys(data)) {
                                    if (errorKeys.includes(key) && key !== "_content") {
                                      lastValue = data[key]; // Store the last found error message
                                    }
                              
                                    // Recursively search inside nested objects
                                    if (typeof data[key] === "object") {
                                      search(data[key]);
                                    }
                              
                                    // Special handling for `_content`
                                    if (key === "_content" && typeof data[key] === "string") {
                                      try {
                                        const parsedContent = JSON.parse(data[key]);
                              
                                        // Check for `decline_reason` inside the parsed content
                                        if (parsedContent.decline_reason) {
                                          lastValue = parsedContent.decline_reason;
                                        } else {
                                          // If `decline_reason` is not found, check for other error keys
                                          for (const parsedKey of Object.keys(parsedContent)) {
                                            if (errorKeys.includes(parsedKey)) {
                                              lastValue = parsedContent[parsedKey];
                                            }
                                          }
                                        }
                                      } catch (error) {
                                        console.error("Failed to parse _content:", error);
                                      }
                                    }
                                  }
                                };
                              
                                search(obj);
                                return lastValue;
                              };
                              
                              
                              const lastError = findLastErrorMessage(gatewayResponse);
                              errorMessage = typeof lastError === "string" ? lastError : "";

                             
                            }
                            return (
                              <>
                                <Card className="m-2 shadow " key={index}>
                                  <div className="border-1 max-w-[70vh] border  border-white  p-3">
                                    {index == 0 && (
                                      <p className="">
                                        Created at {hisData?.created_at} by API
                                        call from [{hisData?.client}
                                        {" - "}
                                        {hisData?.ip}] with status{" "}
                                        {hisData?.status}

                                        {["DECLINED", "ERRORED", "FAILED"].includes(hisData?.status) && (
                                          <>
                                            <br />
                                            Reason : {errorMessage !== "" ? errorMessage : "Reason not mentioned by the gateway"}
                                          </>
                                        )}
                                      </p>
                                    )}

                                    <div className="">
                                      {hisData?.status == "CHARGEBACK" &&
                                        index != 0 && (
                                          <div className="">
                                            <p>
                                              Status change from{" "}
                                              {hisData?.previous_status} to{" "}
                                              {hisData?.status} at{" "}
                                              {hisData?.status_changed_date} by{" "}
                                              {hisData?.client
                                                ? `System Api Call ${
                                                  singleRoleData?.[0]?.payment
                                                      ?.value
                                                      ?.payment_show_method_name
                                                      ? hisData?.gateway_name
                                                      : info?.cell?.row
                                                          ?.original
                                                          ?.gateway_id || ""
                                                  }`
                                                : `${
                                                    hisData?.first_name || ""
                                                  } ${
                                                    hisData?.last_name || ""
                                                  }`}
                                            </p>
                                            <p>
                                              ARN Number : {hisData?.arn || "-"}
                                            </p>
                                            <p>
                                              Charge Back Reason :{" "}
                                              {hisData?.reason || "-"}
                                            </p>
                                          </div>
                                        )}
                                      {hisData?.status != "CHARGEBACK" &&
                                        index != 0 && (
                                          <p className=" ">
                                            Status change from{" "}
                                            {hisData?.previous_status} to{" "}
                                            {hisData?.status} at{" "}
                                            {hisData?.status_changed_date} by{" "}
                                            {hisData?.client
                                              ? `System Api Call ${
                                                singleRoleData?.[0]?.payment?.value
                                                    ?.payment_show_method_name
                                                    ? hisData?.gateway_name
                                                    : info?.cell?.row?.original
                                                        ?.gateway_id || ""
                                                }`
                                              : `${hisData?.first_name || ""} ${
                                                  hisData?.last_name || ""
                                                }`}

                                                
                                              {["DECLINED", "ERRORED", "FAILED"].includes(hisData?.status) && (
                                                <>
                                                  <br />
                                                  Reason : {errorMessage !== "" ? errorMessage : "Reason not mentioned by the gateway"}
                                                </>
                                              )}
                                            {/* Status change from {hisData?.previous_status} to {hisData?.status} at {hisData?.status_changed_date} by {hisData?.client ? `System Api Call  ${hisData?.first_name || ""} ${hisData?.last_name || ""}` : ""} */}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </Card>
                              </>
                            );
                          }
                        )
                      ) : (
                        <span className="">No history available.</span>
                      )
                    }
                    trigger={
                      <button className="flex items-center gap-1.5">
                        <StatusRender
                          status={info?.row?.original?.status}
                          value={info.getValue()}
                        />
                      </button>
                    }
                    header={"Status History"}
                  />
                ) : (
                  <StatusRender
                    status={info?.row?.original?.status}
                    value={info.getValue()}
                  />
                )}
              </div>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor("et_id", {
      id: "et_id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          ACTION
        </p>
      ),
      cell: (info: any) => {
        let enRefund = allGateways?.find(
          (gateway) => gateway.id === info?.row?.original?.gateway_id
        );
        return (
          <div className="flex items-center">
            {singleRoleData?.[0]?.payment?.value?.refund_payment_list && (
            <RefundModal
              info={info?.row?.original}
              transactionID={info?.row?.original?.id}
              fetchPayments={fetchPayments}
              enRefund={enRefund?.refund}
              varient="small"
            />
            )}
            {singleRoleData?.[0]?.payment?.value
                    ?.view_payment_response_information  && (
            <InfoModal info={info?.row?.original} varient="small" />
          )}
          </div>
        )
      },
      enableSorting:false
    }),
  ]; // eslint-disable-next-line
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [data, setData] = React.useState(() => [...defaultData]);
  React.useEffect(() => {
    setData(tableData);
  }, [tableData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6 mb-6"}>
      <header className="relative flex flex-wrap items-center justify-between pt-5">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Recent Transactions
        </div>
        <a
          href="/admin/transactions"
          className="linear ml-auto mt-[5px] rounded-full bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          View More
        </a>
      </header>
      <div className="mt-4 overflow-x-auto scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5">
        {isLoading ? (
          <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
        ) : (
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                      >
                        <div className="flex items-center text-xs text-gray-200">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {header.column.getCanSort() && ( // Only show if sorting is enabled
                              <span className="ml-2">
                                {header.column.getIsSorted() === "asc" ? (
                                  <BsArrowUp size={14} className="text-gray-900" />
                                ) : header.column.getIsSorted() === "desc" ? (
                                  <BsArrowDown size={14} className="text-gray-900" />
                                ) : (
                                  <RiArrowUpDownFill size={14} className="text-gray-900" />
                                )}
                              </span>
                            )}
                          </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[110px] border-white/0 pr-1 pt-2"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}

              {table.getRowModel().rows?.length == 0 && (
                <tr>
                  <td colSpan={5} className="py-3 text-center">
                    Data not found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </Card>
  );
}

export default PaymentsListDashboard;
const columnHelper = createColumnHelper<PaymentRowObj>();
