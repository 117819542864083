import Card from "components/card/custom";
import Searchbox from "components/fields/Searchbox";
import React from "react";
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Pagination from "components/pagination";
import { BlackListClientsRowObj } from "..";
import DeleteModal from "./DeleteBlockRecordModal";
// import { deleteOrganization } from "api/organizations";
import { deleteOrganization } from "api/graphQL_organizations";
import { deleteBlackListRecord, getBlackListHistoryById } from "api/blackListClient";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader 
} from "@chakra-ui/modal";
import {
  createBlackListClient,
  getBlackListRecordById,
  updateBlackListRecord,
} from "api/blackListClient";
// import { createOrganization, getOrganization, updateOrganization, } from "api/organizations";
// import { createOrganization, updateOrganization, } from "api/organizations";
import { createOrganization, updateOrganization, } from "api/graphQL_organizations";
import { getOrganization } from "api/graphQL_organizations";

import Checkbox from "components/checkbox";
import DivLoader from "components/divloader/DivLoader";
import InputField from "components/fields/InputField";
import TextArea from "components/fields/TextArea";
import { toast } from "react-hot-toast";
import { BsPlusCircle } from "react-icons/bs";
import {
  MdCheck,
  MdEdit,
  MdFileCopy,
  MdHighlightOff,
  MdHistory,
  MdInfo,
  MdVisibility,
} from "react-icons/md";
import { MultiSelect } from "react-multi-select-component";
import { getImage } from "utils/commonFunction";
import InfoModal from "./InfoModal";
export interface iCreateOrgForm {
  first_name: string;
  last_name: string;
  bin: string;
  last_four: string;
  email: string;
  is_binAndlast_four?: any;
  is_client_name?: any;
  is_email?: any;
}
const ViewHistoryModal = ({
  fetchBlackListClientsData,
  id,
  info,
  roleData,
  infoData,
  tableData,
}: {
  fetchBlackListClientsData: () => void;
  id?: string;
  info?: boolean;
  roleData?: any;
  infoData?: any;
  tableData?: any;
}) => {
  const rootForm = {
    first_name: "",
    last_name: "",
    bin: "",
    last_four: "",
    email: "",
    is_binAndlast_four: false,
    is_client_name: false,
    is_email: false,
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [formValues, setFormValues] = React.useState<any>();
  const [viewMode, setViewMode] = React.useState<boolean>(info);
  const [selected, setSelected] = React.useState([]);
  const [options, setGatewayOption] = React.useState([]);
  const [formValuesErr, setFormValuesErr] = React.useState<any>(rootForm);
  const [multiselectError, setMultiselectError] = React.useState<boolean>(true);
  const [methodName, setMethodName] = React.useState<boolean>(false);
  const [isCloseModal, setIsCloseModal] =  React.useState<boolean>(false);
  
  let defaultData = formValues;

  const [data, setData] = React.useState([]);

  const handleClose = () => {
    setData([]);
    setIsCloseModal(true)
    setTimeout(() => {
      setIsCloseModal(false)
      onClose();
    }, 300);
  };

  async function fetchBlockedUserHistoryById(id: string) {
    setIsLoading(true);
    await getBlackListHistoryById(id)
      .then((data: any) => {
        setData(data);
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message ?? "Unable to fetch data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const columns = [
    columnHelper.accessor("client_name", {
      id: "client_name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Client Name
        </p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue()}
              </p>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Created At
        </p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue()}
              </p>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("request", {
      id: "request",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Request
        </p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {true && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                <InfoModal
                  heading={info?.row?.original?.client_name}
                  info={info.getValue()}
                />
              </p>
            )}
          </>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
  });

  return (
    <>
      {info
        ? roleData?.[0]?.blacklist?.value?.view_blacklist && (
            <div
              onClick={() => {
                if (id) {
                  fetchBlockedUserHistoryById(id);
                }
                onOpen();
              }}
            >
              {" "}
              <MdHistory
                className="h-5 w-5 cursor-pointer text-blue-500"
                title="View History"
              />
            </div>
          )
        : ""}
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        {/* <ModalContent className="sm:max-w-xxl scrollbarhide   z-[1002] !m-auto flex max-h-[100vh] min-h-[100vh] max-w-[800px]  justify-start overflow-auto sm:my-8 sm:w-full "> */}
        <Card extra={`fixed top-0 right-0 h-full rounded-none max-w-[600px] sm:w-full flex flex-col justify-start !z-[1004] overflow-y-auto scrollbar-hide overflow-auto ${
            isOpen && !isCloseModal ? "card-enter" : "card-exit"
          }`}>
          <ModalHeader className="py-[20px] mb-[20px] px-[30px] text-2xl font-bold bg-brand-500 text-white">
            <MdHighlightOff
              className="absolute right-7 top-6 h-8 w-8 cursor-pointer text-white"
              onClick={handleClose}
            />
            History
          </ModalHeader>
          <ModalBody className="p-2">
            
              <div className="px-3">
                <div className="relative mt-4 overflow-x-auto overflow-x-auto shadow-md scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 sm:rounded-lg">
                  {isLoading ? (
                    <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
                  ) : (
                    <table className="w-full w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
                      <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                        {table?.getHeaderGroups().map((headerGroup) => (
                          <tr
                            key={headerGroup.id}
                            className="!border-px !border-gray-400"
                          >
                            {headerGroup.headers?.map((header) => {
                              return (
                                <th
                                  key={header.id}
                                  colSpan={header.colSpan}
                                  onClick={header.column.getToggleSortingHandler()}
                                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2  pt-4 text-start"
                                >
                                  <div className="items-center justify-between px-2 text-xs text-gray-200">
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {{
                                      asc: "",
                                      desc: "",
                                    }[header.column.getIsSorted() as string] ??
                                      null}
                                  </div>
                                </th>
                              );
                            })}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {table.getRowModel().rows?.length > 0 ? (
                          table.getRowModel().rows.map((row) => {
                            return (
                              <tr
                                key={row.id}
                                className={`border-b hover:bg-gray-50 dark:border-gray-700  dark:bg-gray-800 ${
                                  row.getIsExpanded() ? "" : ""
                                } ${!row.getCanExpand() ? "" : ""} `}
                              >
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <td
                                      key={cell.id}
                                      className={`border-white/0 px-2 py-3`}
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10}>
                              <p className="p-4 text-center">
                                No records found.
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>

              
           
          </ModalBody>
          <ModalFooter className="mt-[10px]">
           <div className="mt-5  flex gap-2 px-[30px] pb-[30px]">
              <button
                onClick={handleClose}
                className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
              >
                Close
              </button>
            </div>
          </ModalFooter>
        </Card>  
        {/* </ModalContent> */}
      </Modal>
    </>
  );
};
export default ViewHistoryModal;
const columnHelper = createColumnHelper<any>();
