import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay , ModalHeader , ModalFooter } from "@chakra-ui/modal";
// import { createClient, getClient, updateClientById } from "api/clients";
import { getClient, createClient , updateClientById } from "api/graphQL_clients";
// import { getAllOrganizations, getAllOrganizationsUser, getOrganization, } from "api/organizations";
import { getAllOrganizations , getOrganization } from "api/graphQL_organizations";
import Card from "components/card/custom";
import DivLoader from "components/divloader/DivLoader";
import InputField from "components/fields/InputField";
import TextArea from "components/fields/TextArea";
import ClientPayment from "./ClientPayment";
import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { BsPlusCircle } from "react-icons/bs";
import { MdEdit, MdHighlightOff, MdInfo } from "react-icons/md";
import { Select as AntSelect } from "antd";
import _ from 'lodash';

interface iCreateOrgForm {
  name: string;
  org_id: string;
  description?: string;
  website?: string;
  email?: string;
  phone?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
  assigned_payment_methods?: string;
}

interface Organization {
  id: string;
  name: string;
  description: string;
  payment_methods: string[];
}

const CreateClientModal = ({
  fetchClients,
  id,
  info,
  roleData,
  allGateways,
  infoData,
}: {
  fetchClients: () => void;
  id?: string;
  info?: boolean;
  roleData?: any;
  allGateways?: any;
  infoData?: any;
}) => {
  const rootForm = {
    name: "",
    org_id: "",
    description: "",
    website: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    assigned_payment_methods: "",
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formValues, setFormValues] = React.useState<iCreateOrgForm>(rootForm);
  const [initialValues, setInitialFormValues] = React.useState<iCreateOrgForm>(rootForm);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [allorganizations, setallOrganizations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [viewMode, setViewMode] = React.useState<boolean>(info);
  const [org, setOrg] = React.useState();
  const [orgID, setOrgID] = React.useState();
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [checkedpaymentMethods, setCheckedPaymentMethods] = React.useState([]);
  const [multiselectError, setMultiselectError] = useState<boolean>(false);
  const [formValuesErr, setFormValuesErr] =
    React.useState<iCreateOrgForm>(rootForm);
  const [methodName, setMethodName] = useState<boolean>(false);
  const [isCloseModal, setIsCloseModal] =  React.useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(500);
  const [searchTexts, setSearchTexts] = useState<string>("");
  const fetchOrganizations = () => {
    setIsLoading(true);
    getAllOrganizations(
      page,
      pageSize,
      searchTexts,
    )
      .then((data) => {
        setOrganizations(
          data?.[0]?.map((ele: any) => {
            return {
              name: ele.name,
              id: ele.id,
            };
          })
        );
        setallOrganizations(data);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
            "Something went wrong while fetching organizations"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function fetchClient() {
    getClient(id)
      .then((data: any) => {
        // console.log("get client by id in jsx graphql :::", data)
        setFormValues(data[0]);
        setInitialFormValues(data[0]);
        setOrgID(data[0].org_id);
        const selectedOptions = data[0]?.assigned_payment_methods.map(
          (ele: any) => {
            const gateway = allGateways?.find((item: any) => item.id === ele);
            return {
              value: ele,
              label: methodName ? gateway?.name : ele,
            };
          }
        );

        setCheckedPaymentMethods(selectedOptions);
        if (data[0]?.assigned_payment_methods?.length > 0) {
          setMultiselectError(true);
        } else {
          setMultiselectError(false);
        }
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message ?? "Unable to fetch data");
      });
  }

  React.useEffect(() => {
    setMultiselectError(true);
    if (orgID !== undefined) {
      let method = roleData[0]?.client?.value?.client_show_method_name;
      setMethodName(method);

      getOrganization({ id: orgID })
        .then((data: any) => {
          setPaymentMethods(data[0]?.payment_methods);
        })
        .catch((err: any) => {
          toast.error(err?.response?.data?.message ?? "Unable to fetch data");
        });
    }
  }, [orgID]);

  const handleClose = () => {
    setFormValues(rootForm);
    setFormValuesErr(rootForm);
    setIsCloseModal(true)
    setTimeout(() => {
      setIsCloseModal(false)
      onClose();
    }, 300);
  };

  function handleChildSelectionChange(selected: any) {
    setFormValues((prevData: any) => {
      return {
        ...prevData,
        assigned_payment_methods: selected?.map((ele: any) => ele.value),
      };
    });

    if (selected?.length > 0) {
      setMultiselectError(true);
    } else {
      setMultiselectError(false);
    }
  }
  function handleValueChange(e: any) {
    let method = roleData[0]?.client?.value?.client_show_method_name;
    setMethodName(method);

    const { id, value } = e.target;

    const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/.*)?$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\d+$/;
  
    let isValid = true;
    let errorMessage = "";
  
    if (id === "website" && !websiteRegex.test(value)) {
      isValid = false;
      errorMessage = "Invalid website URL";
    }
  
    if (id === "email" && !emailRegex.test(value)) {
      isValid = false;
      errorMessage = "Invalid email address";
    }
  
    if (id === "phone" && !phoneRegex.test(value)) {
      isValid = false;
      errorMessage = "Invalid phone number";
    }
  
    if (!isValid) {
      setFormValuesErr((prevErr: any) => ({
        ...prevErr,
        [id]: errorMessage,
      }));
      setFormValues((prevVal: any) => ({
        ...prevVal,
        [id]: value,
      }));
      return;
    } else {
      setFormValuesErr((prevErr: any) => ({
        ...prevErr,
        [id]: "",
      }));
      setFormValues((prevVal: any) => ({
        ...prevVal,
        [id]: value,
      }));
    }

    // setFormValues((preVal: any) => {
    //   return { ...preVal, [e.target.id]: e.target.value };
    // });
    // setFormValuesErr((preVal: any) => {
    //   return { ...preVal, [e.target.id]: "" };
    // });
    // const orgData = e.target.value ? e.target.value : "";
    // const getPaymentMethods = findPaymentMethods(orgData);
    // setOrg(orgData);
  }

  function findPaymentMethods(orgData: string) {
    const scrap_data: Organization[] = allorganizations[0];
    const selected2 = scrap_data.find(
      (organization) => organization.id === orgData
    );
    if (selected2) {
      setPaymentMethods(selected2.payment_methods);
    }
  }

  function validateData() {
    let verifier: iCreateOrgForm;
    if (!formValues?.name) {
      verifier = { ...verifier, name: "The field is required" };
    }
    if (!formValues?.org_id) {
      verifier = { ...verifier, org_id: "The field is required" };
    }

    const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/.*)?$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\d+$/;

    if (formValues.website !== "" && !websiteRegex.test(formValues.website)) {
      verifier = { ...verifier, website: "Invalid website URL" };
    }

    if (formValues.email !== "" && !emailRegex.test(formValues.email)) {
      verifier = { ...verifier, email: "Invalid email address" };
    }
  
    if (formValues.phone !== "" && !phoneRegex.test(formValues.phone)) {
      verifier = { ...verifier, phone: "Invalid phone number" };
    }

    if (formValues?.assigned_payment_methods?.length < 1) {
      verifier = {
        ...verifier,
        assigned_payment_methods: "The field is required",
      };
      setMultiselectError(false);
    } else {
      setMultiselectError(true);
    }
    if (verifier) {
      setFormValuesErr(verifier);
      return false;
    }
    return true;
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    let validate = validateData();
    if (validate) {
      try {
        setIsLoading(true);
        if (id) {
          const hasChanges = !_.isEqual(formValues, initialValues);
          if (!hasChanges) {
                toast.error("No changes were detected. You're all set!");
                handleClose();
          } else {
            await updateClientById(formValues);
            toast.success("Client details updated successfully");
            fetchClients();
            handleClose();
          }
        } else {
          await createClient(formValues);
          toast.success("Client added successfully");
          fetchClients();
          handleClose();
        }        
      } catch (err: any) {
        console.log("Errprrrrrr",err);
        if (err?.response?.status === 422) {
          toast.error("Please provide valid values");
        } else {
          toast.error(err?.response?.data?.message ?? "Login failed");
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  const hasFormChanged = () => {
    return Object.keys(formValues).some((key) => {
      const k = key as keyof iCreateOrgForm;
      return formValues[k] !== initialValues[k];
    });
  };

  return (
    <>
      {info ? (
        roleData?.[0]?.client?.value?.view_client && (
          <div
            onClick={() => {
              if (id) {
                fetchClient();
              }
              fetchOrganizations();
              setViewMode(true)
              onOpen();
            }}
          >
            <MdInfo
              className="h-5 w-5 cursor-pointer text-blue-500"
              title="View"
            />
          </div>
        )
      ) : id ? (
        roleData?.[0]?.client?.value?.edit_client && (
          <div
            onClick={() => {
              if (id) {
                fetchClient();
              }
              fetchOrganizations();
              onOpen();
            }}
          >
            <MdEdit
              className="h-5 w-5 cursor-pointer text-blue-500"
              title="Edit"
            />
          </div>
        )
      ) : roleData?.[0]?.client?.value?.add_client ? (
        <div
          onClick={() => {
            onOpen();
            fetchOrganizations();
          }}
        >
          <Card extra="w-fit px-5 cursor-pointer border-brand-500">
            <button className="flex items-center justify-center gap-2 whitespace-nowrap p-5 text-navy-700 outline-none dark:text-gray-200">
              <BsPlusCircle className="h-5 w-5 text-brand-500" />
              <span className="text-brand-500"> Add new client</span>
            </button>
          </Card>
        </div>
      ) : null}

      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        {/* <ModalContent className="fixed-position top-0 right-0 rounded-none sm:max-w-xxl overflow-y-auto scrollbar-hide  z-[1002] !m-auto flex max-h-[100vh] min-h-[100vh] max-w-[600px]   justify-start sm:my-8 sm:w-full "> */}
          <Card extra={`fixed top-0 right-0 h-full rounded-none max-w-[600px] sm:w-full flex flex-col justify-start !z-[1004] overflow-y-auto scrollbar-hide overflow-auto ${
            isOpen && !isCloseModal ? "card-enter" : "card-exit"
          }`}>
            <ModalHeader className="py-[20px] mb-[20px] px-[30px] text-2xl font-bold bg-brand-500 text-white">
              {!viewMode && (
                <MdHighlightOff
                  className="absolute right-7 top-6 h-8 w-8 cursor-pointer text-white"
                  onClick={handleClose}
                />
              )}

              {viewMode
              ? "View"
              : id
              ? infoData?.row?.original?.name + " - " + "Edit Clients "
              : "Create Client"}
            </ModalHeader>
            <ModalBody className="p-2">
                {viewMode && (
                  <MdEdit
                    className="absolute right-7 top-6 h-6 w-6 cursor-pointer text-white"
                    onClick={() => {
                      setViewMode(!viewMode);
                    }}
                  />
                )}
                <div className="px-[30px]">
                  <div className="flex gap-5">
                    <div className="w-[49%]">
                      <label
                        htmlFor="org_id"
                        className={`ml-1.5 text-sm font-bold text-gray-900 dark:text-white`}
                      >
                        Organization<span className="important">*</span>
                      </label>
                      <AntSelect
                        showSearch={true}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        allowClear
                        placeholder="Select Organization"
                        optionFilterProp="children"
                        className="h-[48px] w-full mt-[5px]"
                        disabled={viewMode}
                        options={organizations?.map((m: any) => {
                          return {
                            ...m,
                            label: m?.name,
                            value: m?.id,
                          };
                        })}
                        value={formValues?.org_id || undefined}
                        onChange={(e: any) => {
                          setFormValues((preVal: any) => {
                            return { ...preVal, org_id: e };
                          });
                          setFormValuesErr((preVal: any) => {
                            return { ...preVal, org_id: "" };
                          });
                        }}
                        notFoundContent={"Entered organization not found!"}
                      />
                      {formValuesErr?.org_id && (
                        <p className="text-sm text-orange-500">
                          The field is required
                        </p>
                      )}
                    </div>
                    <div className="w-[49%]">
                      <InputField
                      variant="auth"
                      extra="mb-2 w-full"
                      placeholder="Google inc."
                      id="name"
                      label="Name*"
                      type="text"
                      disabled={viewMode}
                      value={formValues?.name}
                      onChange={handleValueChange}
                      errMessage={formValuesErr?.name ? formValuesErr?.name : ""}
                    />
                    </div>
                  </div>
                  
                  <div className="flex gap-5">
                    <InputField
                      variant="auth"
                      extra="mb-2 w-full"
                      label="Website"
                      placeholder="www.google.com"
                      id="website"
                      type="text"
                      disabled={viewMode}
                      value={formValues?.website}
                      onChange={handleValueChange}
                      errMessage={formValuesErr?.website ? formValuesErr?.website : ""}
                    />
                    <InputField
                      variant="auth"
                      extra="mb-2 w-full"
                      label="Email"
                      placeholder="google@gmail.com"
                      id="email"
                      type="text"
                      disabled={viewMode}
                      value={formValues?.email}
                      onChange={handleValueChange}
                      errMessage={formValuesErr?.email ? formValuesErr?.email : ""}

                    />
                  </div>
                  <div className="flex gap-5">
                    <InputField
                      variant="auth"
                      extra="mb-2 w-full"
                      label="Phone"
                      placeholder="+919909988088"
                      id="phone"
                      type="text"
                      disabled={viewMode}
                      value={formValues?.phone}
                      onChange={handleValueChange}
                      errMessage={formValuesErr?.phone ? formValuesErr?.phone : ""}

                    />

                    <InputField
                      variant="auth"
                      extra="mb-2 w-full"
                      label="Address line"
                      placeholder="45 - Test street"
                      id="address"
                      type="text"
                      disabled={viewMode}
                      value={formValues?.address}
                      state={formValuesErr?.address ? "error" : ""}
                      onChange={handleValueChange}
                    />
                  </div>
                  <div className="flex gap-5">
                    <InputField
                      variant="auth"
                      extra="mb-2 w-full"
                      label="City"
                      placeholder="Mountain View"
                      id="city"
                      type="text"
                      disabled={viewMode}
                      value={formValues?.city}
                      state={formValuesErr?.city ? "error" : ""}
                      onChange={handleValueChange}
                    />
                    <InputField
                      variant="auth"
                      extra="mb-2 w-full"
                      label="State"
                      placeholder="California"
                      id="state"
                      type="text"
                      disabled={viewMode}
                      value={formValues?.state}
                      state={formValuesErr?.state ? "error" : ""}
                      onChange={handleValueChange}
                    />
                  </div>

                  <div className="flex gap-5">
                    <InputField
                      variant="auth"
                      extra="mb-2 w-full"
                      label="Country"
                      placeholder="USA"
                      id="country"
                      type="text"
                      disabled={viewMode}
                      value={formValues?.country}
                      state={formValuesErr?.country ? "error" : ""}
                      onChange={handleValueChange}
                    />
                    <InputField
                      variant="auth"
                      extra="mb-2 w-full"
                      label="Postal Code"
                      placeholder="850125"
                      id="postal_code"
                      type="text"
                      disabled={viewMode}
                      value={formValues?.postal_code}
                      state={formValuesErr?.postal_code ? "error" : ""}
                      onChange={handleValueChange}
                    />
                  </div>

                  <TextArea
                    variant="auth"
                    extra="mb-2 w-full"
                    label="Description"
                    placeholder=""
                    height="50px"
                    id="description"
                    disabled={viewMode}
                    value={formValues?.description}
                    state={formValuesErr?.description ? "error" : ""}
                    onChange={handleValueChange}
                  />

                  <ClientPayment
                    Organization={paymentMethods}
                    slectedOrg={org}
                    assignPaymentMethod={checkedpaymentMethods}
                    handleSelectionChange={handleChildSelectionChange}
                    errorclass={multiselectError}
                    methodName={methodName}
                    viewMode={viewMode}
                    allGateways={allGateways}
                    formData = {formValues}
                  />
                </div>
            </ModalBody>
            <ModalFooter className="mt-[10px]">
              <div className="flex gap-2 px-[30px] py-[15px]">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-indigo-600 px-5 py-2 text-base font-medium text-white outline-none transition duration-200 hover:bg-indigo-500 activebg-indigo-500 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={viewMode || isLoading || !hasFormChanged()}
                  className={
                    viewMode
                      ? "linear rounded-xl bg-indigo-600 px-5 py-2 text-base font-medium text-white outline-none transition duration-200  dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                      : "linear update rounded-xl bg-indigo-600 px-5 py-2 text-base font-medium text-white outline-none transition duration-200  dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                  }
                >
                  {isLoading ? (
                    <DivLoader className="h-6 w-6 border-indigo-500" />
                  ) : (
                    <>{id ? "Update" : "Create"}</>
                  )}
                </button>
              </div>
            </ModalFooter>
          </Card>
        {/* </ModalContent> */}
      </Modal>
    </>
  );
};
export default CreateClientModal;
