import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay , ModalHeader , ModalFooter} from "@chakra-ui/modal";
// import { deleteOrganization } from "api/organizations";
import { deleteOrganization } from "api/graphQL_organizations";
import Card from "components/card/custom";
import DivLoader from "components/divloader/DivLoader";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { MdDelete, MdHighlightOff, MdWarning } from "react-icons/md";


const DeleteOrgModal = ({
  name,
  id,
  fetchOrganizations,
}: {
  name: string;
  id: string;
  fetchOrganizations: () => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isCloseModal, setIsCloseModal] =  useState<boolean>(false);

  const handleClose = () => {
    setIsCloseModal(true)
    setTimeout(() => {
      setIsCloseModal(false)
      onClose();
    }, 300);
  };

  const handleDelete = () => {
    setIsLoading(true);
    deleteOrganization(id)
      .then((data) => {
        console.log("check data of organization delete org graphql", {data})
        fetchOrganizations();
        toast.success("Organization deleted");
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ?? "Organization deletion failed"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <button
        className="text-red-500 outline-none dark:text-gray-200"
        title="Delete"
      >
        <MdDelete className="h-5 w-5 cursor-pointer" onClick={onOpen} />
      </button>
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
          <Card extra={`fixed top-0 right-0 h-full rounded-none max-w-[600px] sm:w-full flex flex-col justify-start !z-[1004] overflow-y-auto scrollbar-hide overflow-auto ${
              isOpen && !isCloseModal ? "card-enter" : "card-exit"
            }`}>
              <ModalHeader className=" flex py-[20px] mb-[20px] px-[30px] text-2xl font-bold bg-brand-500 text-white">
                <MdHighlightOff
                  className="absolute right-7 top-6 h-8 w-8 cursor-pointer text-white"
                  onClick={handleClose}
                />
                Delete Organization
              </ModalHeader> 
              <ModalBody className="p-4">
                <div className="p-4 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-800 rounded-md shadow-md">
                  <div className="flex items-center">
                    <MdWarning className="w-6 h-6 mr-2 text-yellow-600" />
                    <span className="">
                    This will delete the organization <strong>{name}</strong> which is irreversible action. Do you still want to continue?
                    </span>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="mt-[10px] p-4">
                <div className="mt-5 flex gap-2">
                  <button
                    onClick={handleClose}
                    className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleDelete}
                    className="linear rounded-xl bg-red-50 px-5 py-2 text-base font-medium text-red-600 outline-none transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                  >
                    {isLoading ? (
                      <DivLoader className="h-6 w-6 border-red-400" />
                    ) : (
                      "Delete"
                    )}
                  </button>
                </div>
              </ModalFooter>
          </Card>
      </Modal>
    </>
  );
};
export default DeleteOrgModal;
