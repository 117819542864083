import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import InputField from "components/fields/InputField";
import Card from "components/card";
import DivLoader from "components/divloader/DivLoader";
import React, { useState, useEffect, useContext } from "react";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { GatewayTypes } from "views/admin/clients/components/CreateClientGatewaysModal";
import { TruncateCopy } from "views/admin/organizations/components/CreateOrgModal";
import { PaymentRowObj } from "..";
import InfoModal from "./InfoModal";
import RefundModal from "./RefundModal";
import { convertToFloat } from "utils/formatNumber";
import { BsArrowDown, BsArrowUp, BsReceipt } from "react-icons/bs";
import { ClientContext } from "ClientProvider";
import { DownloadCSV } from "api/payouts";
import {  getStatusHistory } from "api/graphQL_payments";
import { toast } from "react-hot-toast";
import FilterModal from "./FilterModal";
import { FaDownload } from "react-icons/fa";
import ShortTruncateCopy from "components/common/ShortTruncateCopy";
import Searchbox from "components/fields/Searchbox";
import { getImage } from "utils/commonFunction";
import Pagination from "components/pagination";
import StatusRender from "./StatusRender";
import RetryModal from "./RetryModal";
import visa from "assets/svg/card_type/visa.svg";
import MasterCard from "assets/svg/card_type/mastercard.svg";
import Discover from "assets/svg/card_type/discover.svg";
import Amex from "assets/svg/card_type/amex.svg";
import MaestroCard from "assets/svg/card_type/maestro.svg";
import DinersClub from "assets/svg/card_type/dinersclub.svg";
import JCB from "assets/svg/card_type/jcb.svg";
import UnionPay from "assets/svg/card_type/unionpay.svg";
import ChangeStatusModal from "./StatusChangeModal";
import CustomToolTip from "components/tooltips";
import { RiArrowUpDownFill } from "react-icons/ri";
function PaymentTable(props: {
  tableData: any;
  fetchPayments: () => void;
  filterData: () => void;
  isLoading: boolean;
  page: number;
  setPage: any;
  totalpage: number;
  totalItems: number;
  currentPage: number;
  clients: any[];
  allGateways: any[];
  allCardType: any[];
  curretClient: string;
  setCurrentClient: any;
  pageSize: number;
  setPageSize: any;
  roleData: any;
  filter: any;
  setFilter: (e: any) => void;
  onValueChange: (value: string) => void;
}) {
  const {
    tableData,
    filterData,
    fetchPayments,
    page,
    clients,
    allGateways,
    allCardType,
    setPage,
    currentPage,
    totalpage,
    curretClient,
    setCurrentClient,
    pageSize,
    setPageSize,
    roleData,
    filter,
    setFilter,
    onValueChange,
  } = props;

  let defaultData = tableData;

  const [{ pageIndex }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  });

  const [paymentHistoryData, setPaymentHistoryData] = useState<any>([]);

  const { paymentGateway, setPaymentGateway, paymentType, setPaymentType , singleRoleData} =
    useContext(ClientContext);

  const [DownloadLoading, setDownloadLoading] = useState<boolean>(false);
    

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const method = roleData[0]?.payout?.value?.payout_show_method_name;
  const columns = [
    columnHelper.accessor("client", {
      id: "client",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          CLIENT NAME
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  {info.getValue()}
                </p>
              </div>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">DATE</p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue()}
              </p>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor("amount", {
      id: "amount",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          AMOUNT
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center">
                <p className="text-sm font-bold uppercase text-navy-700 dark:text-white">
                  {convertToFloat(info.getValue())}{" "}
                  {info?.row?.original?.currency}
                  {info?.row?.original?.converted_amount &&
                    info?.row?.original?.converted_currency && (
                      <span>
                        {` (${info?.row?.original?.converted_amount} ${info?.row?.original?.converted_currency})`}
                      </span>
                    )}
                </p>
              </div>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor("et_id", {
      id: "et_id_",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          TRANSACTION ID
        </p>
      ),
      cell: (info) => {
        return (
          <>
            <p className="text-sm font-bold uppercase text-navy-700 dark:text-white">
              {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
                <ShortTruncateCopy info={info?.getValue()} showCopy={true} />
              )}
            </p>
          </>
        );
      },
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          INTERNAL ID
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            <p className="text-sm font-bold uppercase text-navy-700 dark:text-white">
              {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
                <ShortTruncateCopy info={info?.getValue()} showCopy={true} />
              )}
            </p>
          </>
        );
      },
    }),
    columnHelper.accessor("gateway_id", {
      id: "gateway_id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          GATEWAY {method ? "NAME" : "HASH ID"}
        </p>
      ),
      cell: (info) => {
        let gateway_name = info?.cell?.row?.original?.gateway_name;
        return ( 
          <>
            <p className="text-sm font-bold uppercase text-navy-700 dark:text-white">
              {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
                <div title={info?.cell?.row?.original?.gateway_id}>
                  {!singleRoleData[0]?.payout?.value?.payout_show_method_name ? (
                    <ShortTruncateCopy
                      info={
                        info?.cell?.row?.original?.gateway_id
                          ? info?.cell?.row?.original?.gateway_id
                          : "-"
                      }
                      showCopy={true}
                    />
                  ) : (
                    <span className="flex items-center ">
                      <img
                        style={{ height: "auto", width: "15px" }}
                        className="h-auto w-20"
                        src={getImage(info?.cell?.row?.original?.gateway_name)}
                        alt="Img"
                      />
                      <p className="px-2">
                        {info?.cell?.row?.original?.gateway_name
                          ? info?.cell?.row?.original?.gateway_name
                          : "-"}
                      </p>
                    </span>
                  )}
                </div>
              )}
            </p>
          </>
        );
      },
      enableSorting: true, // ✅ Ensure sorting is enabled
      sortingFn: "alphanumeric", 
    }),
    columnHelper.accessor("meta_info.Descriptor", {
      id: "descriptor",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          DESCRIPTOR
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.row.original.meta_info?.Descriptor || "-"}
              </p>
            )}
          </>
        );
      },
      sortingFn: "alphanumeric",
      enableSorting: true,
    }),

    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          STATUS
        </p>
      ),
      cell: (info) => {
        // // console.log("infio")
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center gap-1.5 text-sm font-bold">
                
                {true ? (
                  <CustomToolTip
                    content={
                      info?.row?.original?.payment_history?.length > 0 ? (
                        info?.row?.original?.payment_history?.map(
                          (hisData: any, index: number) => {
                            let errorMessage = ""
                            if(info.getValue() == 'DECLINED' || info.getValue() == 'ERRORED' || info.getValue() == 'FAILED') {
                              const gatewayResponse = info?.row?.original?.gateway_response?.gateway_response;
                              const errorKeys = ["errorMessage", "message", "decline_reason","error_message"];
                              const findLastErrorMessage = (obj: any): string | null => {
                                let lastValue: string | null = null;
                              
                                const search = (data: any) => {
                                  if (!data || typeof data !== "object") return;
                              
                                  for (const key of Object.keys(data)) {
                                    if (errorKeys.includes(key) && key !== "_content") {
                                      lastValue = data[key]; // Store the last found error message
                                    }
                              
                                    // Recursively search inside nested objects
                                    if (typeof data[key] === "object") {
                                      search(data[key]);
                                    }
                              
                                    // Special handling for `_content`
                                    if (key === "_content" && typeof data[key] === "string") {
                                      try {
                                        const parsedContent = JSON.parse(data[key]);
                              
                                        // Check for `decline_reason` inside the parsed content
                                        if (parsedContent.decline_reason) {
                                          lastValue = parsedContent.decline_reason;
                                        } else {
                                          // If `decline_reason` is not found, check for other error keys
                                          for (const parsedKey of Object.keys(parsedContent)) {
                                            if (errorKeys.includes(parsedKey)) {
                                              lastValue = parsedContent[parsedKey];
                                            }
                                          }
                                        }
                                      } catch (error) {
                                        console.error("Failed to parse _content:", error);
                                      }
                                    }
                                  }
                                };
                              
                                search(obj);
                                return lastValue;
                              };
                              
                              
                              const lastError = findLastErrorMessage(gatewayResponse);
                              errorMessage = typeof lastError === "string" ? lastError : "";

                             
                            }
                            return (
                              <>
                                <Card className="m-2 shadow " key={index}>
                                  <div className="border-1 max-w-[70vh] border  border-white  p-3">
                                    {index == 0 && (
                                      <p className="">
                                        Created at {hisData?.created_at} by API
                                        call from [{hisData?.client}
                                        {" - "}
                                        {hisData?.ip}] with status{" "}
                                        {hisData?.status}

                                        {["DECLINED", "ERRORED", "FAILED"].includes(hisData?.status) && (
                                          <>
                                            <br />
                                            Reason : {errorMessage !== "" ? errorMessage : "Reason not mentioned by the gateway"}
                                          </>
                                        )}
                                      </p>
                                    )}

                                    <div className="">
                                      {hisData?.status == "CHARGEBACK" &&
                                        index != 0 && (
                                          <div className="">
                                            <p>
                                              Status change from{" "}
                                              {hisData?.previous_status} to{" "}
                                              {hisData?.status} at{" "}
                                              {hisData?.status_changed_date} by{" "}
                                              {hisData?.client
                                                ? `System Api Call ${
                                                    roleData?.[0]?.payment
                                                      ?.value
                                                      ?.payment_show_method_name
                                                      ? hisData?.gateway_name
                                                      : info?.cell?.row
                                                          ?.original
                                                          ?.gateway_id || ""
                                                  }`
                                                : `${
                                                    hisData?.first_name || ""
                                                  } ${
                                                    hisData?.last_name || ""
                                                  }`}
                                            </p>
                                            <p>
                                              ARN Number : {hisData?.arn || "-"}
                                            </p>
                                            <p>
                                              Charge Back Reason :{" "}
                                              {hisData?.reason || "-"}
                                            </p>
                                          </div>
                                        )}
                                      {hisData?.status != "CHARGEBACK" &&
                                        index != 0 && (
                                          <p className=" ">
                                            Status change from{" "}
                                            {hisData?.previous_status} to{" "}
                                            {hisData?.status} at{" "}
                                            {hisData?.status_changed_date} by{" "}
                                            {hisData?.client
                                              ? `System Api Call ${
                                                  roleData?.[0]?.payment?.value
                                                    ?.payment_show_method_name
                                                    ? hisData?.gateway_name
                                                    : info?.cell?.row?.original
                                                        ?.gateway_id || ""
                                                }`
                                              : `${hisData?.first_name || ""} ${
                                                  hisData?.last_name || ""
                                                }`}

                                                
                                              {["DECLINED", "ERRORED", "FAILED"].includes(hisData?.status) && (
                                                <>
                                                  <br />
                                                  Reason : {errorMessage !== "" ? errorMessage : "Reason not mentioned by the gateway"}
                                                </>
                                              )}
                                            {/* Status change from {hisData?.previous_status} to {hisData?.status} at {hisData?.status_changed_date} by {hisData?.client ? `System Api Call  ${hisData?.first_name || ""} ${hisData?.last_name || ""}` : ""} */}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </Card>
                              </>
                            );
                          }
                        )
                      ) : (
                        <span className="">No history available.</span>
                      )
                    }
                    trigger={
                      <button className="flex items-center gap-1.5">
                        <StatusRender
                          status={info?.row?.original?.status}
                          value={info.getValue()}
                        />
                      </button>
                    }
                    header={"Status History"}
                  />
                ) : (
                  <StatusRender
                    status={info?.row?.original?.status}
                    value={info.getValue()}
                  />
                )}
              </div>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor("et_id", {
      id: "et_id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          ACTION
        </p>
      ),
      cell: (info: any) => {
        let enRefund = allGateways?.find(
          (gateway) => gateway.id === info?.row?.original?.gateway_id
        );
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center">
                {roleData?.[0]?.payout?.value?.refund_payout_list && (
                  <RefundModal
                    info={info.row.original}
                    fetchPayments={fetchPayments}
                    enRefund={enRefund?.refund}
                    transactionID={info.row.original.id}
                    client_is_deleted={info?.row?.original?.client_is_deleted}
                  />
                )}
                {roleData?.[0]?.payout?.value
                  ?.view_payout_response_information && (
                  <InfoModal info={info.row.original} />
                )}
                {roleData?.[0]?.payout?.value?.view_payout_receipt && (
                <a
                  href={info.row.original?.receipt_url}
                  target="_blank"
                  aria-disabled={!info.row.original?.receipt_url}
                  className="aria-disabled:cursor-not-allowed aria-disabled:opacity-50"
                >
                  
                    <BsReceipt className={"h-5 w-5"} />
                 
                </a>
                 )}
                {roleData?.[0]?.payout?.value?.retry_payout_list && (
                  <RetryModal
                    info={info.row.original}
                    fetchPayments={fetchPayments}
                    enRefund={enRefund?.refund}
                    transactionID={info?.row?.original?.id}
                    client_is_deleted={info?.row?.original?.client_is_deleted}
                  />
                )}

                {roleData?.[0]?.payout?.value?.edit_payout_status && (
                  <ChangeStatusModal
                    info={info.row.original}
                    fetchPayments={fetchPayments}
                    enRefund={enRefund?.refund}
                    transactionID={info?.row?.original?.id}
                    client_is_deleted={info?.row?.original?.client_is_deleted}
                  />
                )}
              </div>
            )}
          </>
        );
      },
      enableSorting:false
    }),
  ]; // eslint-disable-next-line
  const columnsNonAction = [
    columnHelper.accessor("payment_id", {
      id: "payment_id",
      header: () => <p></p>,
      cell: ({ row }) => {
        return (
          <div className="flex h-12 w-12 items-center justify-center px-2">
            {row.getCanExpand() ? (
              <>
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: "pointer" },
                  }}
                >
                  {row.getIsExpanded() ? (
                    <MdKeyboardDoubleArrowDown className="h-5 w-5 text-indigo-500" />
                  ) : (
                    <MdKeyboardDoubleArrowRight className="h-5 w-5 text-indigo-500" />
                  )}
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor("client", {
      id: "client",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          CLIENT NAME
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  {info.getValue()}
                </p>
              </div>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">DATE</p>
      ),
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue()}
              </p>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor("amount", {
      id: "amount",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          AMOUNT
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center">
                <p className="text-sm font-bold uppercase text-navy-700 dark:text-white">
                  {convertToFloat(info.getValue())}{" "}
                  {info?.row?.original?.currency}
                </p>
              </div>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          INTERNAL ID
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            <p className="text-sm font-bold uppercase text-navy-700 dark:text-white">
              {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
                <ShortTruncateCopy info={info?.getValue()} showCopy={true} />
              )}
            </p>
          </>
        );
      },
    }),
    columnHelper.accessor("gateway_id", {
      id: "gateway_id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          GATEWAY {method ? "NAME" : "HASH ID"}
        </p>
      ),
      cell: (info) => {
        let gateway_name = info?.cell?.row?.original?.gateway_name;
        return ( 
          <>
            <p className="text-sm font-bold uppercase text-navy-700 dark:text-white">
              {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
                <div title={info?.cell?.row?.original?.gateway_id}>
                  {!singleRoleData[0]?.payout?.value?.payout_show_method_name ? (
                    <ShortTruncateCopy
                      info={
                        info?.cell?.row?.original?.gateway_id
                          ? info?.cell?.row?.original?.gateway_id
                          : "-"
                      }
                      showCopy={true}
                    />
                  ) : (
                    <span className="flex items-center ">
                      <img
                        style={{ height: "auto", width: "15px" }}
                        className="h-auto w-20"
                        src={getImage(info?.cell?.row?.original?.gateway_name)}
                        alt="Img"
                      />
                      <p className="px-2">
                        {info?.cell?.row?.original?.gateway_name
                          ? info?.cell?.row?.original?.gateway_name
                          : "-"}
                      </p>
                    </span>
                  )}
                </div>
              )}
            </p>
          </>
        );
      },
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          DESCRIPTOR
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-center text-sm font-bold text-navy-700 dark:text-white">
                {info?.cell?.row?.original?.meta_info?.Descriptor
                  ? info?.cell?.row?.original?.meta_info?.Descriptor
                  : "-"}
              </p>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          STATUS
        </p>
      ),
      cell: (info) => {
        // // console.log("infio")
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center gap-1.5 text-sm font-bold">
                
                {true ? (
                  <CustomToolTip
                    content={
                      info?.row?.original?.payment_history?.length > 0 ? (
                        info?.row?.original?.payment_history?.map(
                          (hisData: any, index: number) => {
                            let errorMessage = ""
                            if(info.getValue() == 'DECLINED' || info.getValue() == 'ERRORED' || info.getValue() == 'FAILED') {
                              const gatewayResponse = info?.row?.original?.gateway_response?.gateway_response;
                              const errorKeys = ["errorMessage", "message", "decline_reason","error_message"];
                              const findLastErrorMessage = (obj: any): string | null => {
                                let lastValue: string | null = null;
                              
                                const search = (data: any) => {
                                  if (!data || typeof data !== "object") return;
                              
                                  for (const key of Object.keys(data)) {
                                    if (errorKeys.includes(key) && key !== "_content") {
                                      lastValue = data[key]; // Store the last found error message
                                    }
                              
                                    // Recursively search inside nested objects
                                    if (typeof data[key] === "object") {
                                      search(data[key]);
                                    }
                              
                                    // Special handling for `_content`
                                    if (key === "_content" && typeof data[key] === "string") {
                                      try {
                                        const parsedContent = JSON.parse(data[key]);
                              
                                        // Check for `decline_reason` inside the parsed content
                                        if (parsedContent.decline_reason) {
                                          lastValue = parsedContent.decline_reason;
                                        } else {
                                          // If `decline_reason` is not found, check for other error keys
                                          for (const parsedKey of Object.keys(parsedContent)) {
                                            if (errorKeys.includes(parsedKey)) {
                                              lastValue = parsedContent[parsedKey];
                                            }
                                          }
                                        }
                                      } catch (error) {
                                        console.error("Failed to parse _content:", error);
                                      }
                                    }
                                  }
                                };
                              
                                search(obj);
                                return lastValue;
                              };
                              
                              
                              const lastError = findLastErrorMessage(gatewayResponse);
                              errorMessage = typeof lastError === "string" ? lastError : "";

                             
                            }
                            return (
                              <>
                                <Card className="m-2 shadow " key={index}>
                                  <div className="border-1 max-w-[70vh] border  border-white  p-3">
                                    {index == 0 && (
                                      <p className="">
                                        Created at {hisData?.created_at} by API
                                        call from [{hisData?.client}
                                        {" - "}
                                        {hisData?.ip}] with status{" "}
                                        {hisData?.status}

                                        {["DECLINED", "ERRORED", "FAILED"].includes(hisData?.status) && (
                                          <>
                                            <br />
                                            Reason : {errorMessage !== "" ? errorMessage : "Reason not mentioned by the gateway"}
                                          </>
                                        )}
                                      </p>
                                    )}

                                    <div className="">
                                      {hisData?.status == "CHARGEBACK" &&
                                        index != 0 && (
                                          <div className="">
                                            <p>
                                              Status change from{" "}
                                              {hisData?.previous_status} to{" "}
                                              {hisData?.status} at{" "}
                                              {hisData?.status_changed_date} by{" "}
                                              {hisData?.client
                                                ? `System Api Call ${
                                                    roleData?.[0]?.payment
                                                      ?.value
                                                      ?.payment_show_method_name
                                                      ? hisData?.gateway_name
                                                      : info?.cell?.row
                                                          ?.original
                                                          ?.gateway_id || ""
                                                  }`
                                                : `${
                                                    hisData?.first_name || ""
                                                  } ${
                                                    hisData?.last_name || ""
                                                  }`}
                                            </p>
                                            <p>
                                              ARN Number : {hisData?.arn || "-"}
                                            </p>
                                            <p>
                                              Charge Back Reason :{" "}
                                              {hisData?.reason || "-"}
                                            </p>
                                          </div>
                                        )}
                                      {hisData?.status != "CHARGEBACK" &&
                                        index != 0 && (
                                          <p className=" ">
                                            Status change from{" "}
                                            {hisData?.previous_status} to{" "}
                                            {hisData?.status} at{" "}
                                            {hisData?.status_changed_date} by{" "}
                                            {hisData?.client
                                              ? `System Api Call ${
                                                  roleData?.[0]?.payment?.value
                                                    ?.payment_show_method_name
                                                    ? hisData?.gateway_name
                                                    : info?.cell?.row?.original
                                                        ?.gateway_id || ""
                                                }`
                                              : `${hisData?.first_name || ""} ${
                                                  hisData?.last_name || ""
                                                }`}

                                                
                                              {["DECLINED", "ERRORED", "FAILED"].includes(hisData?.status) && (
                                                <>
                                                  <br />
                                                  Reason : {errorMessage !== "" ? errorMessage : "Reason not mentioned by the gateway"}
                                                </>
                                              )}
                                            {/* Status change from {hisData?.previous_status} to {hisData?.status} at {hisData?.status_changed_date} by {hisData?.client ? `System Api Call  ${hisData?.first_name || ""} ${hisData?.last_name || ""}` : ""} */}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </Card>
                              </>
                            );
                          }
                        )
                      ) : (
                        <span className="">No history available.</span>
                      )
                    }
                    trigger={
                      <button className="flex items-center gap-1.5">
                        <StatusRender
                          status={info?.row?.original?.status}
                          value={info.getValue()}
                        />
                      </button>
                    }
                    header={"Status History"}
                  />
                ) : (
                  <StatusRender
                    status={info?.row?.original?.status}
                    value={info.getValue()}
                  />
                )}
              </div>
            )}
          </>
        );
      },
    }),
  ];

  
  const [data, setData] = React.useState(() => [...defaultData]);
  const [selectedVal, setSelectedVal] = useState("");
  const [Loading, setLoading] = useState<boolean>(false);
  const [methodName, setMethodName] = useState<boolean>(false);
  React.useEffect(() => {
    let method = roleData[0]?.payout?.value?.payout_show_method_name;
    setMethodName(method);
    setData(tableData);
  }, [tableData]);

  const table = useReactTable({
    data,
    columns:
      roleData?.[0]?.payout?.value?.retry_payout_list ||
      roleData?.[0]?.payout?.value?.view_payout_receipt ||
      roleData?.[0]?.payout?.value?.edit_payout_status ||
      roleData?.[0]?.payout?.value?.view_payout_response_information ||
      roleData?.[0]?.payout?.value?.refund_payout_list
        ? columns
        : columnsNonAction,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getSubRows: (row) => row.all_data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    const fetchData = async (type: any) => {
      try {
      } catch (err) {}
    };

    if (paymentGateway === true) {
      fetchData("live"); // Make the API request for Live mode
    } else {
      fetchData("test"); // Make the API request for Test mode
    }
  }, [paymentGateway]);

  const handleSelectPayment = async (e: any) => {
    const newValue = e.target.value;
    setSelectedVal(newValue);
    setPaymentType(newValue);
  };

  const handleValueChange = async (e: string) => {
    onValueChange(e);
  };

  const handelOnDownloadCSV = () => {
      const paymentType = filter?.status;
      const client = filter?.client;
      const gateway = filter?.gateway;
      const payment_status = filter?.payment_status;
      const card_type = filter?.card_type;
      const start_date = filter?.dates?.[0];
      const end_date = filter?.dates?.[1];
      setDownloadLoading(true);
  
      DownloadCSV(
        paymentType,
        client,
        gateway,
        payment_status,
        start_date,
        end_date
      )
        .then((response) => {
          // if (!response.ok) {
          //   throw new Error(`Failed to download CSV. Status: ${response.status}`);
          // }
          const csvText = response;
          const blob = new Blob([csvText], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "generated_data.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          toast.success("Download Completed");
          setTimeout(() => {
            setDownloadLoading(false);
          }, 2000);
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message ??
              "Something went wrong while downloading CSV"
          );
          setTimeout(() => {
            setDownloadLoading(false);
          }, 2000);
        });
    };
  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6 mb-6"}>
      <header className="relative flex flex-wrap items-center justify-between pt-5">
        <div className="block w-full justify-between gap-5 md:flex lg:flex">
          <div className="flex items-center gap-5">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              All Payout
            </div>
          </div>
          <div className="flex flex-wrap justify-around gap-2 md:pe-[0px] lg:pe-[0px]">
            <button
              onClick={() => handelOnDownloadCSV()}
              disabled={DownloadLoading}
              className="my-1 flex w-[155px] items-center gap-2 rounded-full bg-indigo-600 px-3 py-2 text-white hover:bg-indigo-500 md:my-3"
            >
              <FaDownload className="w-[14px] " />
              <p>Download CSV</p>
            </button>
            <FilterModal
              clients={clients}
              allGateways={allGateways}
              allCardType={allCardType}
              paymentType={paymentType}
              curretClient={curretClient}
              handleSelectPayment={handleSelectPayment}
              setPage={setPage}
              setCurrentClient={setCurrentClient}
              filter={filter}
              setFilter={setFilter}
              filterData={filterData}
              roleData={roleData}
            />
            <Searchbox onSearch={handleValueChange} />
          </div>
        </div>
      </header>

      <div className="relative mt-4 overflow-x-auto overflow-x-auto shadow-md scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 sm:rounded-lg">
        {props.isLoading ? (
          <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
        ) : (
          <table className="w-full w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header, pIndex) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                      >
                       <div className="flex items-center text-xs text-gray-200">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort() && ( // Only show if sorting is enabled
                          <span className="ml-2">
                            {header.column.getIsSorted() === "asc" ? (
                              <BsArrowUp size={14} className="text-gray-900" />
                            ) : header.column.getIsSorted() === "desc" ? (
                              <BsArrowDown size={14} className="text-gray-900" />
                            ) : (
                              <RiArrowUpDownFill size={14} className="text-gray-900" />
                            )}
                          </span>
                        )}
                      </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows?.length > 0 ? (
                table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      className={`border-b hover:bg-gray-50 dark:border-gray-700  dark:bg-gray-800 ${
                        row.getIsExpanded() ? "bg-gray-100" : ""
                      } ${!row.getCanExpand() ? "bg-gray-100" : ""} `}
                    >
                      {row.getVisibleCells().map((cell, cIndex) => {
                        return (
                          <td
                            key={cell.id}
                            className={`border-white/0 py-3  pr-4 ${
                              cell.column.id === "payment_id"
                                ? "min-w-[30px]"
                                : "min-w-[150px]"
                            }
                              
                            `}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <p className="p-4 text-center">No records found.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      <Pagination
        setPage={setPage}
        page={page}
        totalpage={totalpage}
        currentPage={currentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        arraySize={[50, 100, 200]}
      />
    </Card>
  );
}

export default PaymentTable;
const columnHelper = createColumnHelper<PaymentRowObj>();
