// import { getAllOrganizations, getAllOrganizationsUser, } from "api/organizations";
import { getAllOrganizationsUser, } from "api/organizations";
import { getAllOrganizations } from "api/graphQL_organizations";
import { useEffect, useState, useContext, useRef } from "react";
import toast from "react-hot-toast";
import CreateOrgModal from "./components/CreateOrgModal";
import OrgTable from "./components/OrganizaionsList";
// import { getSingleRole } from "api/role";
import { getSingleRole } from "api/graphQL_role";
import { getId } from "utils/auth";
// import { getAllGateways } from "api/gateways";
import { getAllGateways } from "api/graphQL_gateways";
import { ClientContext } from "ClientProvider";
import { useLocation, useNavigate } from "react-router-dom";


export type OrgRowObj = {
  name: string;
  email: string;
  phone: string;
  address: string;
  description: string;
  website: string;
  created_at: string;
  id: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  payment_methods?: string[];
  gateways?: [];
  clients?: [];
};

export type AllGatewayObj = {
  name: string;
  id: string;
};

const Organizations = () => {
  const [organizations, setOrganizations] = useState<OrgRowObj[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isDataLoading, setIsDataLoading] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [totalpage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [allGateways, setAllGateways] = useState<AllGatewayObj[]>([]);
  const { singleRoleData, setSingleRoleData } = useContext(ClientContext);
  const [searchTexts, setSearchTexts] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();
  
  const prevValues = useRef<{  searchTexts: string }>({
    searchTexts: "__init__", 
  });

  const fetchOrganizations = () => {
    setIsDataLoading(true);
    if (pageSize === null || pageSize === undefined) {
      setIsDataLoading(false);
      return;
    }

    let finalSearchText = ""
    if(location.state) {
      if (location.state?.search) {
        finalSearchText = location.state.search
        const searchTexts = location.state.search
        prevValues.current = { searchTexts };
        navigate(".", { replace: true, state: null }); 
      }
    } else {
        finalSearchText = searchTexts;
    }
    // getAllOrganizationsUser()
    getAllOrganizations(
      page,
      pageSize,
      finalSearchText,
    )
      .then((data) => {
        console.log("check data of all fetchorganization func organization users graphql", {data})
        setOrganizations(
          data?.[0]?.map((ele: any) => {
            return { ...ele, id: ele.id };
          })
        );

        setTotalPage(data?.[1]?.total_pages);
        setTotalItems(data?.[1]?.total_items);
        setCurrentPage(data?.[1]?.current_page);
      })
      .catch((err) => {
        setOrganizations([])
        // toast.error(
        //   err?.response?.data?.message ??
        //   "Something went wrong while fetching organizations"
        // );
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  };

  const fetchAllGateways = () => {
    
    getAllGateways()
      .then((data) => {
        console.log("graphql called organization fetch all gateways", data)
        setAllGateways(data?.[0] ? data : []);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
          "Something went wrong while fetching AllGateways"
        );
      })
      .finally(() => {
       
      });
  };


  useEffect(() => {
    const hasChanged = prevValues.current.searchTexts !== searchTexts;
    if (!hasChanged) {
      return;
    }
    fetchOrganizations();
  }, [page,searchTexts]);

  useEffect(() => {
    fetchAllGateways();
  }, []);

  const serchboxValueChange = async (searchTerm: string) => {
    const search_txt = searchTerm || "";

    if (search_txt !== searchTexts && searchTexts !== "") {
      setSearchTexts(search_txt);
      setPage(1);
      setCurrentPage(1);
    } else {
      setSearchTexts(search_txt);
    }

  }
  return (
    <>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <CreateOrgModal
          fetchOrganizations={fetchOrganizations}
          allGateways={allGateways?.filter((f: any) => f?.is_active)}
          roleData={singleRoleData}
        />
      </div>
      <div className="mt-5">
        <OrgTable
          tableData={organizations}
          currentPage={currentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          setPage={setPage}
          totalItems={totalItems}
          totalpage={totalpage}
          fetchOrganizations={fetchOrganizations}
          allGateways={allGateways?.filter((f: any) => f?.is_active)}
          isLoading={isDataLoading}
          roleData={singleRoleData}
          onValueChange={serchboxValueChange}
        />
      </div>
    </>
  );
};

export default Organizations;
