import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card";
import DivLoader from "components/divloader/DivLoader";
import React from "react";
import CreateUserModal from "./CreateUserModal";
import DeleteUserModal from "./DeleteUserModal";
import { UserRowObj } from "..";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import Searchbox from "components/fields/Searchbox";
import Pagination from "components/pagination";
import CustomToolTip from "components/tooltips";
import { useNavigate } from "react-router-dom";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { RiArrowUpDownFill } from "react-icons/ri";

function UsersTable(props: {
  tableData: any;
  fetchUsers: () => void;
  isLoading: boolean;
  page: number;
  setPage: any;
  totalpage: number;
  totalItems: number;
  currentPage: number;
  pageSize: number;
  setPageSize: any;
  organizations: any;
  roleData: any;
  roleDataDrop?: any;
  onValueChange: (value: string) => void;
}) {
  const {
    tableData,
    fetchUsers,
    page,
    setPage,
    currentPage,
    totalpage,
    pageSize,
    setPageSize,
    organizations,
    roleData,
    roleDataDrop,
    onValueChange,
  } = props;
  let defaultData = tableData;
  const [{ pageIndex }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const navigate = useNavigate();

  const columns = [
    columnHelper.accessor("first_name", {
      id: "first_name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          FIRST NAME
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("last_name", {
      id: "last_name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          LAST NAME
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">EMAIL</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          <a href={`mailto:${info.getValue()}`}> {info.getValue()}</a>
        </p>
      ),
    }),
    columnHelper.accessor("role", {
      id: "role",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">ROLE</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("allOrganizations", {
      id: "allOrganizations",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">Assigned Organizations</p>
      ),
      cell: (info) => {
        const orgList: any = info.row.original.allOrganizations || [];
        const orgCount = orgList.length;
      
        return (
          <div className="text-navy-700 font-bold">
            <CustomToolTip  
              content={
                orgCount > 0 ? (
                  <div className="flex flex-wrap gap-2 max-w-[200px]">  
                    {orgList.map((org: any, index: any) => (
                      <span 
                        key={index} 
                        className="bg-gray-200 text-gray-800 px-2 py-1 rounded text-xs font-medium cursor-pointer"
                        onClick={() => navigate("/admin/organizations", { state: { search: org.name } })}
                      >
                        {org.name}
                      </span>
                    ))}
                  </div>
                ) : (
                  <span className="text-gray-500 text-xs">No Organizations</span>
                )
              }
              trigger={orgCount}
              header="Assigned Organizations"
            />
          </div>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const gatewaysA = rowA.getValue(columnId) as Record<string, boolean>; // Explicit type
        const gatewaysB = rowB.getValue(columnId) as Record<string, boolean>;
      
        const aCount = Object.keys(gatewaysA || {}).filter((key) => gatewaysA[key]).length;
        const bCount = Object.keys(gatewaysB || {}).filter((key) => gatewaysB[key]).length;
      
        return aCount - bCount; // Sort numerically by gateway count
      },
      enableSorting: true,         
    }),
    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          CREATED AT
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
      sortingFn: (rowA, rowB) => {
        return (
          new Date(rowA.original.created_at).getTime() -
          new Date(rowB.original.created_at).getTime()
        );
      },
      enableSorting: true,
    }),
    columnHelper.accessor("auth_2fa", {
      id: "auth_2fa",
      header: () => (
        <p className="ml-9 text-sm font-bold text-gray-900 dark:text-white">
          2FA
        </p>
      ),
      cell: (info) =>
        info.getValue() ? (
          <MdCheckCircle className="ml-9 h-5 w-5 text-teal-500" />
        ) : (
          <MdCancel className="ml-9 h-5 w-5 text-red-500" />
        ),
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          ACTION
        </p>
      ),
      cell: (info) => (
        <p className="flex items-center gap-3 text-sm font-bold">
          <CreateUserModal
            fetchUsers={fetchUsers}
            id={info.getValue()}
            data={info.row?.original}
            organizations={organizations}
            roleDataDrop={roleDataDrop}
            roleData={roleData}
            infoData={info}
          />
          <CreateUserModal
            fetchUsers={fetchUsers}
            id={info.getValue()}
            is_info={true}
            data={info.row?.original}
            organizations={organizations}
            roleDataDrop={roleDataDrop}
            roleData={roleData}
            infoData={info}
          />
          {roleData?.[0]?.user?.value?.delete_user && (
            <DeleteUserModal
              fetchUsers={fetchUsers}
              id={info.getValue()}
              name={
                info?.row?.original?.first_name +
                  " " +
                  info?.row?.original?.last_name || ""
              }
            />
          )}
        </p>
      ),
      enableSorting : false,
    }),
  ]; // eslint-disable-next-line
  const columnsNonAction = [
    columnHelper.accessor("first_name", {
      id: "first_name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          FIRST NAME
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("last_name", {
      id: "last_name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          LAST NAME
        </p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">EMAIL</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          <a href={`mailto:${info.getValue()}`}> {info.getValue()}</a>
        </p>
      ),
    }),
    columnHelper.accessor("role", {
      id: "role",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">ROLE</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("allOrganizations", {
      id: "allOrganizations",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">Assigned Organizations</p>
      ),
      cell: (info) => {
        const orgList: any = info.row.original.allOrganizations || [];
        const orgCount = orgList.length;
      
        return (
          <div className="text-navy-700 font-bold">
            <CustomToolTip  
              content={
                orgCount > 0 ? (
                  <div className="flex flex-wrap gap-2 max-w-[200px]">  
                    {orgList.map((org: any, index: any) => (
                      <span 
                        key={index} 
                        className="bg-gray-200 text-gray-800 px-2 py-1 rounded text-xs font-medium cursor-pointer"
                        onClick={() => navigate("/admin/organizations", { state: { search: org.name } })}
                      >
                        {org.name}
                      </span>
                    ))}
                  </div>
                ) : (
                  <span className="text-gray-500 text-xs">No Organizations</span>
                )
              }
              trigger={orgCount}
              header="Assigned Organizations"
            />
          </div>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const gatewaysA = rowA.getValue(columnId) as Record<string, boolean>; // Explicit type
        const gatewaysB = rowB.getValue(columnId) as Record<string, boolean>;
      
        const aCount = Object.keys(gatewaysA || {}).filter((key) => gatewaysA[key]).length;
        const bCount = Object.keys(gatewaysB || {}).filter((key) => gatewaysB[key]).length;
      
        return aCount - bCount; // Sort numerically by gateway count
      },
      enableSorting: true,         
    }),
    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          CREATED AT
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
      sortingFn: (rowA, rowB) => {
        return (
          new Date(rowA.original.created_at).getTime() -
          new Date(rowB.original.created_at).getTime()
        );
      },
      enableSorting: true,
    }),
    columnHelper.accessor("auth_2fa", {
      id: "auth_2fa",
      header: () => (
        <p className="ml-9 text-sm font-bold text-gray-900 dark:text-white">
          2FA
        </p>
      ),
      cell: (info) =>
        info.getValue() ? (
          <MdCheckCircle className="ml-9 h-5 w-5 text-teal-500" />
        ) : (
          <MdCancel className="ml-9 h-5 w-5 text-red-500" />
        ),
    }),
  ];

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [searchVal, setSearchVal] = React.useState<any>("");
  const [data, setData] = React.useState(() => [...defaultData]);

  React.useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const table = useReactTable({
    data,
    columns:
      roleData?.[0]?.user?.value?.delete_user ||
      roleData?.[0]?.user?.value?.edit_user ||
      roleData?.[0]?.user?.value?.view_user
        ? columns
        : columnsNonAction,

    state: {
      pagination,
      sorting,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const handleValueChange = (e: any) => {
    onValueChange(e);
    setSearchVal(e);
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex flex-wrap items-center justify-between pt-5">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          All User
        </div>
        <Searchbox onSearch={handleValueChange} />
      </header>

      <div className="relative mt-4 overflow-x-auto overflow-x-auto shadow-md scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 sm:rounded-lg">
        {props.isLoading ? (
          <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
        ) : (
          <table className="w-full w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-200 p-2 pb-2 pr-4 pt-4 text-start"
                      >
                        <div className="flex items-center text-xs text-gray-200">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.getCanSort() && ( // Only show if sorting is enabled
                            <span className="ml-2">
                              {header.column.getIsSorted() === "asc" ? (
                                <BsArrowUp size={14} className="text-gray-900" />
                              ) : header.column.getIsSorted() === "desc" ? (
                                <BsArrowDown size={14} className="text-gray-900" />
                              ) : (
                                <RiArrowUpDownFill size={14} className="text-gray-900" />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows?.length > 0 ? (
                table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      className="border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[150px] border-white/0 p-2  py-3 pr-4"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <p className="p-4 text-center">No records found.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      <Pagination
        setPage={setPage}
        page={page}
        totalpage={totalpage}
        currentPage={currentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        arraySize={[50, 100, 200]}
      />
    </Card>
  );
}

export default UsersTable;
const columnHelper = createColumnHelper<UserRowObj>();
