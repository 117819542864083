import { useDisclosure } from "@chakra-ui/hooks"

import Card from "components/card";
import { Container, Row, Col } from "react-bootstrap"
import InputField from "components/fields/InputField";
import Radio from "components/radio";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Stack,
  Text,
  VStack,
  Progress,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Modal,
  background,
  ModalCloseButton
} from '@chakra-ui/react';

import DivLoader from "components/divloader/DivLoader";
import { useEffect, useState, useContext, useMemo } from "react";
import { MdCircle, MdIntegrationInstructions, MdShoppingCartCheckout } from "react-icons/md";

import "primereact/resources/themes/lara-light-cyan/theme.css";
import './OrderSummary.css'; // Add your CSS file here
import { CirclePicker, BlockPicker , ChromePicker  } from "react-color";
import { AiFillCheckCircle, AiFillCloseCircle, AiFillExclamationCircle, AiOutlineLeft } from "react-icons/ai";
import { BsLockFill } from "react-icons/bs";

import bm2payLogo from "../../../../assets/img/bm2pay.svg";
import lightningChecksLogo  from "../../../../assets/img/mainlogo.png";
import paxumLogo  from "../../../../assets/img/paxum.png";
import sampleImage  from "../../../../assets/img/payment-clients/placeholder.png";

import { Select as AntSelect } from "antd";
import { getCurrencySymbol, currencyCodes } from "utils/currencyList"; 
import toast from "react-hot-toast";

import { createUpdateCheckout, generateCheckoutLink, getCheckOutDetails, updateCheckoutStatus } from "api/clients";
import ShortTruncateCopy from "components/common/PaymentLinkCopy";
const smart_routingimg = require("../../../../assets/svg/smart_routing.svg");

let mainlogo: string = bm2payLogo;

if (window.location.hostname === "gateway-dev.lightningchecks.com" || window.location.hostname === "gateway.lightningchecks.com" || window.location.hostname === "testing.lightningchecks.com") {
  mainlogo = lightningChecksLogo;
} else if (window.location.hostname === "gateway-dev.bm2pay.com" || window.location.hostname === "gateway.bm2pay.com" || window.location.hostname === "gateway-stagging.bm2pay.com") {
  mainlogo = bm2payLogo;
} else if (window.location.hostname === "pay.paxum.com") {
  mainlogo = paxumLogo;
} 


interface Product {
  name: string;
  quantity: number;
  price: number | null;
}

const CheckoutProductClientModal = ({
  id,
  fetchClients,
  assigned_payment_methods,
  infoData,
}: {
  id: string;
  fetchClients: () => void;
  assigned_payment_methods?: any;
  infoData?: any;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>();
  
  const [cardNumber, setCardNumber] = useState('1234 1234 1234 1234');
  const [expiryDate, setExpiryDate] = useState("12/25");
  const [cvv, setCVV] = useState('123');
  const [cardholderName, setCardholderName] = useState('Card Holder Name');
  
  const [clientData, setClientData]= useState(infoData.row.original);
  const [brandColor, setBrandColor] = useState('rgba(32, 32, 228, 1)');
  const [isCustom, setIsCustom] = useState(false);
  const [style, setStyle] = useState("rounded");
  const [selectedFont, setSelectedFont] = useState("'Poppins'");
  const [showPreview,setShowPreview] = useState(true);
  const [successPreview,setSuccessPreview] = useState(false);
  const [pendingPreview,setPendingPreview] = useState(false);
  const [failedPreview,setFailedPreview] = useState(false);
  const [isCheckoutEnabled,setIsCheckoutEnabled] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string>("USD");
  const [amount, setAmount] = useState(null);
  const [paymentLink, setPaymentLink] = useState("");
  const [checkoutId, setCheckoutId] = useState("");
  const [targetDateTime, setTargetDateTime] = useState(null);
  const [businessName,setBusinessName] = useState("Business Name");
  const [imagePreview, setImagePreview] = useState(sampleImage);
  const [base64Image, setBase64Image] = useState("");
  const [checkOutStatus,setCheckOutStatus] = useState(infoData.row.original.is_checkout_enabled || false);

  const handleClose = () => {
    onClose();
    if(!clientData?.checkout_id) {
      setBusinessName("Business Name")
      setImagePreview(sampleImage)
      setBase64Image("")
      setCheckOutStatus(false)
      setSelectedFont("")
      setShowPreview(true)
      setSuccessPreview(false)
      setPendingPreview(false)
      setFailedPreview(false)
      setIsCheckoutEnabled(false)
      setBrandColor('#2020e4')
      setIsCustom(false)
    }
  };

  // State for products and total
  const [products, setProducts] = useState<Product[]>([
    { name: '', quantity: 1, price: 0.0 },
    { name: '', quantity: 1, price: 0.0 },
  ]);

  const [total, setTotal] = useState(
    products.reduce((acc, product) => acc + (product.price ?? 0) * product.quantity, 0)
  );

  // Recalculate total when products change
  useEffect(() => {
    setTotal(products.reduce((acc, product) => acc + (product.price ?? 0) * product.quantity, 0));
  }, [products]);

  useEffect(() => {
    if(clientData?.checkout_id) {
      getCheckOutDetails(clientData?.checkout_id,clientData?.client_id,clientData?.client_secret)
        .then((checkoutData) => {
            const responseData = checkoutData?.data;
            const targetDateFromAPI = new Date(responseData?.expires_at);
            if (responseData?.brand_color) {
                setBrandColor(responseData.brand_color);
            }
            if (responseData?.brand_style) {
                setStyle(responseData.brand_style);
            }
            if (responseData?.brand_font) {
                setSelectedFont(responseData.brand_font);
            }
            if (responseData?.currency) {
                setSelectedCurrency(responseData.currency);
            }
            if (responseData?.amount) {
              setAmount(responseData?.amount)
            }
            if (responseData?.business_name) {
                setBusinessName(responseData.business_name);
            }
            if (responseData?.business_logo) {
                setImagePreview("data:image/jpeg;base64,"+responseData.business_logo);
                setBase64Image(responseData.business_logo);
            }
            setIsCheckoutEnabled(responseData.is_checkout_enabled);

            if(responseData.is_checkout_enabled) {
              setCheckOutStatus(true);
            }
            
            setTargetDateTime(targetDateFromAPI)
          
        })
        .catch((error) => {
            console.error("Error generating new link:", error);
        });
    }
  },[clientData?.checkout_id])

  const [currentActive, setCurrentActive] = useState(1);

  const tabs = [
    // { id: 1, label: "Payment Types" },
    { id: 1, label: "Customize" },
    { id: 2, label: "Preview" },
  ];

  const nextTab = () => {
    setCurrentActive((prev) => Math.min(prev + 1, tabs.length));
    setShowPreview(true);
    setSuccessPreview(false);
    setPendingPreview(false);
    setFailedPreview(false);
  };

  const prevTab = () => {
    setCurrentActive((prev) => Math.max(prev - 1, 1));
    // setSelectedFont("'Poppins'")
    // setStyle('rounded')
  };

  const progressWidth = ((currentActive - 1) / (tabs.length - 1)) * 90;


  const [step, setStep] = useState(1);

  const nextStep = () => {
    if (step < 3) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleCardNumberChange = (e : any) => {
    let input = e.target.value.replace(/\D/g, "");
    input = input.slice(0, 16);
    const formattedInput = input.replace(/(.{4})/g, "$1 ").trim();
    setCardNumber(formattedInput);
  };

  const handleExpiryDateChange = (e : any) => {
    let input = e.target.value.replace(/\D/g, "");
    input = input.slice(0, 4);
    if (input.length > 2) {
      input = `${input.slice(0, 2)} / ${input.slice(2)}`;
    }
    setExpiryDate(input);
  };

  const handleCvvChange = (e : any) => {
    let input = e.target.value.replace(/\D/g, "");
    input = input.slice(0, 3);
    setCVV(input);
  };

  const handleChangeComplete = (color: any) => {
    // setBrandColor(color.hex);
    setBrandColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
  };
  const toggleCustomPicker = (event: any) => {
    event.preventDefault();
    setIsCustom((prev) => !prev);
  };

  const handleStyleChange = (event :any) => {
    setStyle(event.target.value);
  };

  const handleFontChange = (event :any) => {
    setSelectedFont(event.target.value);
  };

  const getBorderRadiusClass = () => {
    if (style === "sharp") {
      return "rounded-none";
    } else if (style === "pill") {
      return "rounded-full";
    } else {
      return "rounded";
    }
  };

  const handleFormSubmit = () => {
    const payload = {
      "brand_color" : brandColor,
      "brand_style" : style,
      "brand_font" : selectedFont,
      "status" : isCheckoutEnabled,
      "business_name" : businessName,
      "business_logo" : base64Image,
    }

    setIsLoading(true);

    createUpdateCheckout(payload,clientData.client_id,clientData.client_secret)
    .then((response) => {
      
      toast.success(response?.message);
      setCheckoutId(response?.data?.id);
      handleClose();
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }).catch((err) => {
      console.log("Error",err);
      toast.error(
        err?.response?.data?.message ??
          "Something went wrong while cancelling subscription"
      );
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    });
    
  }

  const successPayment = () => {
    setShowPreview(false);
    setSuccessPreview(true);
    setPendingPreview(false);
    setFailedPreview(false);
  }

  const pendingPayment = () => {
    setShowPreview(false);
    setSuccessPreview(false);
    setPendingPreview(true);
    setFailedPreview(false);
  }

  const failedPayment = () => {
    setShowPreview(false);
    setSuccessPreview(false);
    setPendingPreview(false);
    setFailedPreview(true);
  }

  const handlePreview = () => {
    setShowPreview(true);
    setSuccessPreview(false);
    setPendingPreview(false);
    setFailedPreview(false);
  }

  const handleToggle = () => {
    setIsCheckoutEnabled(!isCheckoutEnabled);
    const payload = {
      "status" : !isCheckoutEnabled
    }

    setIsLoading(true);

    updateCheckoutStatus(payload,clientData.client_id,clientData.client_secret)
    .then((response) => {
      setCheckOutStatus(!checkOutStatus);
      // toast.success(response?.message);
      // handleClose();
    })
    .catch((error) => {
      console.error("Error generating new link:", error);
  });
  };

  const handleBusinessChange = (event:any) => {
    setBusinessName(event.target.value);
  }

  const handleFileChange = (e:any) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
      if (allowedTypes.includes(file.type)) {
       
        const reader : any = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setImagePreview(reader.result); // Preview Image
          setBase64Image(reader.result.split(",")[1]); // Store Base64 (without prefix)
        };
      } else {
        toast.error("Only JPEG, JPG, PNG, and GIF files are allowed.");
        e.target.value = ""; // Clear the file input
        setImagePreview(null);
      }
    }
  };

  return (
    <>
      <button className="text-blue-500 outline-none" title="Checkout">
        <MdShoppingCartCheckout
          className="h-5 w-5 cursor-pointer"
          onClick={() => {
            onOpen();
          }}
        />
      </button>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="sm:max-w-xxl z-[1002] !m-auto flex min-h-[70vh] sm:min-h-[100vh] overflow-auto rounded-[20px] max-w-[800px] !p-3 sm:my-8 sm:w-full">          
          <ModalBody className=" overflow-auto sm:max-h-[100vh] scrollbarhide rounded-[20px]">
            <Card extra=" max-w-[800px] px-[30px] pt-[35px] pb-[35px] flex flex-col !z-[1004] ">
              <div className="flex justify-between items-center mb-2">
                <h2>Checkout </h2>
                <div className="flex justify-end p-2 ">
                  <label className="relative mb-3 inline-flex cursor-pointer items-center">
                    <input
                      type="checkbox"
                      className="peer sr-only"
                      //@ts-ignore
                      checked={isCheckoutEnabled}
                      onChange={handleToggle}
                    />
                    <div className="peer h-6 w-11 rounded-full bg-gray-400 after:absolute after:left-[2px] after:top-[4px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-indigo-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-400 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-indigo-800" />
                    <span className="ml-3 text-lg font-medium text-gray-900 dark:text-gray-300">
                    {isCheckoutEnabled ? 'Enabled' : 'Disabled'}
                    </span>
                  </label>
                </div>
              </div>
              {checkOutStatus ? (
                <>
                  <div className="h-[70vh] overflow-auto">
                    <div 
                    className='container my-4 w-75 position-relative custome-counter' 
                    style={{ backgroundColor: brandColor }}
                    >
                      <div className="row justify-content-center">
                        <div className="col-md-6 col-12">
                          <div className="progress-container" style={{marginBottom:'15px'}}>
                            <div
                              className="progress"
                              id="progress"
                              style={{ width: `${progressWidth}%` }}
                            ></div>
                            <div className="steps">
                              {tabs.map((tab) => (
                                <div
                                  key={tab.id}
                                  className={`step ${currentActive === tab.id ? "active" : ""}`}
                                >
                                  <div className="circle">{tab.id}</div>
                                  <div className="label">{tab.label}</div>
                                </div>
                              ))}
                            </div>
                            
                          </div>
                          {paymentLink !== '' && (
                            <></>
                            // <div className="w-full py-2 px-4 flex items-center justify-between" style={{ color: '#ffffff' }}>
                            //   {isExpired ? (
                            //     <div className="flex items-center gap-x-2">
                            //       <span>Generating new link.....</span>
                            //        <button
                            //           className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200  active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                            //           onClick={generateNewLink}
                            //       > 
                            //           Generate New Link
                            //       </button>
                            //   </div>
                            // ) : (
                            //   <>
                            //   <div className="flex items-center gap-x-2">
                            //     <span>Payment Link :</span>
                            //     <ShortTruncateCopy info={paymentLink} showCopy={true} />
                            //   </div>
                            //   <span>
                            //     {`Expires At : ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
                            //   </span>
                            //   </>
                            //   )}
                            // </div>
                            
                          )}
                        </div>
                      
                      </div>

                      <div className="row justify-content-center">
                        <div className="col-lg-8">
                          <div className="card w-100">
                            <div className="card-body w-100" style={{ background: "#fff", borderRadius: "12px", padding: "20px" }}>
                              {currentActive ===  1 && (
                                <>
                                  <h3>Brand Color & Style</h3>
                                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-2 mb-5">
                                    <div className="flex flex-col items-center">
                                      {!isCustom ? (
                                        <div>
                                          <CirclePicker
                                            color={brandColor}
                                            onChangeComplete={handleChangeComplete}
                                          />
                                          <button className="mt-4 px-4 py-2 bg-indigo-500 text-white rounded justify-center" onClick={toggleCustomPicker}>Want to choose custom color?</button>
                                        </div>
                                      ) : (
                                        <div className="relative">
                                          <button
                                            className="absolute top-2 left-[-35px] text-gray-700 hover:text-gray-900"
                                            onClick={toggleCustomPicker}
                                          >
                                            <AiOutlineLeft className="w-6 h-6" />
                                          </button>
                                          <ChromePicker 
                                            color={brandColor}
                                            onChangeComplete={handleChangeComplete}
                                          />
                                          {/* <button className="mt-4 px-4 py-2 bg-indigo-500 text-white rounded" onClick={toggleCustomPicker}>Back to Circle Picker</button> */}
                                        </div>
                                      )}
                                      
                                    </div>
                                    <div className="flex flex-col  space-y-4">
                                      <div className="flex flex-col ">
                                        <label htmlFor="style" className="mb-2">Style</label>
                                        <select id="style" className="border p-2 rounded" onChange={handleStyleChange}>
                                          <option value="rounded" selected={style == 'rounded'}>Rounded</option>
                                          <option value="sharp" selected={style == 'sharp'}>Sharp</option>
                                          <option value="pill" selected={style == 'pill'}>Pill</option>
                                        </select>
                                        <input
                                          type="text"
                                          className={`border-2 p-2 w-full ${getBorderRadiusClass()} mt-2 border-indigo-600 dark:!border-white/10 dark:text-white`}
                                          disabled
                                          value='Preview'
                                        />
                                      </div>

                                      <div className="flex flex-col ">
                                        <label htmlFor="font" className="mb-2">Font</label>
                                        <select id="font" className="border p-2 rounded" onChange={handleFontChange}>
                                        <option value="'Poppins'" selected={selectedFont == "'Poppins'"}>Poppins</option>
                                        <option value="'DM Sans'" selected={selectedFont == "'DM Sans'"}>DM Sans</option>
                                        <option value="'circular'" selected={selectedFont == "'circular'"}>circular</option>
                                        
                                        </select>
                                        <label style={{ fontFamily : selectedFont , fontSize: '18px' }} className="mt-2">Font Preview</label>
                                      </div>
                                    </div>
                                  </div>
                                  <h3>Client Information</h3>
                                  {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-2 mb-5"> */}
                                    <div className="flex flex-row items-end gap-4 w-full">
                                      <div className="flex flex-col w-1/2">
                                        <label htmlFor="business_name" className="mb-2">Business Name</label>
                                        <input
                                          type="text"
                                          id="business_name"
                                          className="border p-2 w-full rounded mt-2 border-gray-200 dark:!border-white/10 dark:text-white"
                                          value={businessName}
                                          onChange={handleBusinessChange}
                                          placeholder="Enter Business Name"
                                        />
                                      </div>
                                      <div className="flex flex-col  gap-2 w-1/2">
                                        <label htmlFor="business_logo" className="mb-2">
                                          Business Logo
                                        </label>
                                        <input
                                          type="file"
                                          id="business_logo"
                                          className="border p-2 w-full rounded mt-2 border-gray-200 dark:!border-white/10 dark:text-white"
                                          onChange={handleFileChange}
                                        />
                                      </div>
                                    </div>
                                  {/* </div> */}

                                </>
                              )}
                              {currentActive === 2 && (
                                <>
                                  {showPreview ? (
                                    <div className="flex flex-col lg:flex-row container mx-auto gap-10 border border-gray-300 shadow-md hover:shadow-lg transition-shadow">
                                      <div  className="w-full lg:w-1/2 border-r-2 p-5"  style={{ backgroundColor: brandColor , color : '#FFFFFF' }}>
                                      <div className="flex items-center gap-2">
                                        {imagePreview && (
                                          <img
                                            src={imagePreview}
                                            alt="Business Logo Preview"
                                            className="w-10 h-10 rounded-full border border-gray-300"
                                          />
                                        )}
                                        <h3
                                          className="text-xl font-semibold"
                                          style={{ fontFamily: selectedFont }}
                                        >
                                          {businessName}
                                        </h3>
                                      </div>
                                        <h2 className="text-3xl font-bold my-4" style={{ fontFamily : selectedFont }}>$ 32.00</h2>
                                      </div>
                                      <div className="w-full lg:w-1/2 py-5 pr-2">
                                        <h3 className="text-xl font-semibold mb-5" style={{ fontFamily : selectedFont }}>Pay with card</h3>
                                        <div className="flex flex-col">
                                          <label htmlFor="email" className="mb-2" style={{ fontFamily : selectedFont }}>Email</label>
                                          <input
                                            id="email"
                                            type="text"
                                            className={`border p-2 w-full ${getBorderRadiusClass()} border-gray-200 dark:!border-white/10 dark:text-white`}
                                            style={{ fontFamily : selectedFont }}
                                            placeholder="test@gmail.com"
                                            value={clientData?.email}
                                            disabled
                                          />
                                        </div>
                                        <div className="flex flex-col mt-5">
                                          <label htmlFor="phone" className="mb-2" style={{ fontFamily : selectedFont }}>Phone Number</label>
                                          <input
                                            id="phone"
                                            type="text"
                                            className={`border p-2 w-full ${getBorderRadiusClass()} border-gray-200 dark:!border-white/10 dark:text-white`}
                                            style={{ fontFamily : selectedFont }}
                                            placeholder="1234567890"
                                            value={clientData?.phone}
                                            disabled
                                          />
                                        </div>
                                        <div className="flex flex-col mt-5">
                                          <label htmlFor="card-info" className="mb-2" style={{ fontFamily: selectedFont }}>Card information</label>
                                          <div className=" flex flex-col gap-3">
                                            <div className="flex items-center">
                                              <input
                                              id="card-info"
                                              type="text"
                                              className={`border p-2 w-full ${getBorderRadiusClass()} border-gray-200 dark:!border-white/10 dark:text-white`}
                                              placeholder="Card Holder Name"
                                              value={cardholderName}
                                              disabled
                                              />
                                            </div>
                                            <div className="flex items-center">
                                              <input
                                              id="card-info"
                                              type="text"
                                              className={`border p-2 w-full ${getBorderRadiusClass()} border-gray-200 dark:!border-white/10 dark:text-white`}
                                              placeholder="1234 1234 1234 1234"
                                              value={cardNumber}
                                              onChange={handleCardNumberChange}
                                              disabled
                                              />
                                            </div>
                                            <div className="flex gap-2">
                                              <input
                                              type="text"
                                              className={`border p-2 w-full ${getBorderRadiusClass()} border-gray-200 dark:!border-white/10 dark:text-white`}
                                              placeholder="MM / YY"
                                              value={expiryDate}
                                              onChange={handleExpiryDateChange}
                                              disabled
                                              />
                                              <input
                                              type="text"
                                              className={`border p-2 w-full ${getBorderRadiusClass()} border-gray-200 dark:!border-white/10 dark:text-white`}
                                              placeholder="CVC"
                                              value={cvv}
                                              onChange={handleCvvChange}
                                              disabled
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex flex-col mt-5">
                                          <label htmlFor="card-info" className="mb-2" style={{ fontFamily: selectedFont }}>Address information</label>
                                          <div className=" flex flex-col gap-3">
                                            <div className="flex items-center">
                                              <input
                                              id="card-info"
                                              type="text"
                                              className={`border p-2 w-full ${getBorderRadiusClass()} border-gray-200 dark:!border-white/10 dark:text-white`}
                                              placeholder="Address"
                                              value='Your address'
                                              disabled
                                              />
                                            </div>
                                            <div className="flex gap-2">
                                              <input
                                              type="text"
                                              className={`border p-2 w-full ${getBorderRadiusClass()} border-gray-200 dark:!border-white/10 dark:text-white`}
                                              placeholder="MM / YY"
                                              value='Your state'
                                              disabled
                                              />
                                              <input
                                              type="text"
                                              className={`border p-2 w-full ${getBorderRadiusClass()} border-gray-200 dark:!border-white/10 dark:text-white`}
                                              placeholder="CVC"
                                              value='Your city'
                                              disabled
                                              />
                                            </div>
                                            <div className="flex gap-2">
                                              <select id="style" className={`border p-2 rounded ${getBorderRadiusClass()}`} disabled>
                                                <option value="canada" >Canada</option>
                                              </select>
                                              <input
                                              type="text"
                                              className={`border p-2 w-full ${getBorderRadiusClass()} border-gray-200 dark:!border-white/10 dark:text-white`}
                                              placeholder="CVC"
                                              value='Your postal code'
                                              disabled
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex flex-col mt-5">
                                          <button 
                                          className={`flex ${getBorderRadiusClass()} items-center justify-center gap-2 whitespace-nowrap p-3 text-navy-700 outline-none dark:text-gray-200`}
                                          style={{ backgroundColor: brandColor , color : '#FFFFFF' }}
                                          >
                                            <BsLockFill className="h-5 w-5" />
                                            <span> Pay Now</span>
                                          </button>
                                        </div>
                                        <div className="flex flex-row items-center justify-center mt-5 text-center gap-2">
                                          Powered by 
                                            <img
                                              style={{ height: "auto", width: "30px" }}
                                              src={mainlogo}
                                              alt="Gateway Image"
                                            />
                                        </div>
                                      </div>
                                    </div>
                                  ) : successPreview ? (
                                    <div className="container mx-auto flex h-[40vh] flex-col lg:flex-row gap-10 rounded-lg border border-gray-300 shadow-md hover:shadow-lg transition-shadow">
                                      <div
                                        className="flex flex-col items-center justify-center w-full p-5 rounded-lg"
                                        style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                                      >
                                        <AiFillCheckCircle className="w-20 h-20 mb-4" />
                                        <span className="text-xl font-semibold mb-4">Payment Success</span>
                                        <button
                                          className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200  active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                                          onClick={handlePreview}
                                        > 
                                        Back to preview
                                        </button>
                                      </div>
                                    </div>

                                  ) : pendingPreview ? (
                                    <div className="container mx-auto flex h-[40vh] flex-col lg:flex-row gap-10 rounded-lg border border-gray-300 shadow-md hover:shadow-lg transition-shadow">
                                      <div
                                        className="flex flex-col items-center justify-center w-full p-5 rounded-lg"
                                        style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                                      >
                                        <AiFillExclamationCircle  className="w-20 h-20 mb-4" />
                                        <span className="text-xl font-semibold mb-4">Payment Pending</span>
                                        <button
                                          className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200  active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                                          onClick={handlePreview}
                                        > 
                                        Back to preview
                                        </button>
                                      </div>
                                    </div>
                                  ) : failedPreview ? (
                                    <div className="container mx-auto flex h-[40vh] flex-col lg:flex-row gap-10 rounded-lg border border-gray-300 shadow-md hover:shadow-lg transition-shadow">
                                    <div
                                      className="flex flex-col items-center justify-center w-full p-5 rounded-lg"
                                      style={{ backgroundColor: brandColor, color: '#FFFFFF' }}
                                    >
                                      <AiFillCloseCircle  className="w-20 h-20 mb-4" />
                                      <span className="text-xl font-semibold mb-4">Payment Failed</span>
                                      <button
                                        className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200  active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                                        onClick={handlePreview}
                                      > 
                                      Back to preview
                                      </button>
                                    </div>
                                  </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 flex w-full items-center justify-between">
                
                    <div className="flex gap-2">
                      {currentActive !== 1 && (
                        <>
                        <button
                          id="prev"
                          onClick={prevTab}
                          disabled={currentActive === 1}
                          className="linear rounded-xl bg-gray-100 px-3 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                        >
                          Prev
                        </button>
                        
                        </>
                      )}
                      {currentActive === tabs.length ? (
                        <button
                          className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                          onClick={handleFormSubmit}
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          id="next"
                          onClick={nextTab}
                          className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                        >
                          Next
                        </button>
                      )}
                      <button
                        onClick={handleClose}
                        className="linear rounded-xl bg-red-50 px-5 py-2 text-base font-medium text-red-500 outline-none transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                      >
                        Close
                      </button>
                    </div>

                    <div className="flex gap-2">
                      {currentActive === tabs.length && (
                        <>
                          <button 
                            className="rounded-xl bg-green-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
                            onClick={successPayment}
                          >
                            Success
                          </button>
                          <button 
                            className="rounded-xl bg-red-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
                            onClick={failedPayment}
                          > 
                            Failed
                          </button>
                          <button 
                            className="rounded-xl bg-yellow-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-yellow-600 active:bg-yellow-700 dark:bg-yellow-400 dark:text-white dark:hover:bg-yellow-300 dark:active:bg-yellow-200"
                            onClick={pendingPayment}
                          >
                            Pending
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : ( 
                <>
                  <div className="h-[70vh] overflow-auto text-center">
                    <div className="flex flex-col justify-center items-center px-2 h-full">
                        <img
                          src={smart_routingimg.default}
                          alt="smart_routing_img"
                          className={`w-[300px] ${checkOutStatus}`}
                          loading="lazy"
                        />
                      <h3 className="text-[20px] mb-2">Looks like checkout is disabled! Enable it now to set up and configure your checkout settings with ease.</h3>
                      <button
                        onClick={onClose}
                        className="linear rounded-xl bg-red-50 px-5 py-2 text-base font-medium text-red-500 outline-none transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                      >
                        Close
                      </button>
                    </div>
                   
                  </div>
                </>
              )}
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal >

    </>
  );
};
export default CheckoutProductClientModal;