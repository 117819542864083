import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  createBlackListClient,
  getBlackListRecordById,
  updateBlackListRecord,
} from "api/blackListClient";
// import { createOrganization, getOrganization, updateOrganization, } from "api/organizations";
// import { createOrganization, updateOrganization, } from "api/organizations";
import { createOrganization, updateOrganization, } from "api/graphQL_organizations";
import { getOrganization } from "api/graphQL_organizations";
import { createWhiteListClient, getWhitwListRecordById, updateWhiteListRecord } from "api/whiteListed";

import Card from "components/card/custom";
import Checkbox from "components/checkbox";
import DivLoader from "components/divloader/DivLoader";
import InputField from "components/fields/InputField";
import React from "react";
import { toast } from "react-hot-toast";
import { BsPlusCircle } from "react-icons/bs";
import { MdArrowBack, MdClose, MdEdit, MdInfo, MdVisibility } from "react-icons/md";

export interface iCreateOrgForm {
  first_name: string;
  last_name: string;
  bin: string;
  last_four: string;
  email: string;
  is_binAndlast_four?: any;
  is_client_name?: any;
  is_email?: any;
}

const CreateBlockClientsModal = ({
  fetchBlackListClientsData,
  id,
  info,
  roleData,
  infoData,
  client_id,
  gateway_id,
}: {
  fetchBlackListClientsData: () => void;
  id?: string;
  info?: boolean;
  roleData?: any;
  infoData?: any;
  client_id?: any;
  gateway_id?: any;
}) => {
  const rootForm = {
    first_name: "",
    last_name: "",
    bin: "",
    last_four: "",
    email: "",
    is_binAndlast_four: false,
    is_client_name: false,
    is_email: false,
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [formValues, setFormValues] = React.useState<any>();
  const [viewMode, setViewMode] = React.useState<boolean>(info);
  const [formValuesErr, setFormValuesErr] = React.useState<any>(rootForm);
  const [isCloseModal, setIsCloseModal] =  React.useState<boolean>(false);
  const handleClose = () => {
    setFormValues(rootForm);
    setFormValuesErr(rootForm);
    setIsCloseModal(true)
    setTimeout(() => {
      setIsCloseModal(false)
      onClose();
    }, 300);
  };
  function handleValueChange(e: any) {
    const target = e.target;
    const value = target.value;
    setFormValues((preVal: any) => {
      return { ...preVal, [e.target.id]: value };
    });
    setFormValuesErr((preVal: any) => {
      return { ...preVal, [e.target.id]: "" };
    });
  }

  function validateData() {
    let isValid = false;
    let isValid_last_four = true,
      isValid_bin = true,
      isValid_first_name = true,
      isValid_email = true;
    if (formValues?.is_binAndlast_four) {
      isValid = true;
      if (formValues?.bin?.length != 6) {
        setFormValuesErr((preVal: any) => {
          return { ...preVal, bin: "Please enter 6 digit bin number" };
        });
        isValid_bin = false;
      }
    }

    if (formValues?.is_client_name) {
      isValid = true;
      if (!formValues?.first_name) {
        setFormValuesErr((preVal: any) => {
          return { ...preVal, first_name: "Please enter first name." };
        });
        isValid_first_name = false;
      }
    }

    if (formValues?.is_email) {
      isValid = true;
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(formValues?.email)) {
        setFormValuesErr((preVal: any) => {
          return { ...preVal, email: "Please enter valid email address." };
        });
        isValid_email = false;
      }
    }

    if (
      isValid_last_four &&
      isValid_bin &&
      isValid_first_name &&
      isValid_email
    ) {
      isValid = true;
    } else {
      isValid = false;
    }

    if (!formValues?.is_email && !formValues?.is_binAndlast_four) {
      isValid = false;

      toast.error("Please provide either card details or email address.");
    }

    return isValid;
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    let validate = validateData();
    if (validate) {
      try {
        setIsLoading(true);
        if (id) {
          let payload = {
            id: id,
            bin: formValues?.is_binAndlast_four ? formValues?.bin : "",
            last_four: formValues?.is_binAndlast_four
              ? formValues?.last_four
              : "",
            first_name: formValues?.is_client_name
              ? formValues?.first_name
              : "",
            last_name: formValues?.is_client_name ? formValues?.last_name : "",
            email: formValues?.is_email ? formValues?.email : "",
            client_id: client_id,
            gateway_id: gateway_id,
          };
          await updateWhiteListRecord(payload);
        } else {
          let payload = {
            bin: formValues?.is_binAndlast_four ? formValues?.bin : "",
            last_four: formValues?.is_binAndlast_four
              ? formValues?.last_four
              : "",
            first_name: formValues?.is_client_name
              ? formValues?.first_name
              : "",
            last_name: formValues?.is_client_name ? formValues?.last_name : "",
            email: formValues?.is_email ? formValues?.email : "",
            client_id: client_id,
            gateway_id: gateway_id,
          };
          await createWhiteListClient(payload);
          toast.success("Success!");
        }
        fetchBlackListClientsData();
        handleClose();
      } catch (err: any) {
        if (err?.response?.status === 422) {
          toast.error("Please provide valid values");
        } else {
          toast.error(
            err?.response?.data?.message ?? "Unable to update details!"
          );
        }
      } finally {
        setIsLoading(false);
      }
    } else {
    }
  }

  async function fetchBlockedUserById(id: string) {
    setIsLoading(true);
    await getWhitwListRecordById(id)
      .then((data: any) => {
        setFormValues((prev: any) => {
          return {
            ...prev,
            first_name: data[0]?.first_name,
            last_name: data[0]?.last_name,
            bin: data[0]?.bin,
            last_four: data[0]?.last_four,
            email: data[0]?.email,
            is_binAndlast_four: data[0]?.bin ? true : false,
            is_client_name: data[0]?.first_name ? true : false,
            is_email: data[0]?.email ? true : false,
          };
        });
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message ?? "Unable to fetch data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {info ? (
        roleData?.[0]?.whitelist?.value?.view_whitelist && (
          <div
            onClick={() => {
              if (id) {
                fetchBlockedUserById(id);
              }
              onOpen();
            }}
          >
            {" "}
            <MdInfo
              className="h-5 w-5 cursor-pointer text-blue-500"
              title="View"
            />
          </div>
        )
      ) : id ? (
        true && (
          <div
            onClick={() => {
              if (id) {
                fetchBlockedUserById(id);
              }
              onOpen();
            }}
          >
            <MdEdit
              className="h-5 w-5 cursor-pointer text-blue-500"
              title="Edit"
            />
          </div>
        )
      ) : roleData?.[0]?.whitelist?.value?.add_whitelist ? (
        // true ? (
        <span>
          <Card extra="w-fit  cursor-pointer">
            <button
              onClick={() => {
                if (id) {
                  fetchBlockedUserById(id);
                }
                onOpen();
              }}
              className="flex items-end justify-end gap-2 whitespace-nowrap p-2 text-navy-700 outline-none dark:text-gray-200"
            >
              <BsPlusCircle className="h-5 w-5 text-brand-500" />
              <span className="text-brand-500"> Add Whitelist </span>
            </button>
          </Card>
        </span>
      ) : null}
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="z-20" />
        {/* <ModalContent className="sm:max-w-xxl scrollbarhide   z-[1002] !m-auto flex max-h-[100vh] min-h-[100vh] max-w-[800px]  justify-start overflow-auto sm:my-8 sm:w-full "> */}
        <Card extra={`fixed top-0 right-0 h-full rounded-none max-w-[700px] sm:w-full flex flex-col justify-start !z-[1004] overflow-y-auto scrollbar-hide overflow-auto ${
            isOpen && !isCloseModal ? "card-enter" : "card-exit"
          }`}>
            <ModalHeader className=" flex py-[20px] mb-[20px] px-[30px] text-2xl font-bold bg-brand-500 text-white">
              <MdArrowBack
                className="h-[1.9rem] mr-2 w-6 cursor-pointer text-white"
                onClick={handleClose}
              />
              {viewMode
              ? `${infoData?.row?.original?.first_name} ${
                  infoData?.row?.original?.last_name
                } ${formValues?.is_client_name ? "-" : ""} View`
              : id
              ? `${infoData?.row?.original?.first_name} ${
                  infoData?.row?.original?.last_name
                } ${formValues?.is_client_name ? "-" : ""} Edit`
              : "Add Whitelist"}
            </ModalHeader>
          <ModalBody className="p-2">
            
              {isLoading && <DivLoader className="h-6 w-6 border-indigo-500" />}
              {info &&
                (viewMode ? (
                  <MdEdit
                    className="absolute right-7 top-6 h-6 w-6 cursor-pointer text-white"
                    onClick={() => {
                      setViewMode(!viewMode);
                    }}
                  />
                ) : (
                  <MdVisibility
                    className="absolute right-7 top-6 h-6 w-6 cursor-pointer text-white"
                    onClick={() => {
                      setViewMode(!viewMode);
                    }}
                  />
                ))}

              <div className="px-[35px]">
                <div className="my-3 ">
                  <strong>
                    Note: You must have either card details or email address.
                  </strong>
                </div>
                <div className="my-2 border p-2 shadow">
                  <div className="my-2 flex">
                    <Checkbox
                      id="is_binAndlast_four"
                      value={!formValues?.is_binAndlast_four}
                      checked={formValues?.is_binAndlast_four}
                      disabled={viewMode}
                      onChange={(e: any) => {
                        setFormValues((prev: any) => {
                          return {
                            ...prev,
                            is_binAndlast_four:
                              e.target?.value == "false"
                                ? false
                                : e.target?.value == "true"
                                ? true
                                : false,
                          };
                        });
                      }}
                    />
                    <label className="mx-2" htmlFor="is_binAndlast_four">
                      Do you have card details(both bin and last 4 Number)?
                    </label>
                  </div>
                  {formValues?.is_binAndlast_four && (
                    <>
                      <div className="flex">
                        <div className="w-full">
                          <div>
                            <InputField
                              variant="auth"
                              extra="my-2 w-full text-sm font-bold text-gray-900 dark:text-white"
                              label="Bin *"
                              placeholder="ex: 546470"
                              id="bin"
                              type="number"
                              disabled={viewMode}
                              value={formValues?.bin}
                              state={formValuesErr?.bin ? "error" : ""}
                              onChange={(e) => {
                                const value = e.target?.value;
                                const name = e.target?.id;
                                if (value?.length <= 6) {
                                  setFormValues((preVal: any) => {
                                    return { ...preVal, [name]: value };
                                  });
                                }
                                setFormValuesErr((preVal: any) => {
                                  return { ...preVal, [e.target.id]: "" };
                                });
                              }}
                            />
                          </div>
                          <div className="my-2 text-orange-500">
                            {formValuesErr?.bin}
                          </div>
                        </div>

                        <div className="w-full">
                          <div>
                            <InputField
                              variant="auth"
                              extra="my-2 mx-2 w-full text-sm font-bold text-gray-900 dark:text-white"
                              label="Last 4 Numbers"
                              placeholder="ex: 9876"
                              id="last_four"
                              type="number"
                              disabled={viewMode}
                              value={formValues?.last_four}
                              state={formValuesErr?.last_four ? "error" : ""}
                              onChange={(e) => {
                                const value = e.target.value;
                                const name = e.target.id;
                                if (/^\d{0,4}$/.test(value)) {
                                  setFormValues((prevVal: any) => {
                                    return { ...prevVal, [name]: value };
                                  });
                                  if (value.length === 4) {
                                    setFormValuesErr((prevVal: any) => {
                                      return { ...prevVal, [name]: "" };
                                    });
                                  } else {
                                    setFormValuesErr((prevVal: any) => {
                                      return {
                                        ...prevVal,
                                        [name]:
                                          "Value must be exactly 4 digits",
                                      };
                                    });
                                  }
                                } else {
                                  // If the value is not numeric or exceeds 4 digits, set an error message
                                  setFormValuesErr((prevVal: any) => {
                                    return {
                                      ...prevVal,
                                      [name]:
                                        "Value must be exactly 4 numeric digits",
                                    };
                                  });
                                }
                              }}
                            />
                          </div>
                          <div className="my-2 text-orange-500">
                            {formValuesErr?.last_four}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="my-2 border p-2 shadow">
                  <div className="my-2 flex">
                    <Checkbox
                      id="is_client_name"
                      value={!formValues?.is_client_name}
                      checked={formValues?.is_client_name}
                      disabled={viewMode}
                      onChange={(e: any) => {
                        setFormValues((prev: any) => {
                          return {
                            ...prev,
                            is_client_name:
                              e.target?.value == "false"
                                ? false
                                : e.target?.value == "true"
                                ? true
                                : false,
                          };
                        });
                      }}
                    />
                    <label className="mx-2" htmlFor="is_client_name">
                      Do you have client Name?
                    </label>
                  </div>
                  {formValues?.is_client_name && (
                    <>
                      <div className="flex gap-2">
                        <div className="w-full">
                          <div>
                            <InputField
                              variant="auth"
                              extra="mb-2 w-full text-sm font-bold text-gray-900 dark:text-white"
                              label="First Name"
                              placeholder="Enter First Name"
                              id="first_name"
                              type="text"
                              disabled={viewMode}
                              value={formValues?.first_name}
                              state={formValuesErr?.first_name ? "error" : ""}
                              onChange={handleValueChange}
                            />
                          </div>
                          <div className="my-2 text-orange-500">
                            {formValuesErr.first_name}
                          </div>
                        </div>

                        <div className="w-full">
                          <div>
                            <InputField
                              variant="auth"
                              extra="mb-2 w-full text-sm font-bold text-gray-900 dark:text-white"
                              label="Last Name"
                              placeholder="Enter Last Name"
                              id="last_name"
                              type="text"
                              disabled={viewMode}
                              value={formValues?.last_name}
                              state={formValuesErr?.last_name ? "error" : ""}
                              onChange={handleValueChange}
                            />
                          </div>
                          <div className="my-2 text-orange-500">
                            {formValuesErr.last_name}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="my-2 border p-2 shadow">
                  <div className="my-2 flex">
                    <Checkbox
                      id="is_email"
                      value={!formValues?.is_email}
                      checked={formValues?.is_email}
                      disabled={viewMode}
                      onChange={(e: any) => {
                        setFormValues((prev: any) => {
                          return {
                            ...prev,
                            is_email:
                              e.target?.value == "false"
                                ? false
                                : e.target?.value == "true"
                                ? true
                                : false,
                          };
                        });
                      }}
                    />
                    <label className="mx-2" htmlFor="is_email">
                      Do you have email address?
                    </label>
                  </div>
                  {formValues?.is_email && (
                    <>
                      <div>
                        <div>
                          <InputField
                            variant="auth"
                            extra="mb-2 w-full text-sm font-bold text-gray-900 dark:text-white"
                            label="Email *"
                            placeholder="example@gmail.com"
                            id="email"
                            type="text"
                            disabled={viewMode}
                            value={formValues?.email}
                            state={formValuesErr?.email ? "error" : ""}
                            onChange={handleValueChange}
                          />
                        </div>
                        <div className="my-2 text-orange-500">
                          {formValuesErr.email}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              
          </ModalBody>
            <ModalFooter className="mt-[10px]">
              <div className="mt-5  flex gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-indigo-600 px-5 py-2 text-white font-medium text-navy-700 outline-none transition duration-200 hover:bg-indigo-500 active:bg-indigo-500 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>

                {viewMode ? (
                  ""
                ) : (
                  <button
                    onClick={handleSubmit}
                    disabled={isLoading || viewMode}
                    className="linear rounded-xl bg-indigo-600 px-5 py-2 text-white font-medium text-indigo-500 outline-none transition duration-200 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                  >
                    {isLoading ? (
                      <DivLoader className="h-6 w-6 border-indigo-500" />
                    ) : id ? (
                      "Update"
                    ) : (
                      "Submit"
                    )}
                  </button>
                )}
              </div>
            </ModalFooter>
          </Card>
        {/* </ModalContent> */}
      </Modal>
    </>
  );
};
export default CreateBlockClientsModal;
